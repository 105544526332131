<template>
  <div class="write">
    <div class="title">
      <span>健康记录</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="position">
        <el-button size="mini">批量保存</el-button>
        <el-button size="mini" class="yellow">批量提交</el-button>
      </div>
      <div class="box">
        <div class="tab">
          <div class="tab-item" :class="{ 'tab-active': index === tabIndex }" v-for="(item, index) in tabs" :key="index"
            @click="tabClick(index)">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="info">
          <span>李明明</span>
          <span>男</span>
          <span>56岁</span>
          <span>身份证号：130730199808080808</span>
          <span>签约时间：2022-08-03</span>
        </div>
        <div class="scroll">
          <div class="scroll-box" v-if="tabIndex === 0">
            <div class="scroll-title">
              <span>症状</span>
            </div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox :label="index + 1 + ''" v-for="(item, index) in checks" :key="index">{{ item }}</el-checkbox>
              <el-checkbox label="24">
                <span>其他</span>
                <el-input class="other"></el-input>
              </el-checkbox>
            </el-checkbox-group>
            <div class="line">
              <div class="item">
                <span>体温：</span>
                <el-input class="other"></el-input>
                <span>℃</span>
              </div>

              <div class="item">
                <span>脉率：</span>
                <el-input class="other"></el-input>
                <span>次/分钟</span>
              </div>

              <div class="item">
                <span>左侧血压值：</span>
                <el-input class="other"></el-input>
                <el-input class="other margin-left"></el-input>
                <span>mmHg</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>右侧血压值：</span>
                <el-input class="other"></el-input>
                <el-input class="other margin-left"></el-input>
                <span>mmHg</span>
              </div>

              <div class="item">
                <span>身高：</span>
                <el-input class="other"></el-input>
                <span>cm</span>
              </div>

              <div class="item">
                <span>体重</span>
                <el-input class="other"></el-input>
                <span>kg</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>血红蛋白</span>
                <el-input class="other"></el-input>
                <span>g/L</span>
              </div>

              <div class="item">
                <span>白细胞</span>
                <el-input class="other"></el-input>
                <span>g/L</span>
              </div>

              <div class="item">
                <span>血小板</span>
                <el-input class="other"></el-input>
                <span>g/L</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>尿蛋白定性检测：</span>
                <el-input class="other width"></el-input>
              </div>

              <div class="item">
                <span>尿胴体定性检测：</span>
                <el-input class="other width"></el-input>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>尿潜血定性检测：</span>
                <el-input class="other width"></el-input>
              </div>

              <div class="item">
                <span>空腹血糖值：</span>
                <el-input class="other"></el-input>
                <span>mmol/L或mg/dl</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>心电图：</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">正常</el-radio>
                  <el-radio label="1">异常</el-radio>
                </el-radio-group>
              </div>

              <div class="item">
                <span>血清谷丙转氨酶脂：</span>
                <el-input class="other"></el-input>
                <span>u/l</span>
              </div>

              <div class="item">
                <span>血清谷草转氨酶脂：</span>
                <el-input class="other"></el-input>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>白蛋白浓度</span>
                <el-input class="other"></el-input>
                <span>g/L</span>
              </div>

              <div class="item">
                <span>总胆红素值</span>
                <el-input class="other"></el-input>
                <span>umol/l</span>
              </div>

              <div class="item">
                <span>结合胆红素值</span>
                <el-input class="other"></el-input>
                <span>umol/l u/l</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>血清肌酸值</span>
                <el-input class="other"></el-input>
                <span>umol/l</span>
              </div>

              <div class="item">
                <span>血尿素氮检测值</span>
                <el-input class="other"></el-input>
                <span>mmol/L</span>
              </div>

              <div class="item">
                <span>总胆固醇值</span>
                <el-input class="other"></el-input>
                <span>mmol/L</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>甘油三酯值</span>
                <el-input class="other"></el-input>
                <span>mmol/L</span>
              </div>

              <div class="item">
                <span>血清低密度脂蛋白胆固醇检测值</span>
                <el-input class="other"></el-input>
                <span>mmol/L</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>腹部B超：</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">正常</el-radio>
                  <el-radio label="1">异常</el-radio>
                  <el-input class="other"></el-input>
                </el-radio-group>
              </div>

              <div class="item">
                <span>健康评价：</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">正常</el-radio>
                  <el-radio label="1">异常</el-radio>
                  <el-input class="other"></el-input>
                </el-radio-group>
              </div>
            </div>

            <div class="scroll-title">
              <span>健康指导</span>
            </div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox :label="index + 1 + ''" v-for="(item, index) in checks1" :key="index">{{ item }}</el-checkbox>
              <el-input class="other"></el-input>
            </el-checkbox-group>

            <div class="scroll-title">
              <span>危险因素控制</span>
            </div>
            <el-checkbox-group v-model="checkList" class="checkbox">
              <el-checkbox label="0" class="margin-right">戒烟</el-checkbox>
              <el-checkbox label="1" class="margin-right">健康饮酒</el-checkbox>
              <el-checkbox label="2" class="margin-right">饮食</el-checkbox>
              <el-checkbox label="3" class="margin-right">锻炼</el-checkbox>
              <el-checkbox label="4">减体重</el-checkbox>
              <el-input class="other width1"></el-input>
              <el-checkbox label="5">建议接诊疫苗</el-checkbox>
              <el-input class="other width1"></el-input>
              <el-checkbox label="6">其他</el-checkbox>
              <el-input class="other width1"></el-input>
            </el-checkbox-group>

            <div class="line line1">
              <div class="item">
                <span>提交时间：暂无</span>
              </div>

              <div class="item">
                <span>修改时间：暂无</span>
              </div>
            </div>

            <div class="btns">
              <el-button class="submit" size="mini">打印履约单</el-button>
            </div>
          </div>

          <div class="scroll-box" v-else-if="tabIndex === 1">
            <div class="scroll-title">
              <span>第1次高血压随访</span>
            </div>
            <div class="line">
              <div class="item">
                <span>目标日吸烟量：</span>
                <el-input class="other"></el-input>
                <span>支</span>
              </div>

              <div class="item">
                <span>日饮酒量：</span>
                <el-input class="other"></el-input>
                <span>两</span>
              </div>

              <div class="item">
                <span>目标日饮酒量：</span>
                <el-input class="other"></el-input>
                <span>两</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>运动次数：</span>
                <el-input class="other"></el-input>
                <span>次/周</span>
              </div>

              <div class="item">
                <span>目标运动次数：</span>
                <el-input class="other"></el-input>
                <span>次/周</span>
              </div>

              <div class="item">
                <span>运动时长：</span>
                <el-input class="other"></el-input>
                <span>分/次</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>目标运动时长：</span>
                <el-input class="other"></el-input>
                <span>分/次</span>
              </div>

              <div class="item">
                <span>摄盐情况（咸淡）</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">轻</el-radio>
                  <el-radio label="1">中</el-radio>
                  <el-radio label="2">重</el-radio>
                </el-radio-group>
              </div>

              <div class="item">
                <span>目标摄盐情况（咸淡）</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">轻</el-radio>
                  <el-radio label="1">中</el-radio>
                  <el-radio label="2">重</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div class="line line1">
              <div class="item">
                <span>心理调整评价</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">良好</el-radio>
                  <el-radio label="1">一般</el-radio>
                  <el-radio label="2">差</el-radio>
                </el-radio-group>
              </div>

              <div class="item">
                <span>遵医行为评价</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">良好</el-radio>
                  <el-radio label="1">一般</el-radio>
                  <el-radio label="2">差</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>辅助检查：</span>
                <el-input class="other"></el-input>
              </div>

              <div class="item">
                <span>服药依从性</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">规律</el-radio>
                  <el-radio label="1">间断</el-radio>
                  <el-radio label="2">不服药</el-radio>
                </el-radio-group>
              </div>

              <div class="item">
                <span>服药不良反应</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">无</el-radio>
                  <el-radio label="1">有</el-radio>
                  <el-input class="other width1"></el-input>
                </el-radio-group>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>此次随访分类</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">控制满意</el-radio>
                  <el-radio label="1">控制不满意</el-radio>
                  <el-radio label="2">不良反应</el-radio>
                  <el-radio label="3">并发症</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div class="line line1">
              <div class="item">
                <span>提交时间：暂无</span>
              </div>

              <div class="item">
                <span>修改时间：暂无</span>
              </div>
            </div>

            <div class="scroll-title">
              <span>第2次高血压随访</span>
            </div>

            <el-checkbox-group v-model="checkList" class="checkbox">
              <el-checkbox label="0" class="margin-right">无症状</el-checkbox>
              <el-checkbox label="1" class="margin-right">头痛头晕</el-checkbox>
              <el-checkbox label="2" class="margin-right">恶心呕吐</el-checkbox>
              <el-checkbox label="3" class="margin-right">眼花耳鸣</el-checkbox>
              <el-checkbox label="4" class="margin-right">呼吸苦难</el-checkbox>
              <el-checkbox label="5" class="margin-right">心悸胸闷</el-checkbox>
              <el-checkbox label="6" class="margin-right">鼻子出血不止</el-checkbox>
              <el-checkbox label="7" class="margin-right">四肢发麻</el-checkbox>
              <el-checkbox label="8" class="margin-right">下肢水肿</el-checkbox>
              <el-checkbox label="9" class="margin-right">其他

              <el-input class="other"></el-input>
              </el-checkbox>
            </el-checkbox-group>

            <div class="btns">
              <el-button class="submit" size="mini">打印履约单</el-button>
            </div>
          </div>

          <div class="scroll-box" v-else-if="tabIndex === 2">
            <div class="scroll-title">
              <span>第1次高血压随访</span>
            </div>
            <div class="line">
              <div class="item">
                <span>目标日吸烟量：</span>
                <el-input class="other"></el-input>
                <span>支</span>
              </div>

              <div class="item">
                <span>日饮酒量：</span>
                <el-input class="other"></el-input>
                <span>两</span>
              </div>

              <div class="item">
                <span>目标日饮酒量：</span>
                <el-input class="other"></el-input>
                <span>两</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>运动次数：</span>
                <el-input class="other"></el-input>
                <span>次/周</span>
              </div>

              <div class="item">
                <span>目标运动次数：</span>
                <el-input class="other"></el-input>
                <span>次/周</span>
              </div>

              <div class="item">
                <span>运动时长：</span>
                <el-input class="other"></el-input>
                <span>分/次</span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>目标运动时长：</span>
                <el-input class="other"></el-input>
                <span>分/次</span>
              </div>

              <div class="item">
                <span>摄盐情况（咸淡）</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">轻</el-radio>
                  <el-radio label="1">中</el-radio>
                  <el-radio label="2">重</el-radio>
                </el-radio-group>
              </div>

              <div class="item">
                <span>目标摄盐情况（咸淡）</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">轻</el-radio>
                  <el-radio label="1">中</el-radio>
                  <el-radio label="2">重</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div class="line line1">
              <div class="item">
                <span>心理调整评价</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">良好</el-radio>
                  <el-radio label="1">一般</el-radio>
                  <el-radio label="2">差</el-radio>
                </el-radio-group>
              </div>

              <div class="item">
                <span>遵医行为评价</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">良好</el-radio>
                  <el-radio label="1">一般</el-radio>
                  <el-radio label="2">差</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>辅助检查：</span>
                <el-input class="other"></el-input>
              </div>

              <div class="item">
                <span>服药依从性</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">规律</el-radio>
                  <el-radio label="1">间断</el-radio>
                  <el-radio label="2">不服药</el-radio>
                </el-radio-group>
              </div>

              <div class="item">
                <span>服药不良反应</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">无</el-radio>
                  <el-radio label="1">有</el-radio>
                  <el-input class="other width1"></el-input>
                </el-radio-group>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <span>此次随访分类</span>
                <el-radio-group v-model="radio">
                  <el-radio label="0">控制满意</el-radio>
                  <el-radio label="1">控制不满意</el-radio>
                  <el-radio label="2">不良反应</el-radio>
                  <el-radio label="3">并发症</el-radio>
                </el-radio-group>
              </div>
            </div>

            <div class="line line1">
              <div class="item">
                <span>提交时间：暂无</span>
              </div>

              <div class="item">
                <span>修改时间：暂无</span>
              </div>
            </div>

            <div class="scroll-title">
              <span>第2次高血压随访</span>
            </div>

            <el-checkbox-group v-model="checkList" class="checkbox">
              <el-checkbox label="0" class="margin-right">无症状</el-checkbox>
              <el-checkbox label="1" class="margin-right">头痛头晕</el-checkbox>
              <el-checkbox label="2" class="margin-right">恶心呕吐</el-checkbox>
              <el-checkbox label="3" class="margin-right">眼花耳鸣</el-checkbox>
              <el-checkbox label="4" class="margin-right">呼吸苦难</el-checkbox>
              <el-checkbox label="5" class="margin-right">心悸胸闷</el-checkbox>
              <el-checkbox label="6" class="margin-right">鼻子出血不止</el-checkbox>
              <el-checkbox label="7" class="margin-right">四肢发麻</el-checkbox>
              <el-checkbox label="8" class="margin-right">下肢水肿</el-checkbox>
              <el-checkbox label="9" class="margin-right">其他

              <el-input class="other"></el-input>
              </el-checkbox>
            </el-checkbox-group>

            <div class="btns">
              <el-button class="submit" size="mini">打印履约单</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['基础包-健康体检', '基础包-高血压人群', '基础包-糖尿病人群']),
      checkList: ['1'],
      checks: Object.freeze(['无症状', '头疼', '心悸', '胸闷', '胸痛', '慢性咳嗽', '咳痰', '呼吸苦难', '多饮', '多尿', '体重下降', '乏力', '关节肿痛', '视力模糊', '手脚麻木', '尿急', '尿痛', '便秘', '腹泻', '恶心呕吐', '眼花', '耳鸣', '乳房胀痛']),
      checks1: Object.freeze(['纳入慢性病患者健康管理', '建议复查', '建议转诊']),
      radio: '1',
      value: '',
      options: [{
        value: '1',
        label: '1'
      }]
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 300px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 150px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 800px;
    margin-top: 10px;
    padding: 30px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 38px;
      right: 22px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .position {
      position: absolute;
      top: 80px;
      right: 80px;
      display: flex;
      align-items: center;

      .el-button {
        background-color: #0F9E09;
        border: none;
        border-radius: 0;
        color: #fff;
        font-size: 16px;
      }

      .yellow {
        background-color: #D05D04;
      }
    }

    .box {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 70px);

      .tab {
        display: flex;
        align-items: center;
        width: 100%;
        height: 80px;

        .tab-item {
          height: 38px;
          line-height: 38px;
          margin-right: 30px;
          padding: 0 11px;
          background-color: #616B73;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
        }

        .tab-active {
          background-color: #04B4D0;
        }
      }

      .info {
        display: flex;
        align-items: center;
        height: 60px;
        color: #fff;
        font-size: 18px;

        >span {
          margin-right: 20px;
        }
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 140px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .scroll-box {
          display: flex;
          flex-direction: column;

          .scroll-title {
            margin-top: 20px;
            color: #fff;
            font-size: 18px;
          }

          /deep/.el-checkbox {
            margin-top: 10px;
          }

          /deep/.el-checkbox__label {
            color: #fff;
            font-size: 16px;
          }

          .other {
            width: 160px;
          }

          .width {
            width: 266px;
          }

          .width1 {
            width: 100px;
            margin-right: 30px;
          }

          .margin-right {
            margin-right: 30px !important;
          }

          /deep/.el-input__inner {
            background-color: transparent;
            border-left: none;
            border-top: none;
            border-right: none;
            border-radius: 0;
            color: #fff;
            font-size: 16px;
          }

          /deep/.el-checkbox__inner,
          /deep/.el-radio__inner {
            background-color: transparent;
          }

          /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
          /deep/.el-radio__input.is-checked .el-radio__inner {
            background-color: #01EEFD;
          }

          /deep/.el-radio__input.is-checked+.el-radio__label,
          /deep/.el-radio__label {
            color: #fff;
          }

          .line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;

            .item {
              display: flex;
              align-items: center;
              color: #fff;
              font-size: 16px;

              .margin-left {
                margin-left: 20px;
              }

              .el-radio-group {
                margin-left: 10px;
                margin-top: 4px;
              }
            }
          }

          .line1 {
            justify-content: flex-start;

            .item {
              margin-right: 40px;
            }

            .checkbox {
              .el-checkbox {
                margin-right: 0;
              }
            }
          }

          .btns {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 80px;
          }

          .submit {
            background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
            color: #fff;
            border-radius: 0;
            border: none;
          }
        }

      }
    }
  }
}
</style>
