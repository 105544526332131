<template>
  <div class="select">
    <div class="select-title">
      <span>处理意见模板</span>
    </div>
    <div class="select-content">
      <div class="close" @click="closePopup">
        <span class="el-icon-close"></span>
      </div>
      <div class="select-line">
        <span class="key">模板类型</span>
        <el-select v-model="value" placeholder="高血压患者处理意见范例">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <el-table :data="tableData" border>
        <el-table-column type="selection" width="55">
        </el-table-column>
        <el-table-column prop="date" label="">
        </el-table-column>
      </el-table>

      <div class="btns">
        <el-button size="mini" @click="closePopup">取消</el-button>
        <el-button size="mini" class="blue" @click="closePopup">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '建议反复测几次，如果反复高的话，及时在医生的指导下口服降血压药物，调理血压达标，不超过140/90，注意饮食清淡，增加户外活动等等。定期复查。',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '防止过度饱食: 每餐应以八九分饱外宜:',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }],
      options: [{
        value: '1',
        label: '1'
      }],
      value: ''
    }
  },
  methods: {
    update () {
      this.isUpdate = true
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8889;
  width: 100%;
  height: 100%;
  padding: 0 600px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .select-title {
    width: 180px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .select-content {
    position: relative;
    width: 700px;
    height: 502px;
    padding: 20px 60px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 20px;
      right: 10px;
      color: #fff;
      font-size: 22px;
      cursor: pointer;
    }

    .select-line {
      display: flex;
      align-items: center;
      height: 97px;
      color: #fff;
      font-size: 18px;

      .key {
        margin-right: 20px;
      }

      .el-select {
        width: 335px;
      }
    }

    .el-table {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.el-table--border {
      border: 1px solid #01EEFD;
      border-bottom: none;
    }

    /deep/.el-table th.el-table__cell {
      background-color: transparent;
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-table tr {
      background-color: transparent;
    }

    /deep/.el-table__cell {
      text-align: center;
    }

    /deep/.el-table th.el-table__cell {
      color: #fff;
    }

    /deep/.el-table th.el-table__cell.is-leaf {
      border-bottom: 1px solid #01EEFD;
    }

    /deep/.el-table--border .el-table__cell {
      border-bottom: 1px solid #01EEFD;
      border-right: 1px solid #01EEFD;
    }

    /deep/.el-table--border::after,
    /deep/.el-table::before {
      background-color: transparent;
    }

    /deep/.el-table__body tr:hover>td {
      background-color: transparent;
    }

    /deep/.el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0px;
      }

      &::-webkit-scrollbar-track {
        border: none;
      }
    }

    /deep/.el-table th.gutter {
      display: none;
      width: 0
    }

    /deep/.el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    /deep/.el-table__body {
      width: 100% !important;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 50px;
      padding: 0 200px;
      box-sizing: border-box;

      .el-button {
        background-color: #616B73;
        border: none;
        border-radius: 0;
        color: #fff;
      }

      .blue {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
