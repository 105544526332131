<template>
  <div class="children-page">
    <div class="head">
      <el-button>团队履约记录</el-button>
    </div>
    <div class="search">
      <div class="item">
        <span class="key">履约分类</span>
        <el-select v-model="value" placeholder="请选择履约分类">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item item1">
        <span class="key">履约日期</span>
        <el-date-picker type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="item">
        <span class="key">签字状态</span>
        <el-select v-model="value" placeholder="请选择签字状态">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="key">履约照片状态</span>
        <el-select v-model="value" placeholder="请选择履约照片状态">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="key">推送次数</span>
        <el-select v-model="value" placeholder="不限">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <el-input placeholder="请输入姓名/身份证号/手机号" class="input"></el-input>
      </div>
      <div class="item">
        <el-input placeholder="请输入服务机构" class="input"></el-input>
      </div>
      <div class="item">
        <span class="key">处理意见</span>
        <el-select v-model="value" placeholder="请选择处理意见">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button>查询</el-button>
      <el-button>导出</el-button>
    </div>
    <div class="info">
      <div class="tab">
        <span class="active">当前履约</span>
        <span>历史履约</span>
      </div>
      <el-table :data="tableData" border height="500">
        <el-table-column prop="date" label="机构">
        </el-table-column>
        <el-table-column prop="date" label="基本信息" width="440">
          <template>
            <div class="news">
              <div class="news-right">
                <div class="news-item">
                  <span class="name">郭秋生</span>
                  <span>公卫已建档-本机构</span>
                </div>
                <div class="news-item">
                  <span class="margin-right">男</span>
                  <span class="margin-right">49岁</span>
                  <span>电话：15806638888</span>

                </div>
                <div class="news-item news-item1">
                  <span>人群分类：人群分类</span>
                </div>
                <div class="news-item news-item1">
                  <span>身份证号：130133199909090909</span>
                </div>
                <div class="news-item">
                  <span>居住地址：</span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="服务内容">
        </el-table-column>
        <el-table-column prop="address" label="服务者"></el-table-column>
        <el-table-column prop="address" label="服务时间"></el-table-column>
        <el-table-column prop="address" label="履约时间"></el-table-column>
        <el-table-column prop="address" label="履约照片"></el-table-column>
        <el-table-column prop="address" label="签名状态"></el-table-column>
        <el-table-column prop="address" label="操作" width="140">
          <template>
            <div class="operate">
              <div class="operate-item" @click="openSee">
                <span>查看</span>
              </div>
              <div class="operate-item">
                <span>编辑</span>
              </div>
              <div class="operate-item">
                <span>删除</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 随访记录 -->
    <See v-if="showSee" @close="closeSee"></See>
  </div>
</template>

<script>
import See from './see.vue'
export default {
  components: {
    See
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      checked: false,
      showSee: false,
      showAgreement: false
    }
  },
  methods: {
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: 100%;
  height: 100%;
  background-color: #081C38;
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    padding: 10px 0;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      margin-right: 20px;
      text-align: center;
      border: 0;
      border-radius: 0;
      background-color: #01EEFD;
      color: #fff;
      font-size: 22px;
    }
  }

  .search {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 100%;
    height: 136px;
    padding: 20px 0;
    box-sizing: border-box;

    .input {
      width: 250px;
    }

    .input1 {
      width: 320px;
    }

    .item {
      display: flex;
      align-items: center;
      width: 250px;
      margin-right: 30px;
      color: #01EEFD;
      font-size: 16px;

      .key {
        margin-right: 3px;
        white-space: nowrap;
      }

      .icon {
        color: #A1A6BB;
        font-size: 14px;
      }

      /deep/.el-checkbox__label {
        color: #fff;
        font-size: 16px;
      }

      /deep/.el-checkbox__inner {
        width: 22px;
        height: 22px;
        background-color: transparent;
      }

      /deep/.el-checkbox__inner::after {
        display: none;
      }

      /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #409EFF;
      }
    }

    .item1 {
      width: 350px;
    }

    .item2 {
      width: auto;
    }

    .item3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 160px;
      margin-right: 0;
    }

    .el-button {
      width: 90px;
      height: 38px;
      margin-right: 30px;
      background-color: #04B4D0;
      border-radius: 0;
      border: 0;
      color: #fff;
    }
  }

  .info {
    width: 100%;
    height: calc(100% - 214px);
    padding: 20px;
    background-color: #024276;
    box-sizing: border-box;

    .tab {
      display: flex;
      align-items: center;
      height: 40px;
      color: #fff;
      font-size: 18px;

      span {
        margin-right: 20px;
      }

      .active {
        color: #01EEFD;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
      }
    }

    .el-table {
      background-color: transparent;
      color: #01EEFD;
      border-bottom: 1px solid #01EEFD !important;
    }

    /deep/.el-table--border {
      border: 1px solid #01EEFD;
      border-bottom: none;
    }

    /deep/.el-table th.el-table__cell {
      background-color: transparent;
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-table tr {
      background-color: transparent;
    }

    /deep/.el-table__cell {
      text-align: center;
    }

    /deep/.el-table th.el-table__cell {
      color: #fff;
    }

    /deep/.el-table th.el-table__cell.is-leaf {
      border-bottom: 1px solid #01EEFD;
    }

    /deep/.el-table--border .el-table__cell {
      border-bottom: 1px solid #01EEFD;
      border-right: 1px solid #01EEFD;
    }

    /deep/.el-table--border::after,
    /deep/.el-table::before {
      background-color: transparent;
    }

    /deep/.el-table__body tr:hover>td {
      background-color: transparent;
    }

    /deep/.el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0px;
      }

      &::-webkit-scrollbar-track {
        border: none;
      }
    }

    /deep/.el-table th.gutter {
      display: none;
      width: 0
    }

    /deep/.el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    /deep/.el-table__body {
      width: 100% !important;
    }

    .news {
      display: flex;
      width: 100%;
      height: 115px;

      .news-left {
        width: 48px;
        height: 48px;
        background-color: #fff;
        border-radius: 50%;
      }

      .news-right {
        display: flex;
        flex-wrap: wrap;
        align-content: start;
        flex: 1;
        height: 100%;
        margin-left: 7px;

        .news-item {
          display: flex;
          width: 100%;
          color: #01EEFD;
          font-size: 14px;
        }

        .news-item1 {
          justify-content: space-between;
        }

        .name {
          margin-right: 20px;
          font-size: 16px;
        }

        .margin-right {
          margin-right: 20px;
        }

        .news-button {
          display: flex;
          align-items: center;
          width: 100%;

          .button {
            display: flex;
            align-items: center;
            margin-right: 20px;
            color: #fff;
            font-size: 14px;
            cursor: pointer;

            .update {
              margin-right: 10px;
              font-size: 20px;
            }
          }
        }
      }
    }

    .serve {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 115px;
      padding: 0 10px;
      box-sizing: border-box;

      .serve-line {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 8px;
        color: #01EEFD;
        font-size: 14px;

        .serve-key {
          width: 220px;
          text-align: left;
        }

        .status {
          width: 220px;
          height: 12px;
          background-color: #9CAEAF;
          border-radius: 6px;
        }

        .button {
          color: #fff;
          font-size: 14px;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;
        }
      }

      .serve-line:first-child {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .date {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 115px;
    }

    .operate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 115px;
      padding: 0 5px;
      box-sizing: border-box;

      .operate-item {
        text-align: left;
        white-space: nowrap;
        border-bottom: 1px solid #01EEFD;
        cursor: pointer;
      }

      .operate-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
