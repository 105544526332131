<template>
  <div class="tab">
    <div class="title">
      <span class="blue"></span>
      <span>个人资料</span>
    </div>
    <div class="content">
      <div class="content-title">
        <span>医生信息</span>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">内容</span>
          <span>2019090009</span>
        </div>
        <div class="item">
          <span class="key">用户名</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="item">
          <span class="key">医师头像</span>
          <el-upload class="avatar-uploader" action="" :show-file-list="false" :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <div class="add">
              <img src="../../../img/emr/user.png" class="avatar">
              <i class="el-icon-plus avatar-uploader-icon"></i>
              <span>点击上传</span>
            </div>
          </el-upload>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">医师姓名</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="item">
          <span class="key">医生角色</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="key">性别</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="line">
        <div class="item item1">
          <span class="key">医生简介</span>
          <el-input type="textarea" placeholder="请输入内容">
          </el-input>
        </div>
      </div>
      <div class="line">
        <div class="item item1">
          <span class="key">医生简介</span>
          <el-input type="textarea" placeholder="请输入内容" v-model="something">
          </el-input>
        </div>
      </div>
    </div>
    <el-button size="small" class="submit">保存</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      imageUrl: '',
      something: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 100px);
  box-sizing: border-box;

  .title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    margin-bottom: 10px;
    padding-left: 10px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: bold;

    .blue {
      width: 4px;
      height: 18px;
      margin-right: 4px;
      background-color: #366FF7;
      border-radius: 2px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 120px);
    padding: 0 10px;
    box-sizing: border-box;

    .content-title {
      display: flex;
      align-items: center;
      margin-top: 10px;
      color: #01EEFD;
      font-size: 18px;
    }

    .line {
      display: flex;
      width: 100%;
      margin-top: 20px;
      padding-left: 10px;
      box-sizing: border-box;

      .item {
        display: flex;
        align-items: center;
        width: 30%;
        margin-right: 60px;
        color: #01EEFD;
        font-size: 18px;

        .key {
          margin-right: 10px;
          white-space: nowrap;
        }

        .el-input {
          width: 198px;
        }

        .add {
          display: flex;
          align-items: center;

          .avatar {
            width: 25px;
            height: 25px;
            margin-right: 11px;
          }
        }
      }

      .item1 {
        width: 100%;
        align-items: self-start;

        /deep/.el-textarea__inner {
          width: 714px;
          height: 143px;
          color: #333333;
          font-size: 16px;
        }
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .el-button {
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #fff;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }

    .delete {
      background-color: #FF0000;
    }
  }

  .submit {
    position: absolute;
    left: 0;
    bottom: 30px;
  }

  .el-button {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      border: none;
      border-radius: 0;
      color: #fff;
    }
}
</style>
