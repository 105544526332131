<template>
  <div class="referral">
    <div class="head">
      <el-button size="small" class="tabIndex" :class="{ blue: tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">{{ item }}</el-button>
    </div>
    <!-- 机构管理 -->
    <Tab1 v-show="tabIndex === 0"></Tab1>
    <!-- 团队管理 -->
    <Tab2 v-show="tabIndex === 1"></Tab2>
    <!-- 医生管理 -->
    <Tab3 v-show="tabIndex === 2"></Tab3>
    <!-- 服务包管理 -->
    <Tab4 v-show="tabIndex === 3"></Tab4>
    <!-- 服务项目管理 -->
    <Tab5 v-show="tabIndex === 4"></Tab5>
    <!-- 业绩目标管理 -->
    <Tab6 v-show="tabIndex === 5"></Tab6>
  </div>
</template>

<script>
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2/tab2.vue'
import Tab3 from './tab3/tab3.vue'
import Tab4 from './tab4/tab4.vue'
import Tab5 from './tab5/tab5.vue'
import Tab6 from './tab6/tab6.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    Tab6
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['机构管理', '团队管理', '医生管理', '服务包管理', '服务项目管理', '业绩目标管理'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: calc(100% - 10px);
  height: 100%;
  padding: 10px 37px;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  // border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      text-align: center;
      border-radius: 0;
      border: 0;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
    }

    .blue {
      background-color: #01EEFD;
    }

    .color {
      margin-right: .25rem;
      background-color: #D5D5D5;
    }

    .width {
      width: 1rem;
      font-size: .225rem;
    }

    .el-input {
      width: 3.075rem;
      height: .475rem;
      border-radius: .075rem;

      /deep/.el-input__inner {
        height: .475rem;
        line-height: .475rem;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: .0125rem solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: .225rem;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: .225rem;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

}
</style>
