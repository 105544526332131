<template>
  <div class="children-page">
    <div class="line">
      <div class="table">
        <div class="table-head">
          <span class="el-icon-s-opportunity icon"></span>
          <span>待办</span>
        </div>
        <div class="table-scroll">
          <div class="table-tr">
            <div class="table-td border-right">
              <span>签约申请</span>
            </div>
            <div class="table-td">
              <span>0 条签约申请待处理</span>
            </div>
          </div>
          <div class="table-tr">
            <div class="table-td border-right">
              <span>解约申请</span>
            </div>
            <div class="table-td">
              <span>0 条解约申请待处理</span>
            </div>
          </div>
          <div class="table-tr">
            <div class="table-td border-right">
              <span>居民续约</span>
            </div>
            <div class="table-td">
              <span>0 个居民将在一个月内到期</span>
            </div>
          </div>
          <div class="table-tr">
            <div class="table-td border-right">
              <span>双向转诊</span>
            </div>
            <div class="table-td">
              <span>0 待安排的转诊申请等待处理</span>
            </div>
          </div>
        </div>
      </div>

      <div class="table">
        <div class="table-head">
          <span class="el-icon-s-opportunity icon"></span>
          <span>我的消息</span>
        </div>
        <div class="table-scroll">
          <div class="table-tr">
            <div class="table-td border-right">
              <span>血糖记录</span>
            </div>
            <div class="table-td">
              <span>今日上传 0 条血糖记录待处理</span>
            </div>
          </div>
          <div class="table-tr">
            <div class="table-td border-right">
              <span>血压记录</span>
            </div>
            <div class="table-td">
              <span>0 条血压记录待处理</span>
            </div>
          </div>
          <div class="table-tr">
            <div class="table-td border-right">
              <span>居民续约</span>
            </div>
            <div class="table-td">
              <span>0 条中医体质辨识待处理</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      <span class="el-icon-s-order icon"></span>
      <span>信息总览</span>
    </div>
    <div class="info">
      <div class="item">
        <div class="item-left">
          <span class="el-icon-user-solid"></span>
        </div>
        <div class="item-right">
          <span>签约居民数</span>
          <span class="text">7</span>
        </div>
      </div>

      <div class="item">
        <div class="item-left">
          <span class="el-icon-user-solid"></span>
        </div>
        <div class="item-right">
          <span>涉及签约居民数</span>
          <span class="text">7</span>
        </div>
      </div>

      <div class="item">
        <div class="item-left">
          <span class="el-icon-user-solid"></span>
        </div>
        <div class="item-right">
          <span>应履约次数</span>
          <span class="text">7</span>
        </div>
      </div>

      <div class="item">
        <div class="item-left">
          <span class="el-icon-user-solid"></span>
        </div>
        <div class="item-right">
          <span>服务包购买量</span>
          <span class="text">7</span>
        </div>
      </div>

      <div class="item">
        <div class="item-left">
          <span class="el-icon-user-solid"></span>
        </div>
        <div class="item-right">
          <span>已履约次数</span>
          <span class="text">7</span>
        </div>
      </div>

      <div class="item">
        <div class="item-left">
          <span class="el-icon-user-solid"></span>
        </div>
        <div class="item-right">
          <span>轻随访服务量</span>
          <span class="text">7</span>
        </div>
      </div>

      <div class="item">
        <div class="item-left">
          <span class="el-icon-user-solid"></span>
        </div>
        <div class="item-right">
          <span>履约人数</span>
          <span class="text">7</span>
        </div>
      </div>

    </div>
    <div class="title">
      <span class="el-icon-s-order icon"></span>
      <span>排班信息</span>
    </div>
    <div class="set">
      <span class="el-icon-s-tools open" @click="openSet"></span>
    </div>
    <div class="table1">
      <div class="table1-head">
        <span class="flex"></span>
        <span class="flex">星期一</span>
        <span class="flex">星期二</span>
        <span class="flex">星期三</span>
        <span class="flex">星期四</span>
        <span class="flex">星期五</span>
        <span class="flex">星期六</span>
        <span class="flex">星期日</span>
      </div>
      <div class="table1-tr">
        <span class="flex border-right">上午</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
      </div>
      <div class="table1-tr">
        <span class="flex border-right">下午</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
        <span class="flex">坐班</span>
      </div>
    </div>
    <!-- 设置 -->
    <Set v-if="showSet" @close="closeSet"></Set>
  </div>
</template>

<script>
import Set from './set.vue'
export default {
  components: {
    Set
  },
  data () {
    return {
      showSet: false
    }
  },
  methods: {
    openSet () {
      this.showSet = true
    },
    closeSet () {
      this.showSet = false
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px 30px;
  background-color: #081C38;
  box-sizing: border-box;

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .table {
      width: 747px;
      height: 211px;
      border: 4px solid #073F73;
      box-sizing: border-box;

      .table-head {
        display: flex;
        align-items: center;
        height: 44px;
        padding-left: 18px;
        background-color: #024276;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 17px;

        .icon {
          font-size: 28px;
        }
      }

      .table-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 44px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .table-tr {
          display: flex;
          align-items: center;
          height: 39px;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;

          .table-td {
            padding-left: 25px;
            box-sizing: border-box;
            color: #01EEFD;
            font-size: 16px;
          }

          .border-right {
            width: 146px;
            text-align: center;
            border-right: 1px solid #01EEFD;
            box-sizing: border-box;
          }
        }

        .table-tr:last-child {
          border: none;
        }

        .table-tr:nth-child(3) {
          border-bottom: 1px solid #01EEFD;
        }
      }
    }
  }

  .title {
    display: flex;
    align-items: center;
    height: 40px;
    margin-top: 20px;
    padding-left: 23px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 17px;

    .icon {
      font-size: 28px;
    }
  }

  .title:last-child {
    margin-top: 0;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 100%;
    height: 280px;
    padding: 20px 23px;
    box-sizing: border-box;

    .item {
      display: flex;
      width: 313px;
      height: 110px;
      margin-right: 50px;
      padding: 20px;
      border: 4px solid #073F73;
      box-sizing: border-box;

      .item-left {
        height: 100%;
        color: #01EEFD;
        font-size: 20px;
      }

      .item-right {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 20px;
        color: #01EEFD;
        font-size: 17px;

        .text {
          margin-top: 6px;
        }
      }
    }
  }

  .set {
    width: 100%;
    height: 51px;
    line-height: 51px;
    text-align: right;
    color: #01EEFD;
    font-size: 24px;

    .open {
      cursor: pointer;
    }
  }

  .table1 {
    overflow: hidden;
    width: 100%;
    height: 107px;
    border: 4px solid #073F73;
    box-sizing: border-box;

    .table1-head {
      display: flex;
      align-items: center;
      height: 33px;
      background-color: #024276;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 17px;
    }

    .flex {
      flex: 1;
      height: 33px;
      line-height: 33px;
      text-align: center;
    }

    .border-right {
      border-right: 1px solid #01EEFD;
      box-sizing: border-box;

    }

    .table1-tr {
      display: flex;
      align-items: center;
      height: 33px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 16px;

      .flex:first-child {
        height: auto;
        line-height: normal;
      }
    }

    .table1-tr:last-child {
      border-bottom: none;
    }
  }

}
</style>
