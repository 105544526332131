<template>
  <div class="item-content">
    <el-descriptions class="margin-top" title="居民信息" :column="4" border>
      <el-descriptions-item class="el-descriptions-item">
        <template slot="label">
          居民编号
        </template>
        20200908900
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          姓名
        </template>
        李明明
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          身份证号
        </template>
        130730200014020816
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          头像
        </template>
        <img class="head-img" src="../../img/emr/user.png" alt="">
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          手机号码
        </template>
        15803339264
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          现居地
        </template>
        河北石家庄桥西区友谊街道202号
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          登录密码
        </template>
        zai123456
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          行政区划
        </template>
        桥西区居委会
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          居民标签
        </template>
        <el-tag>高血压</el-tag>
        <el-tag>糖尿病</el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          性别
        </template>
        男
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          出生日期
        </template>
        1952-04-06
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          籍贯
        </template>
        河北
      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">
          民族
        </template>
        汉族
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          文化程度
        </template>
        本科
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          婚姻状况
        </template>
        已婚
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          职位
        </template>
        河北
      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">
          工作单位
        </template>
        无
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          紧急联系人
        </template>
        无
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          紧急联系人电话
        </template>
        无
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          户籍类型
        </template>
        城镇
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions class="margin-top" title="健康信息" :column="4" border>
      <el-descriptions-item>
        <template slot="label">
          身高
        </template>
        169cm
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          体重
        </template>
        48kg
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          血型
        </template>
        A型
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          RH阴性
        </template>
        无
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          过敏史
        </template>
        无
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          既往史
        </template>
        无
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          就诊史
        </template>
        无
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          家族病史
        </template>
        无
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          家族成员
        </template>
        <div class="flex">
          <div class="card">
            <img src="../../img/emr/user.png" class="img">
            <div class="news">
              <el-row :gutter="10">
                <el-col :span="12">
                  <div class="text">
                    <span>李青</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="text">
                    <span>62岁</span>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <div class="text">
                    <span>关系</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="text">
                    <span>父母</span>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="card">
            <img src="../../img/emr/user.png" class="img">
            <div class="news">
              <el-row :gutter="10">
                <el-col :span="12">
                  <div class="text">
                    <span>李青</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="text">
                    <span>62岁</span>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <div class="text">
                    <span>关系</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="text">
                    <span>父母</span>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="add">
            <span class="el-icon-plus"></span>
            <span>关联家庭成员</span>
          </div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <div class="btns">
      <el-button class="submit" size="small" @click="openPerfectResident">编辑档案信息</el-button>
      <el-button class="close" size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showPerfectResident: false
    }
  },
  methods: {
    openPerfectResident () {
      this.$emit('openPerfectResident')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.item-content {
  width: 100%;
  height: 100%;
}

.el-descriptions {
  margin-top: 10px;
  padding: 10px 0;
  background-color: transparent;
  border-top: 1px solid #01EEFD;
  font-size: 16px;

  .head-img {
    width: 36px;
    height: 36px;
  }
}

.gray {
  color: #D2D2D2;
}

.purple {
  color: #3670F6;
}

/deep/.el-descriptions__header {
  color: #01EEFD;
  font-size: 18px;
}

.el-descriptions-item {}

/deep/.el-descriptions__body {
  background-color: transparent;
  color: #01EEFD;
}

/deep/.el-descriptions-item__label.is-bordered-label {
  background-color: transparent;
  text-align: right;
  // text-align-last: justify;
  color: #01EEFD;
  white-space: nowrap;
}

/deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
  border: none;
  padding: 4px;
}

/deep/.el-descriptions-item__content {
  width: 400px;
}

.el-tag {
  background-color: #0170C2;
  border: none;
  border-radius: 0;
  color: #fff;
  font-size: 16px;
}

.el-tag:nth-child(2) {
  margin-left: 10px;
}

.flex {
  display: flex;
}

.card {
  display: flex;
  align-items: center;
  height: 75px;
  margin-right: 10px;
  padding: 12px 10px;
  background: linear-gradient(96deg, #0173C8 0%, #02457A 100%);
  border-radius: 4px;

  .img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }

  .news {
    display: flex;
    flex-direction: column;

    .text {
      white-space: nowrap;
      color: #fff;
    }
  }
}

.add {
  margin-left: 23px;
}

.btns {
  display: flex;
  align-items: center;
  position: absolute;
  left: 40px;
  bottom: 40px;
}

.submit,
.close {
  margin-right: 20px;
  background-color: #33465C;
  border-radius: 0;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
}

.submit {
  background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
}
</style>
