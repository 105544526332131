<template>
  <div class="info">
    <div class="info-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="info-right">
      <div class="title">
        <span class="blue"></span>
        <span>我管理的团队详情</span>
      </div>
      <el-descriptions class="margin-top" title="团队信息" :column="5" border>
        <el-descriptions-item>
          <template slot="label">
            团队编号
          </template>
          2091900120622
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            团队名称
          </template>
          李明明团队
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            居民头像
          </template>
          <img class="head-img" src="../../../img/emr/user.png">
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            团队标签
          </template>
          <el-tag size="small" class="tag red">高血压</el-tag>
          <el-tag size="small" class="tag">冠心病</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            机构地址
          </template>
          河北省石家庄市桥西区256号
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            机构地址
          </template>
          <el-rate disabled v-model="value1"></el-rate>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            分享量
          </template>
          79
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约人数
          </template>
          79
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            邀请加入二维码
          </template>
          <div class="flex flex1">
            <!-- <img src="" class="code"> -->
            <div class="code"></div>
            <div class="tip">
              <span>医生可通过手机管理端扫描此二维码申请加入团队</span>
            </div>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            创建时间
          </template>
          2021-06-06 10:38:12
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            创建人
          </template>
          李明明
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            团队状态
          </template>
          <el-switch v-model="value2" active-color="#0BBB0B" inactive-color="#D5D5D5" active-text="启动" inactive-text="禁用">
          </el-switch>
        </el-descriptions-item>
      </el-descriptions>
      <div class="user-sroll">
        <div class="sroll-title">
          <span>团队成员(4人)</span>
        </div>
        <el-row>
          <el-col :span="6">
            <div class="item">
              <img class="user-img" src="../../../img/emr/user.png">
              <div class="news">
                <div class="news-line">
                  <span class="name">李明明</span>
                  <span>全科医生</span>
                </div>
                <div class="news-line">
                  <span>签约人数 50人</span>
                </div>
                <div class="news-line">
                  <span>签约评分 4.9分</span>
                </div>
              </div>
              <div class="position">
                <span>医生详情</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="sroll-title">
          <span>服务包(2个)</span>
        </div>
        <el-row>
          <el-col :span="6">
            <div class="item">
              <div class="ing">
                <span>老年人</span>
              </div>
              <img class="user-img" src="../../../../../assets/img/old-man.png">
              <div class="news">
                <div class="news-line">
                  <span class="name">老年人服务包</span>
                </div>
                <div class="news-line">
                  <span>签约对象 55岁以上老人</span>
                </div>
                <div class="news-line">
                  <el-tag size="small" class="red">随访服务</el-tag>
                  <el-tag size="small" class="red green">常规护理</el-tag>
                </div>
              </div>
              <div class="position">
                <span>服务包详情</span>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="add">
                <i class="el-icon-plus"></i>
                <span>添加服务包</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="sroll-title">
          <span>加入团队申请</span>
        </div>
        <el-row>
          <el-col :span="6">
            <div class="item">
              <div class="ing ing-green">
                <span>待审核</span>
              </div>
              <img class="user-img" src="../../../img/emr/user.png">
              <div class="news">
                <div class="news-line">
                  <span class="name">李明明</span>
                  <span>909000000</span>
                </div>
                <div class="news-line">
                  <span>职位 护理医师</span>
                </div>
                <div class="news-line">
                  <span>联系电话 15804449264</span>
                </div>
                <div class="news-line">
                  <span>申请时间 2021/03/02 </span>
                </div>
              </div>
              <div class="buttons">
                <el-button size="mini" class="yes">通过</el-button>
                <el-button size="mini" class="no">拒绝</el-button>
              </div>
              <div class="position">
                <span>医生详情</span>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="ing ing-blue">
                <span>已通过</span>
              </div>
              <img class="user-img" src="../../../img/emr/user.png">
              <div class="news">
                <div class="news-line">
                  <span class="name">李明明</span>
                  <span>909000000</span>
                </div>
                <div class="news-line">
                  <span>职位 护理医师</span>
                </div>
                <div class="news-line">
                  <span>联系电话 15804449264</span>
                </div>
                <div class="news-line">
                  <span>申请时间 2021/03/02 </span>
                </div>
              </div>
              <div class="buttons">
                <el-button size="mini" class="yes">通过</el-button>
                <el-button size="mini" class="no">拒绝</el-button>
              </div>
              <div class="position">
                <span>医生详情</span>
              </div>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="ing ing-grey">
                <span>已拒绝</span>
              </div>
              <img class="user-img" src="../../../img/emr/user.png">
              <div class="news">
                <div class="news-line">
                  <span class="name">李明明</span>
                  <span>909000000</span>
                </div>
                <div class="news-line">
                  <span>职位 护理医师</span>
                </div>
                <div class="news-line">
                  <span>联系电话 15804449264</span>
                </div>
                <div class="news-line">
                  <span>申请时间 2021/03/02 </span>
                </div>
              </div>
              <div class="buttons">
                <el-button size="mini" class="yes">通过</el-button>
                <el-button size="mini" class="no">拒绝</el-button>
              </div>
              <div class="position">
                <span>医生详情</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="btns">
        <el-button class="submit" size="small" @click="openUpadte">编辑团队信息</el-button>
        <el-button class="close out" size="small" @click="openDelete">退出团队</el-button>
        <el-button class="close" size="small" @click="close">返回</el-button>
      </div>
    </div>
    <!-- 编辑团队信息 -->
    <Upadte v-if="showUpadte" @close="closeUpadte"></Upadte>
    <!-- 解散团队 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
  </div>
</template>

<script>
import UserBasicInfo from '../../../../../components/user-basic-info/user-basic-info.vue'
import Upadte from './update.vue'
import Delete from './delete.vue'
export default {
  components: {
    UserBasicInfo,
    Upadte,
    Delete
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      value1: 4,
      value2: '',
      showUpadte: false,
      showDelete: false
    }
  },
  methods: {
    openUpadte () {
      this.showUpadte = true
    },
    closeUpadte () {
      this.showUpadte = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-left {
    width: 270px;
    height: 100%;
  }

  .info-right {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      color: #01EEFD;
      font-size: 20px;

      .blue {
        width: 4px;
        height: 18px;
        margin-right: 4px;
        border-radius: 2px;
        background-color: #366FF7;
      }
    }

    .el-descriptions {
      margin-top: 10px;
      padding: 10px 0;
      background-color: transparent;
      border-top: 1px solid #ECECEC;
      font-size: 16px;
    }

    .head-img {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }

    /deep/.el-descriptions__header {
      color: #01EEFD;
      font-size: 18px;
    }

    /deep/.el-descriptions__body {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.el-descriptions-item__label.is-bordered-label {
      background-color: transparent;
      text-align: right;
      // text-align-last: justify;
      color: #01EEFD;
      white-space: nowrap;
    }

    /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
      border: none;
      padding: 6px;
    }

    .flex {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      .code {
        width: 62px;
        height: 62px;
        background-color: #fff;
      }

      .tip {
        // width: 120px;
        white-space: pre-wrap;
        font-size: 10px;
      }
    }

    .flex1 {
      align-items: flex-start;
      flex-direction: column;
    }

    .tag {
      background-color: #AFCAFF;
      border-radius: 13px;
      border: none;
      color: #3778F6;
      font-size: 14px;
    }

    .tag:nth-child(1) {
      margin-right: 10px;
    }

    .red {
      background-color: #FFC4C4;
      color: #FF0000;
    }

    .user-sroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 350px);
      border-top: 1px solid #01EEFD;

      &::-webkit-scrollbar {
        width: 0;
      }

      .sroll-title {
        height: 56px;
        line-height: 56px;
        color: #01EEFD;
        font-size: 18px;
      }

      .item {
        overflow: hidden;
        position: relative;
        display: flex;
        width: 240px;
        height: 133px;
        padding: 6px;
        background: linear-gradient(180deg, #0162AE 0%, #015394 100%);
        box-shadow: 0px 3px 4px 1px rgba(157, 157, 157, 0.1608);
        border-radius: 6px;
        box-sizing: border-box;

        .ing {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          position: absolute;
          top: -18px;
          right: -38px;
          z-index: 88;
          width: 110px;
          height: 47px;
          background-color: #FF0000;
          color: #fff;
          font-size: 14px;
          transform: rotate(30deg);
        }

        .ing-green {
          background-color: #0BBB0B;
        }

        .ing-blue {
          background-color: #3670F6;
        }

        .ing-grey {
          background-color: #ADADAD;
        }

        .add {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          color: #FFFFFF;
          font-size: 14px;

          i {
            margin-right: 4px;
            font-size: 20px;
            font-weight: bolder;
          }
        }

        .user-img {
          width: 52px;
          height: 52px;
          border-radius: 6px;
        }

        .news {
          height: 100%;
          margin-left: 6px;

          .news-line {
            margin-bottom: 6px;
            color: #fff;
            font-size: 12px;

            .name {
              margin-right: 4px;
              font-size: 18px;
            }

            .red {
              background-color: #FFC4C4;
              border-radius: 12px;
              border: none;
              color: #FF0000;
              font-size: 14px;
            }

            .green {
              margin-left: 10px;
              background-color: #B6FFB6;
              color: #0BBB0B;
            }
          }
        }

        .position {
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 3px 6px;
          background-color: #3670F6;
          border-radius: 0px 0px 6px 0;
          color: #fff;
          font-size: 14px;
        }

        .buttons {
          display: flex;
          align-items: center;
          position: absolute;
          left: 22px;
          bottom: 0;

          .yes, .no {
            border:  none;
            color: #fff;
          }

          .yes {
            background-color: #0BBB0B;
          }

          .no {
            background-color: #FF0000;
          }
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;
      position: absolute;
      left: 40px;
      bottom: 40px;
    }

    .submit,
    .close {
      margin-right: 20px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #FFFFFF;
      font-size: 14px;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }

    .out {
      background-color: #FF0000 !important;
    }
  }

  /deep/.el-switch__label--left {
    position: relative;
    left: 70px;
    color: #fff;
    z-index: -1111;

    span {
      width: 40px;
    }
  }

  /deep/.el-switch__label--right {
    position: relative;
    right: 70px;
    color: #fff;
    z-index: -1111;

    span {
      display: inline-block;
      width: 40px;
    }
  }

  /deep/.el-switch__core {
    width: 65px !important;
  }

  /deep/.el-switch__label--left.is-active {
    z-index: 10;
    color: #fff;
  }

  /deep/.el-switch__label--right.is-active {
    z-index: 10;
    color: #fff;
  }
}
</style>
