<template>
  <div class="info">
    <div class="info-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="info-right">
      <div class="position">
        <img src="../../../../assets/img/unexpire.png" class="img" v-if="isbasic">
        <img src="../../../../assets/img/expire.png" class="img" v-else-if="!isbasic">
      </div>
      <div class="title">
        <span>签约详情</span>
      </div>
      <el-descriptions class="margin-top" title="居民信息" :column="5" border>
        <el-descriptions-item>
          <template slot="label">
            姓名
          </template>
          李明明
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            性别
          </template>
          男
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            年龄
          </template>
          40
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            联系电话
          </template>
          15803339222
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            现居地
          </template>
          河北石家庄桥西区友谊街道202号
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            居民标签
          </template>
          <el-tag size="mini">高血压</el-tag>
          <el-tag size="mini">糖尿病</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            家庭成员
          </template>
          <div class="flex">
            <div class="card">
              <img src="../../img/emr/user.png" class="img">
              <div class="news">
                <el-row :gutter="10">
                <el-col :span="12">
                  <div class="text">
                    <span>李青</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="text">
                    <span>62岁</span>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <div class="text">
                    <span>关系</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="text">
                    <span>父母</span>
                  </div>
                </el-col>
              </el-row>
              </div>
            </div>
            <div class="card">
              <img src="../../img/emr/user.png" class="img">
              <div class="news">
                <el-row :gutter="10">
                <el-col :span="12">
                  <div class="text">
                    <span>李青</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="text">
                    <span>62岁</span>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <div class="text">
                    <span>关系</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="text">
                    <span>父母</span>
                  </div>
                </el-col>
              </el-row>
              </div>
            </div>
            <div class="add" v-if="!isbasic">
              <span class="el-icon-plus"></span>
              <span>关联家庭成员</span>
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="签约信息" :column="5" border>
        <el-descriptions-item>
          <template slot="label">
            签约编号
          </template>
          2091900120622
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约状态
          </template>
          <span class="purple" v-if="isbasic">生效中</span>
          <span class="gray" v-else-if="!isbasic">已过期</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约机构
          </template>
          桥西区社区服务中心
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约团队
          </template>
          李明明团队
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约医生
          </template>
          李明明
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            服务包
          </template>
          老年人服务包
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约周期
          </template>
          1年
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            费用
          </template>
          ￥120
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约类型
          </template>
          首次签约
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            申请时间
          </template>
          2022/03/22 10:00:00
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            生效时间
          </template>
          2022/03/22 10:00:00
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            到期时间
          </template>
          2022/03/22 10:00:00
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            审核人
          </template>
          王斌
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            审核时间
          </template>
          2022/03/22 10:00:00
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            支付时间
          </template>
          2022/03/22 10:00:00
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            支付方式
          </template>
          微信支付
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约备注
          </template>
          无
        </el-descriptions-item>
      </el-descriptions>
      <div class="btns">
        <el-button class="submit" size="small" @click="openUpdate">续约</el-button>
        <el-button class="close" size="small" @click="close">返回</el-button>
      </div>
    </div>
    <!-- 待处理签约编辑 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
  </div>
</template>

<script>
import UserBasicInfo from '../../../../components/user-basic-info/user-basic-info.vue'
import Update from './update.vue'
export default {
  props: {
    isbasic: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UserBasicInfo,
    Update
  },
  data () {
    return {
      showUpdate: false
    }
  },
  methods: {
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 89;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-left {
    width: 270px;
    height: 100%;
  }

  .info-right {
    position: relative;
    width: calc(100% - 280px);
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .position {
      position: absolute;
      top: 30px;
      left: 182px;
      width: 82px;
      height: 66px;

      .img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      color: #01EEFD;
      font-size: 20px;
    }

    .el-descriptions {
      margin-top: 10px;
      padding: 10px 0;
      background-color: transparent;
      border-top: 1px solid #01EEFD;
      font-size: 16px;
    }

    .gray {
      color: #D2D2D2;
    }

    .purple {
      color: #3670F6;
    }

    /deep/.el-descriptions__header {
      color: #01EEFD;
      font-size: 18px;
    }

    /deep/.el-descriptions__body {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.el-descriptions-item__label.is-bordered-label {
      background-color: transparent;
      text-align: left;
      // text-align-last: justify;
      color: #01EEFD;
      white-space: nowrap;
    }

    /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
      border: none;
    }

    .el-tag {
      background-color: #0170C2;
      border: none;
      border-radius: 0;
      color: #fff;
      font-size: 16px;
    }

    .el-tag:nth-child(2) {
      margin-left: 10px;
    }

    .flex {
      display: flex;
    }

    .card {
      display: flex;
      align-items: center;
      height: 75px;
      margin-right: 10px;
      padding: 12px 10px;
      background: linear-gradient(96deg, #0173C8 0%, #02457A 100%);
      border-radius: 4px;

      .img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      .news {
        display: flex;
        flex-direction: column;

        .text {
          white-space: nowrap;
          color: #fff;
        }
      }
    }

    .add {
      margin-left: 23px;
    }

    .btns {
      display: flex;
      align-items: center;
      position: absolute;
      left: 40px;
      bottom: 40px;
    }

    .submit, .close {
      margin-right: 20px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #FFFFFF;
      font-size: 14px;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}</style>
