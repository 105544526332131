<template>
  <div class="write">
    <div class="title">
      <span>签约</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="tab">
          <span class="tab-item" :class="{ 'tab-active': tabIndex === 0 }" @click="tabClick(0)">儿童</span>
          <span class="tab-item" :class="{ 'tab-active': tabIndex === 1 }" @click="tabClick(1)">成人</span>
        </div>
        <el-carousel height="550px" direction="vertical" :autoplay="false" :initial-index="tabIndex"
          ref="remarkCarusel">
          <el-carousel-item>
            <div class="carousel">
              <div class="item">
                <span>姓名</span>
                <el-input placeholder="请输入姓名"></el-input>
              </div>

              <div class="item">
                <span>性别</span>
                <el-radio-group v-model="radio">
                  <el-radio label="1">男</el-radio>
                  <el-radio label="2">女</el-radio>
                </el-radio-group>
              </div>

              <div class="item">
                <span>姓名</span>
                <el-date-picker type="date" placeholder="请选择日期">
                </el-date-picker>
              </div>

              <div class="item">
                <span>身份证号</span>
                <el-input placeholder="请输入身份证号"></el-input>
              </div>

              <div class="item">
                <span>手机号</span>
                <el-input placeholder="请输入手机号"></el-input>
              </div>

              <div class="item">
                <span>联系电话</span>
                <el-input placeholder="请输入联系电话"></el-input>
              </div>

              <div class="item">
                <span>家人身份证号</span>
                <el-input placeholder="请输入身份证号"></el-input>
              </div>

              <div class="item">
                <span>户籍类型</span>
                <el-radio-group v-model="radio">
                  <el-radio label="1">农业</el-radio>
                  <el-radio label="2">非农业</el-radio>
                </el-radio-group>
              </div>

              <div class="item item1">
                <span class="span">公卫服务地</span>
                <el-select v-model="value" placeholder="">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>

              <div class="item item1">
                <span class="span">所属区划</span>
                <el-select v-model="value" placeholder="">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>

              <div class="item item1">
                <span class="span">居住地址</span>
                <el-input placeholder="" class="el-select"></el-input>
              </div>

            </div>
          </el-carousel-item>
            <div class="label">
              <div class="label-item" :class="{ 'label-active': index === labelIndex }" v-for="(item, index) in lables" :key="index">
                <span>{{ item }}</span>
              </div>
            </div>
          <el-carousel-item>

          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="btns">
        <el-button class="button">读取身份证</el-button>
        <el-button class="button" @click="closePopup">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      radio: '1',
      value: '',
      options: [{
        value: '1',
        label: '1'
      }],
      labelIndex: 0,
      lables: Object.freeze(['0-6岁儿童', '孕产妇', '65岁以上老年人', '高血压', '糖尿病', '严重精神障碍', '冠心病', '传染病', '哮喘', '其他人群', '慢性支气管炎', '残疾人', '肺结核', '脑卒中后遗症', '高血压-高危', '糖尿病-高危', '计生特殊困难', '低保、五保户', '收费包配套', '视力残疾', '听力残疾', '肢体残疾', '智力残疾', '精神残疾', '言语残疾', '恶心肿瘤', '脱贫户', '边缘易致贫户', '突发严重困...'])
    }
  },
  created () {
    window.onmousewheel = document.onmousewheel = (e) => {
      if (e.wheelDelta < 0) {
        // console.log('鼠标滚轮后滚')
        this.tabIndex = 1
        this.$refs.remarkCarusel.setActiveItem(1)
      } else if (e.wheelDelta > 0) {
        // console.log('鼠标滚轮前滚')
        this.tabIndex = 0
        this.$refs.remarkCarusel.setActiveItem(0)
      }
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
      this.$refs.remarkCarusel.setActiveItem(index)
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 200px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 137px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 800px;
    margin-top: 10px;
    padding: 50px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 42px;
      right: 28px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 70px);

      .tab {
        display: flex;
        align-items: center;
        width: 100%;
        height: 38px;
        margin-bottom: 20px;

        .tab-item {
          width: 90px;
          height: 38px;
          line-height: 38px;
          margin-right: 30px;
          background-color: #616B73;
          text-align: center;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
        }

        .tab-active {
          background-color: #04B4D0;
        }
      }

      .el-carousel {

        /deep/.el-carousel__button {
          width: 8px;
          height: 218px;
          border-radius: 4px;
          background-color: transparent;
        }

        /deep/.is-active .el-carousel__button {
          background-color: #04B4D0;
        }

        .carousel {
          display: flex;
          flex-wrap: wrap;
          width: calc(100% - 30px);
          height: 100%;
          background-color: #031C30;

          .item {
            display: flex;
            align-items: center;
            width: 50%;
            margin-bottom: 10px;
            color: #FFFFFF;
            font-size: 18px;

            .el-input {
              width: 300px;
              margin-left: 10px;
            }

            .el-radio-group {
              margin-left: 10px;
            }

            .el-select {
              width: calc(100% - 120px);
              margin-left: 0;
            }

            .span {
              width: 120px;
            }
          }

          .item1 {
            width: 100%;
          }
        }

        .label {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          padding: 20px;
          box-sizing: border-box;
          background-color: #031C30;

          .label-item {
            width: 105px;
            height: 32px;
            line-height: 32px;
            margin-right: 60px;
            text-align: center;
            background-color: rgba(97, 107, 115, .74);
            color: #fff;
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .label-active {
            background-color: rgba(4, 180, 208, .74);
          }
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio {
    color: #fff;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }
}
</style>
