<template>
  <div class="content">
    <div class="run">
      <div class="run-title">
        <span>角色管理</span>
      </div>
      <div class="run-content">
        <div class="line">
          <div class="key blue">
            <span>角色</span>
          </div>
          <div class="value blue" @click="openUpdate">
            <span class="el-icon-plus"></span>
            <span>新增角色</span>
          </div>
        </div>
        <div class="line">
          <div class="key">
            <span>系统管理员</span>
          </div>
          <div class="value">
            <span class="el-icon-edit margin" @click="openUpdate"></span>
            <span class="el-icon-delete-solid margin" @click="openDelete"></span>
          </div>
        </div>
        <div class="line">
          <div class="key">
            <span>全科医师</span>
          </div>
          <div class="value"> </div>
        </div>
        <div class="line">
          <div class="key">
            <span>全科护士</span>
          </div>
          <div class="value"> </div>
        </div>
        <div class="line">
          <div class="key">
            <span>护理医师</span>
          </div>
          <div class="value"> </div>
        </div>
      </div>
    </div>
    <div class="run-box">
      <div class="title">
        <span>管理角色权限</span>
      </div>
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="0">
          <div>全部</div>
          <el-checkbox-group class="checkbox-group" v-model="checkList">
            <el-checkbox label="0" class="checkbox">查看待处理签约</el-checkbox>
            <el-checkbox label="1" class="checkbox">编辑待处理签约</el-checkbox>
            <el-checkbox label="2" class="checkbox">删除待处理签约</el-checkbox>
            <el-checkbox label="3" class="checkbox">导出待处理签约</el-checkbox>
          </el-checkbox-group>
        </el-checkbox>
        <el-checkbox label="1">
          <div>签约管理</div>
          <el-checkbox-group class="checkbox-group" v-model="checkList">
            <el-checkbox label="0" class="checkbox">查看待处理签约</el-checkbox>
            <el-checkbox label="1" class="checkbox">编辑待处理签约</el-checkbox>
            <el-checkbox label="2" class="checkbox">删除待处理签约</el-checkbox>
            <el-checkbox label="3" class="checkbox">导出待处理签约</el-checkbox>
          </el-checkbox-group>
        </el-checkbox>
        <el-checkbox label="2">
          <div>签约管理</div>
          <el-checkbox-group class="checkbox-group" v-model="checkList">
            <el-checkbox label="0" class="checkbox">查看待处理签约</el-checkbox>
            <el-checkbox label="1" class="checkbox">编辑待处理签约</el-checkbox>
            <el-checkbox label="2" class="checkbox">删除待处理签约</el-checkbox>
            <el-checkbox label="3" class="checkbox">导出待处理签约</el-checkbox>
          </el-checkbox-group>
        </el-checkbox>
        <el-checkbox label="3">
          <div>签约管理</div>
          <el-checkbox-group class="checkbox-group" v-model="checkList">
            <el-checkbox label="0" class="checkbox">查看待处理签约</el-checkbox>
            <el-checkbox label="1" class="checkbox">编辑待处理签约</el-checkbox>
            <el-checkbox label="2" class="checkbox">删除待处理签约</el-checkbox>
            <el-checkbox label="3" class="checkbox">导出待处理签约</el-checkbox>
          </el-checkbox-group>
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <!-- 编辑 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 删除确认 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
  </div>
</template>

<script>
import Update from './update.vue'
import Delete from './delete.vue'
export default {
  components: {
    Update,
    Delete
  },
  data () {
    return {
      checkList: [],
      showUpdate: false,
      showDelete: false
    }
  },
  methods: {
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  width: 100%;
  height: calc(100% - 90px);
  padding-top: 10px;
  box-sizing: border-box;

  .run {
    width: 270px;
    height: calc(100% - 57px);
    background-color: #081C38;
    border-radius: 6px;

    .run-title {
      width: 100%;
      height: 36px;
      line-height: 42px;
      padding-left: 36px;
      background-image: url('../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }

    .run-content {
      width: calc(100% - 10px);
      height: calc(100% - 138px);
      margin: 7px auto 0;
      padding: 44px 8px;
      box-sizing: border-box;
      background-color: rgba(29, 64, 112, .33);
      border-radius: 6px;

      .line {
        display: flex;
        align-items: center;
        margin-bottom: 43px;
        color: #fff;
        font-size: 18px;

        .key {
          width: 110px;
        }

        .blue {
          color: #04B4D0;
          cursor: pointer;
        }

        .margin {
          margin-left: 16px;
          cursor: pointer;
        }
      }
    }
  }

  .run-box {
    width: calc(100% - 280px);
    height: 100%;
    margin-left: 10px;
    padding: 34px;
    background-color: #082441;
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;

    .title {
      color: #04B4D0;
      font-size: 18px;
    }

    .el-checkbox {
      color: #01EEFD;
      font-size: 18px;
    }

    /deep/.el-checkbox-group {
      display: flex;
      flex-direction: column;
    }

    /deep/.el-checkbox {
      display: flex;
      margin-top: 19px;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    .checkbox-group {
      flex-direction: row;
    }

    /deep/.el-checkbox__label {
        font-size: 18px;
      }

    .checkbox {
      font-size: 16px;

      /deep/.el-checkbox__label {
        font-size: 16px;
      }
    }
  }
}
</style>
