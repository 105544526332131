<template>
  <div class="info">
    <div class="info-right">
      <div class="title">
        <span class="blue"></span>
        <span>完善档案信息</span>
      </div>
      <el-descriptions class="margin-top" title="机构信息" :column="5" border>
        <el-descriptions-item>
          <template slot="label">
            机构编号
          </template>
          2091900120622
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            机构名称
          </template>
          <el-input placeholder="请输入"></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            机构图片
          </template>
          <div class="flex">
            <img class="head-img" src="../../../img/emr/user.png">
            <span class="el-icon-plus"></span>
            <span>点击上传</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            联系人姓名
          </template>
          <el-input placeholder="请输入"></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            联系电话
          </template>
          <el-input placeholder="请输入"></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            机构地址
          </template>
          <el-input placeholder="请输入"></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            机构地址
          </template>
          <el-input type="textarea" placeholder="请输入" >
          </el-input>
        </el-descriptions-item>
      </el-descriptions>
      <div class="btns">
        <el-button class="submit" size="small" @click="close">保存</el-button>
        <el-button class="close" size="small" @click="close">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return { }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-right {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      color: #01EEFD;
      font-size: 20px;
    }

    .el-descriptions {
      margin-top: 10px;
      padding: 10px 0;
      background-color: transparent;
      border-top: 1px solid #ECECEC;
      font-size: 16px;
    }

    .head-img {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }

    /deep/.el-descriptions__header {
      color: #01EEFD;
      font-size: 18px;
    }

    /deep/.el-descriptions__body {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.el-descriptions-item__label.is-bordered-label {
      background-color: transparent;
      text-align: right;
      // text-align-last: justify;
      color: #01EEFD;
      white-space: nowrap;
    }

    /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
      border: none;
      padding: 6px;
    }

    /deep/.el-textarea__inner {
      width: 587px;
      resize: none;
    }

    .flex {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }

    .btns {
      display: flex;
      align-items: center;
      position: absolute;
      left: 40px;
      bottom: 40px;
    }

    .submit,
    .close {
      margin-right: 20px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #FFFFFF;
      font-size: 14px;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }

    .green {
      background-color: #00BB7E;
    }
  }
}
</style>
