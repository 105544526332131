<template>
  <div class="content">
    <div class="search">
      <div class="item">
        <span class="key">选择日期</span>
        <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>
    <div class="scroll">
      <div class="title">
        <span>数据概况</span>
      </div>
      <div class="line">
        <div class="xy" id="xy"></div>
      </div>
      <div class="table">
        <div class="table-head">
          <el-button size="small">导出</el-button>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>数据类型</span>
          </div>
          <div class="table-td">
            <span>2021/04/01</span>
          </div>
          <div class="table-td">
            <span>2021/04/02</span>
          </div>
          <div class="table-td">
            <span>2021/04/03</span>
          </div>
          <div class="table-td">
            <span>2021/04/04</span>
          </div>
          <div class="table-td">
            <span>2021/04/05</span>
          </div>
          <div class="table-td">
            <span>2021/04/06</span>
          </div>
          <div class="table-td">
            <span>2021/04/07</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>新增居民类型</span>
          </div>
          <div class="table-td">
            <span>2021/04/01</span>
          </div>
          <div class="table-td">
            <span>2021/04/02</span>
          </div>
          <div class="table-td">
            <span>2021/04/03</span>
          </div>
          <div class="table-td">
            <span>2021/04/04</span>
          </div>
          <div class="table-td">
            <span>2021/04/05</span>
          </div>
          <div class="table-td">
            <span>2021/04/06</span>
          </div>
          <div class="table-td">
            <span>2021/04/07</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>新增签约量</span>
          </div>
          <div class="table-td">
            <span>2021/04/01</span>
          </div>
          <div class="table-td">
            <span>2021/04/02</span>
          </div>
          <div class="table-td">
            <span>2021/04/03</span>
          </div>
          <div class="table-td">
            <span>2021/04/04</span>
          </div>
          <div class="table-td">
            <span>2021/04/05</span>
          </div>
          <div class="table-td">
            <span>2021/04/06</span>
          </div>
          <div class="table-td">
            <span>2021/04/07</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>新增服务量</span>
          </div>
          <div class="table-td">
            <span>2021/04/01</span>
          </div>
          <div class="table-td">
            <span>2021/04/02</span>
          </div>
          <div class="table-td">
            <span>2021/04/03</span>
          </div>
          <div class="table-td">
            <span>2021/04/04</span>
          </div>
          <div class="table-td">
            <span>2021/04/05</span>
          </div>
          <div class="table-td">
            <span>2021/04/06</span>
          </div>
          <div class="table-td">
            <span>2021/04/07</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>合计</span>
          </div>
          <div class="table-td">
            <span>2021/04/01</span>
          </div>
          <div class="table-td">
            <span>2021/04/02</span>
          </div>
          <div class="table-td">
            <span>2021/04/03</span>
          </div>
          <div class="table-td">
            <span>2021/04/04</span>
          </div>
          <div class="table-td">
            <span>2021/04/05</span>
          </div>
          <div class="table-td">
            <span>2021/04/06</span>
          </div>
          <div class="table-td">
            <span>2021/04/07</span>
          </div>
        </div>
      </div>

      <div class="title">
        <span>数据概况</span>
      </div>
      <div class="line">
        <div class="pie" id="pie1"></div>
        <div class="pie" id="pie2"></div>
        <div class="pie" id="pie3"></div>
      </div>

      <div class="line line1">
        <div class="line-left">
          <div class="title">
            <span>居民标签</span>
          </div>
          <div class="charts">
            <div class="charts-line">
              <span>高血压</span>
              <div class="progress">
                <div class="progress-box" :style="{ width: '80%' }"></div>
              </div>
              <span>200人</span>
            </div>
            <div class="charts-line">
              <span>冠心病</span>
              <div class="progress">
                <div class="progress-box" :style="{ width: '80%' }"></div>
              </div>
              <span>200人</span>
            </div>
            <div class="charts-line">
              <span>高血糖</span>
              <div class="progress">
                <div class="progress-box" :style="{ width: '80%' }"></div>
              </div>
              <span>200人</span>
            </div>
            <div class="charts-line">
              <span>高血脂</span>
              <div class="progress">
                <div class="progress-box" :style="{ width: '80%' }"></div>
              </div>
              <span>200人</span>
            </div>
            <div class="charts-line">
              <span>慢病护理</span>
              <div class="progress">
                <div class="progress-box" :style="{ width: '80%' }"></div>
              </div>
              <span>200人</span>
            </div>
            <div class="charts-line">
              <span>多动症</span>
              <div class="progress">
                <div class="progress-box" :style="{ width: '80%' }"></div>
              </div>
              <span>200人</span>
            </div>
          </div>
        </div>
        <div class="line-right">
          <div class="title">
            <span>服务包使用情况</span>
          </div>
          <div class="bar" id="bar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartXy: null,
      chartPie1: null,
      chartPie2: null,
      chartPie3: null,
      chartBar: null
    }
  },
  mounted () {
    this.canvasXy()
    this.canvasPie1()
    this.canvasPie2()
    this.canvasPie3()
    this.canvasBar()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      this.chartXy.resize()
      this.chartPie1.resize()
      this.chartPie2.resize()
      this.chartPie3.resize()
      this.chartBar.resize()
    },
    canvasXy () {
      var chartDom = document.getElementById('xy')
      this.chartXy = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          //   指示框名字  注意！要和下方series中的name一起改
          data: ['居民数量', '签约量', '服务量'],
          // 指示框位置  距离上下左右多少
          x: 'right',
          y: 'top',
          // top: '50%',
          textStyle: {
            color: '#BAE0FF' // 字体颜色
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1月', '1月', '1月', '1月', '1月', '1月', '1月'],
          axisLabel: {
            show: true,
            color: '#04B4D0',
            fontSize: 15
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 15
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            color: '#04B4D0',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            }
          },
          axisLabel: {
            show: true,
            color: '#04B4D0',
            fontSize: 15
          },
          splitLine: {
            lineStyle: {
              color: '#04B4D0'
            }
          }
        },
        series: [
          {
            name: '居民数量',
            type: 'line',
            stack: 'Total',
            data: [120, 132, 101, 134, 90, 230, 210],
            itemStyle: {
              color: '#fff' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#0077F9' // 改变折线颜色
            }
          },
          {
            name: '签约量',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            itemStyle: {
              color: '#fff' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#00F9D7' // 改变折线颜色
            }
          },
          {
            name: '服务量',
            type: 'line',
            stack: 'Total',
            data: [220, 182, 191, 234, 290, 330, 310],
            itemStyle: {
              color: '#fff' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#F95B00' // 改变折线颜色
            }
          }
        ]
      }
      option && this.chartXy.setOption(option)
    },
    canvasPie1 () {
      var chartDom = document.getElementById('pie1')
      this.chartPie1 = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          x: 'left',
          y: 'top',
          type: 'scroll',
          orient: 'vertical',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          formatter: function (name) {
            var data = option.series[0].data
            var tarValue
            for (var i = 0; i < data.length; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value
              }
            }
            var v = tarValue
            return `${name}  ${v}人`
          }
        },
        title: {
          text: '1000人',
          left: 'center',
          top: '50%',
          textStyle: {
            textAlign: 'center',
            color: '#04B4D0',
            fontSize: 16,
            fontWeight: 400
          }
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: '40%',
          style: {
            text: '居民总数',
            textAlign: 'center',
            fill: '#04B4D0',
            fontSize: 16
          }
        },
        series: [
          {
            name: '居民',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '10岁以下' },
              { value: 735, name: '10-30岁' },
              { value: 580, name: '30-40岁' },
              { value: 484, name: '40-50岁' },
              { value: 300, name: '50岁以上' }
            ]
          }
        ]
      }
      option && this.chartPie1.setOption(option)
    },
    canvasPie2 () {
      var chartDom = document.getElementById('pie2')
      this.chartPie2 = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          x: 'left',
          y: 'top',
          type: 'scroll',
          orient: 'vertical',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          formatter: function (name) {
            var data = option.series[0].data
            var tarValue
            for (var i = 0; i < data.length; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value
              }
            }
            var v = tarValue
            return `${name}  ${v}人`
          }
        },
        title: {
          text: '1000人',
          left: 'center',
          top: '50%',
          textStyle: {
            textAlign: 'center',
            color: '#04B4D0',
            fontSize: 16,
            fontWeight: 400
          }
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: '40%',
          style: {
            text: '居民总数',
            textAlign: 'center',
            fill: '#04B4D0',
            fontSize: 16
          }
        },
        series: [
          {
            name: '居民',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '小学' },
              { value: 735, name: '初中' },
              { value: 580, name: '高中/中专' },
              { value: 484, name: '大专以上' },
              { value: 300, name: '不详' }
            ]
          }
        ]
      }
      option && this.chartPie2.setOption(option)
    },
    canvasPie3 () {
      var chartDom = document.getElementById('pie3')
      this.chartPie3 = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          x: 'left',
          y: 'top',
          type: 'scroll',
          orient: 'vertical',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          formatter: function (name) {
            var data = option.series[0].data
            var tarValue
            for (var i = 0; i < data.length; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value
              }
            }
            var v = tarValue
            return `${name}  ${v}人`
          }
        },
        title: {
          text: '1000人',
          left: 'center',
          top: '50%',
          textStyle: {
            textAlign: 'center',
            color: '#04B4D0',
            fontSize: 16,
            fontWeight: 400
          }
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: '40%',
          style: {
            text: '居民总数',
            textAlign: 'center',
            fill: '#04B4D0',
            fontSize: 16
          }
        },
        series: [
          {
            name: '居民',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '男' },
              { value: 735, name: '女' }
            ]
          }
        ]
      }
      option && this.chartPie3.setOption(option)
    },
    canvasBar () {
      var chartDom = document.getElementById('bar')
      this.chartBar = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: ['老年人护理包', '基础包1', '基础包2', '基础包3', '基础包4', '儿童护理包', '高级特需包'],
          axisLabel: {
            show: true,
            color: '#04B4D0',
            fontSize: 13,
            interval: 0,
            rotate: -20
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            }
          },
          axisLabel: {
            show: true,
            color: '#04B4D0',
            fontSize: 14
          },
          splitLine: {
            lineStyle: {
              color: '#04B4D0'
            }
          }
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
            barWidth: '20%'
          }
        ]
      }
      option && this.chartBar.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: calc(100% - 90px);

  .search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      color: #01EEFD;
      font-size: 16px;

      .key {
        margin-right: 42px;
      }
    }
  }

  .scroll {
    overflow-y: scroll;
    width: calc(100% - 192px);
    height: calc(100% - 100px);
    margin: 0 auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      height: 57px;
      line-height: 57px;
      color: #04B4D0;
      font-size: 17px;
    }

    .line {
      display: flex;
      align-items: center;
      width: 100%;

      .xy {
        width: 100%;
        height: 347px;
      }

      .pie {
        width: 32%;
        height: 300px;
      }

      .line-left {
        width: 400px;
      }

      .line-right {
        width: calc(100% - 400px);
        height: 300px;

        .bar {
          width: 100%;
          height: 300px;
        }
      }

      .charts {
        display: flex;
        flex-direction: column;

        .charts-line {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          color: #04B4D0;
          font-size: 16px;

          .progress {
            display: flex;
            align-items: center;
            width: 234px;
            height: 8px;
            margin-left: 10px;
            margin-right: 21px;
            border-radius: 4px;
            background-color: #ACD3FF;

            .progress-box {
              height: 8px;
              border-radius: 4px;
              background-color: #04B4D0;
            }
          }
        }
      }
    }

    .line1 {
      align-items: initial;
    }

    .table {
      display: flex;
      flex-direction: column;

      .table-head {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        height: 61px;

        .el-button {
          background-color: #367AF6;
          border-radius: 6px;
          border: none;
          color: #fff;
        }
      }

      .table-tr {
        display: flex;
        align-items: center;
        height: 41px;
        background-color: #3569B3;

        .table-td {
          flex: 1;
          text-align: center;
          color: #FFFFFF;
          font-size: 16px;
        }
      }

      .table-tr:nth-child(odd) {
        background-color: #103A75;
      }

      .table-tr:nth-child(even) {
        background-color: #3569B3;
      }
    }
  }
}
</style>
