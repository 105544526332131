<template>
  <div class="info">
    <div class="info-right">
      <div class="title">
        <span class="blue"></span>
        <span>新增团队信息</span>
      </div>
      <el-descriptions class="margin-top" title="团队信息" :column="5" border>
        <el-descriptions-item>
          <template slot="label">
            团队编号
          </template>
          2091900120622
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            团队名称
          </template>
          <el-input placeholder="请输入"></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            居民头像
          </template>
          <div class="flex">
            <img class="head-img" src="../../../img/emr/user.png">
            <span class="el-icon-plus"></span>
            <span>点击上传</span>
          </div>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            所属机构
          </template>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            团队标签
          </template>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>
      </el-descriptions>
      <!-- <el-descriptions class="margin-top" title="团队成员" :column="5" border>
        <el-descriptions-item>
          <div class="item">
            <img class="user-img" src="../../../img/emr/user.png">
            <div class="news">
              <div class="news-line">
                <span class="name">李明明</span>
                <span>全科医生</span>
              </div>
              <div class="news-line">
                <span>签约人数 50人</span>
              </div>
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions> -->
      <div class="user-sroll">
        <div class="sroll-title">
          <span>团队成员</span>
        </div>
        <el-row>
          <el-col :span="4">
            <div class="item">
              <img class="user-img" src="../../../img/emr/user.png">
              <div class="news">
                <div class="news-line">
                  <span class="name">李明明</span>
                  <span>全科医生</span>
                </div>
                <div class="news-line">
                  <span>签约人数 50人</span>
                </div>
                <div class="news-line">
                  <el-tag size="small" class="red">高血压</el-tag>
                  <el-tag size="small" class="red green">老年护理</el-tag>
                </div>
              </div>
              <div class="position">
                <span>医生详情</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="sroll-title">
          <span>服务包</span>
        </div>
        <el-row>
          <el-col :span="4">
            <div class="item">
              <div class="ing">
                <span>使用中</span>
              </div>
              <img class="user-img" src="../../../img/emr/user.png">
              <div class="news">
                <div class="news-line">
                  <span class="name">基础包</span>
                </div>
                <div class="news-line">
                  <span>服务对象  所有人</span>
                </div>
                <div class="news-line">
                  <el-tag size="small" class="red">随访服务</el-tag>
                  <el-tag size="small" class="red green">常规护理</el-tag>
                </div>
              </div>
              <div class="position">
                <span>服务包详情</span>
              </div>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="item">
              <div class="add">
                <i class="el-icon-plus"></i>
                <span>添加服务包</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="btns">
        <el-button class="submit" size="small" @click="close">保存</el-button>
        <el-button class="close" size="small" @click="close">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-right {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      color: #01EEFD;
      font-size: 20px;
    }

    .el-descriptions {
      margin-top: 10px;
      padding: 10px 0;
      background-color: transparent;
      border-top: 1px solid #01EEFD;
      font-size: 16px;
    }

    .head-img {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }

    /deep/.el-descriptions__header {
      color: #01EEFD;
      font-size: 18px;
    }

    /deep/.el-descriptions__body {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.el-descriptions-item__label.is-bordered-label {
      background-color: transparent;
      text-align: right;
      // text-align-last: justify;
      color: #01EEFD;
      white-space: nowrap;
    }

    /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
      border: none;
      padding: 6px;
    }

    .flex {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }

    .user-sroll {
      width: 100%;
      height: calc(100% - 300px);
      border-top: 1px solid #01EEFD;

      .sroll-title {
        height: 56px;
        line-height: 56px;
        color: #01EEFD;
        font-size: 18px;
      }

      .item {
        overflow: hidden;
        position: relative;
        display: flex;
        width: 240px;
        height: 133px;
        padding: 6px;
        background: linear-gradient(180deg, #0162AE 0%, #015394 100%);
        box-shadow: 0px 3px 4px 1px rgba(157, 157, 157, 0.1608);
        border-radius: 6px;
        box-sizing: border-box;

        .ing {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          position: absolute;
          top: -20px;
          right: -38px;
          z-index: 88;
          width: 110px;
          height: 47px;
          background-color: #FF0000;
          color: #fff;
          font-size: 14px;
          transform: rotate(30deg);
        }

        .add {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          color: #FFFFFF;
          font-size: 14px;

          i {
            margin-right: 4px;
            font-size: 20px;
            font-weight: bolder;
          }
        }

        .user-img {
          width: 52px;
          height: 52px;
          border-radius: 6px;
        }

        .news {
          height: 100%;
          margin-left: 6px;

          .news-line {
            margin-bottom: 6px;
            color: #fff;
            font-size: 12px;

            .name {
              margin-right: 4px;
              font-size: 18px;
            }

            .red {
              background-color: #FFC4C4;
              border-radius: 12px;
              border: none;
              color: #FF0000;
              font-size: 14px;
            }

            .green {
              margin-left: 10px;
              background-color: #B6FFB6;
              color: #0BBB0B;
            }
          }
        }

        .position {
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 3px 6px;
          background-color: #3670F6;
          border-radius: 0px 0px 6px 0;
          color: #fff;
          font-size: 14px;
        }
      }
    }

    .btns {
      display: flex;
      align-items: center;
      position: absolute;
      left: 40px;
      bottom: 40px;
    }

    .submit,
    .close {
      margin-right: 20px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #FFFFFF;
      font-size: 14px;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }

    .green {
      background-color: #00BB7E;
    }
  }
}
</style>
