<template>
  <div class="info">
    <div class="info-right">
      <div class="title">
        <span class="blue"></span>
        <span>新增服务包</span>
      </div>
      <div class="scroll">
        <div class="card">
          <div class="card-title">
            <span>服务包信息</span>
          </div>
          <div class="card-box">
            <div class="item">
              <span class="key">服务包名称</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="item">
              <span class="key">服务包类型</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <span class="key">服务包类型</span>
              <el-input placeholder="请输入"></el-input>
              <span class="icon">元/年</span>
            </div>
            <div class="item">
              <span class="key">服务对象</span>
              <el-input placeholder="请输入"></el-input>
            </div>
            <div class="item">
              <span class="key">服务包标签</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <span class="key">签约周期</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="item">
              <span class="key">签约周期</span>
              <el-radio-group v-model="radio">
                <el-radio label="0">可续签</el-radio>
                <el-radio label="1">不可续签</el-radio>
              </el-radio-group>
            </div>
            <div class="item">
              <span class="key">服务包图片</span>
              <el-upload class="avatar-uploader" action="" :show-file-list="false" :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <div v-else class="avatar-uploader-icon">
                  <i class="el-icon-plus icon"></i>
                  <span class="span">上传图片</span>
                </div>
              </el-upload>
            </div>
            <div class="item">
              <span class="key">服务项目</span>
              <el-input type="textarea" placeholder="请输入内容">
              </el-input>
            </div>
          </div>
          <div class="table-head">
            <span>服务项目</span>
            <el-button class="add" size="small">添加服务项目</el-button>
          </div>
          <el-table :data="tableData" height="200" max-height="200" :header-cell-style="{ background: '#024276' }">
            <el-table-column prop="name" label="项目编号">
            </el-table-column>
            <el-table-column prop="date" label="项目名称">
            </el-table-column>
            <el-table-column prop="date" label="次数">
            </el-table-column>
            <el-table-column prop="type" label="项目类型">
            </el-table-column>
            <el-table-column prop="type1" label="项目介绍"></el-table-column>
            <el-table-column prop="type1" label="操作">
              <template>
                <div class="delete">
                  <span class="el-icon-delete-solid"></span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="table-head table-border">
            <span>服务详情字段</span>
          </div>
          <div class="card-box card-padding">
            <div class="item item1">
              <span class="key">病情描述</span>
              <el-input type="textarea" placeholder="请输入内容">
              </el-input>
              <div class="del">
                <span class="el-icon-delete-solid"></span>
              </div>
            </div>
            <div class="item item1">
              <span class="key">健康指导</span>
              <el-input type="textarea" placeholder="请输入内容">
              </el-input>
              <div class="del del-blue">
                <span class="el-icon-delete-solid"></span>
              </div>
            </div>
            <div class="item item1">
              <span class="key">备注</span>
              <el-input type="textarea" placeholder="请输入内容">
              </el-input>
              <div class="del">
                <span class="el-icon-delete-solid"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <el-button class="submit" size="small" @click="close">提交审核</el-button>
        <el-button class="close" size="small" @click="close">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      checkList: ['1'],
      radio: '0',
      imageUrl: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-right {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #01EEFD;
      color: #01EEFD;
      font-size: 20px;

      .blue {
        width: 4px;
        height: 18px;
        margin-right: 4px;
        border-radius: 2px;
        background-color: #366FF7;
      }
    }

    .scroll {
      overflow-y: scroll;
      height: calc(100% - 120px);

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;

      .card-title {
        padding-bottom: 10px;
        color: #01EEFD;
        font-size: 18px;
      }

      .card-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          color: #01EEFD;
          font-size: 18px;

          .key {
            margin-right: 10px;
            white-space: nowrap;
          }

          .icon {
            margin-left: 10px;
            white-space: nowrap;
            color: #B5B5B5;
            font-size: 16px;
          }

          /deep/.avatar-uploader .el-upload {
            border: 1px solid #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }

          .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
          }

          .avatar-uploader-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 177px;
            height: 141px;

            .icon {
              font-size: 28px;
              color: #fff;
            }

            .span {
              color: 14px;
              color: #fff;
            }
          }

          .avatar {
            width: 178px;
            height: 178px;
            display: block;
          }

          .del {
            display: flex;
            flex-direction: column-reverse;
            height: 100%;
            margin-left: 20px;
          }

          .del-blue {
            color: #0077F9;
          }
        }
      }

      .card-padding {
          width: 100%;
          padding: 0 20px;
          box-sizing: border-box;

          .item1 {
            align-items: flex-start;
          }
        }

      .table-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        padding: 0 10px;
        border-top: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 18px;

        .add {
          background: linear-gradient(180deg, #0163AC 0%, #014D88 100%);
          border-radius: 2px;
          border: none;
          color: #fff;
        }
      }

      .table-border {
        border: none;
      }

      ::v-deep .el-table__body-wrapper {
        &::-webkit-scrollbar {
          width: 0rem;
        }
      }

      ::v-deep .el-table th.gutter {
        display: none;
        width: 0;
      }

      ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      ::v-deep .el-table__body {
        width: 100% !important;
      }

      .el-table {
        background-color: #092B50;
        border: .05rem #073F73 solid;
      }

      // 表头文字颜色
      /deep/.el-table__header-wrapper {
        thead {
          th {
            div {
              color: #01EEFD;
              font-size: .2125rem;
              font-weight: 400;
            }
          }
        }
      }

      //表格内容颜色
      /deep/.el-table__body tbody tr {
        color: #fff;
        background-color: #092B50;
      }

      /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #092B50;
      }

      /deep/.el-table tbody tr:hover>td {
        background-color: transparent !important
      }

      // 改变边框颜色
      .el-table--border,
      .el-table--group {
        border: .025rem solid #ddd !important;
      }

      /**
  改变表格内竖线颜色
   */
      .el-table--border td,
      .el-table--border th,
      .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
        border-right: .0125rem solid #ddd !important;
      }

      /deep/.el-table td {
        border-bottom: .0125rem solid #01EEFD;
      }

      /deep/.el-table thead tr th {
        border-color: transparent;
      }

      /deep/ .el-table--border::after,
      /deep/ .el-table--group::after,
      /deep/ .el-table::before {
        background-color: #073F73;
      }

      /deep/.el-table--border {
        border: .0125rem solid #073F73 !important;
      }

      /deep/.el-table .cell {
        text-align: center;
        color: #01EEFD;
      }
    }

    /deep/.el-textarea__inner {
      width: 300px;
      height: 107px;
      resize: none;
    }

    .delete {
      color: #0077F9;
      font-size: 20px;
    }

    .btns {
      display: flex;
      align-items: center;
      position: absolute;
      left: 40px;
      bottom: 40px;
    }

    .submit,
    .close {
      margin-right: 20px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #FFFFFF;
      font-size: 14px;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
