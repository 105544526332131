<template>
  <div class="children-page">
    <div class="head">
      <el-button size="small">新增签约居民</el-button>
    </div>
    <div class="info">
      <div class="table">
        <div class="tr">
          <div class="left">
            <span>第一团队1组</span>
          </div>
          <div class="right">
            <div class="flex">
              <span class="el-icon-s-custom"></span>
              <span class="number">签约量：2809</span>
            </div>
            <div class="flex">
              <span class="el-icon-document"></span>
              <span class="number">签约量：2809</span>
            </div>
            <el-button size="mini" @click="openSign">签约</el-button>
          </div>
        </div>
        <div class="scroll">
          <div class="list">
            <div class="item" :class="{ 'item-border-bottom-none': index > 3 }" v-for="(item, index) in 8" :key="index">
              <div class="news" :class="{ 'item-border-right-none': [3, 7].indexOf(index) > -1 }">
                <img class="img">
                <div class="message">
                  <span>郭秋生</span>
                  <span>擅长：内科、外科</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="all">
        <span>显示全部医生</span>
      </div>
    </div>
    <div class="info">
      <div class="table">
        <div class="tr">
          <div class="left">
            <span>第一团队1组</span>
          </div>
          <div class="right">
            <div class="flex">
              <span class="el-icon-s-custom"></span>
              <span class="number">签约量：2809</span>
            </div>
            <div class="flex">
              <span class="el-icon-document"></span>
              <span class="number">签约量：2809</span>
            </div>
            <el-button size="mini">签约</el-button>
          </div>
        </div>
        <div class="scroll">
          <div class="list">
            <div class="item" :class="{ 'item-border-bottom-none': index > 3 }" v-for="(item, index) in 8" :key="index">
              <div class="news" :class="{ 'item-border-right-none': [3, 7].indexOf(index) > -1 }">
                <img class="img">
                <div class="message">
                  <span>郭秋生</span>
                  <span>擅长：内科、外科</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="all">
        <span>显示全部医生</span>
      </div>
    </div>
    <Sign v-if="showSign" @close="closeSign"></Sign>
  </div>
</template>

<script>
import Sign from './sign.vue'
export default {
  components: {
    Sign
  },
  data () {
    return {
      showSign: false
    }
  },
  methods: {
    openSign () {
      this.showSign = true
    },
    closeSign () {
      this.showSign = false
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: 100%;
  height: 100%;
  background-color: #081C38;
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    box-sizing: border-box;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
      background-color: #01EEFD;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 340px;
    margin-top: 10px;
    padding: 0 65px;
    padding-top: 20px;
    background-color: #024276;
    box-sizing: border-box;

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 1px solid #01EEFD;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 0 34px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;

        .left {
          color: #fff;
          font-size: 18px;
        }

        .right {
          display: flex;
          align-items: center;

          .flex {
            margin-right: 30px;
            color: #01EEFD;
            font-size: 18px;

            .number {
              margin-left: 10px;
              color: #fff;
            }
          }

          .el-button {
            background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
            color: #fff;
            border: none;
          }
        }
      }

      .scroll {
        width: 100%;
        height: 190px;

        .list {
          display: flex;
          flex-wrap: wrap;
          width: 100%;

          .item {
            display: flex;
            align-items: center;
            width: 25%;
            height: 95px;
            border-bottom: 1px solid #01EEFD;
            box-sizing: border-box;

            .news {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 55px;
              border-right: 1px solid #01EEFD;
              box-sizing: border-box;

              .img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-color: #fff;
              }

              .message {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: calc(100% - 10px);
                margin-left: 7px;
                color: #01EEFD;
                font-size: 14px;
              }
            }

            .item-border-right-none {
              border-right: none;
            }
          }

          .item-border-bottom-none {
            border-bottom: none;
          }

        }
      }
    }

    .all {
      width: 100%;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #FFFFFF;
      font-size: 14px;
    }
  }

}
</style>
