<template>
  <div class="children-page">
    <div class="head">
      <el-button>处理意见模板</el-button>
    </div>
    <div class="title">
      <span class="text">模板分类</span>
      <el-button size="mini">增加模板</el-button>
    </div>
    <div class="info">
      <div class="info-left">
        <div class="item" :class="{ 'item-active': selectIndex === index }" v-for="(item, index) in 3" :key="index" @click="select(index)">
          <div class="item-title">
            <span>高血压患者处理意见示例</span>
          </div>
          <div class="item-operate">
            <span class="update" :class="{ 'active': selectIndex === index }" >修改</span>
            <span>删除</span>
          </div>
        </div>
      </div>
      <div class="info-right">
        <div class="add">
          <div class="icon">
            <span class="el-icon-plus"></span>
          </div>
        </div>
        <div class="table">
          <el-table :data="tableData" border max-height="500" height="500">
          <el-table-column prop="name" label="序号">
          </el-table-column>
          <el-table-column prop="address" label="内容"></el-table-column>
          <el-table-column prop="address" label="状态"></el-table-column>
          <el-table-column prop="address" label="操作" width="140">
            <template>
              <div class="operate">
                <div class="operate-item">
                  <span>编辑</span>
                </div>
                <div class="operate-item">
                  <span>停用</span>
                </div>
                <div class="operate-item">
                  <span>删除</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      checked: false,
      showSee: false,
      showAgreement: false,
      selectIndex: 0
    }
  },
  methods: {
    select (index) {
      this.selectIndex = index
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: 100%;
  height: 100%;
  background-color: #081C38;
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    padding: 10px 0;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      margin-right: 20px;
      text-align: center;
      border: 0;
      border-radius: 0;
      background-color: #01EEFD;
      color: #fff;
      font-size: 22px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 10px;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 18px;
    font-weight: bold;

    .text {
      margin-right: 10px;
    }

    .el-button {
      border: 0;
      border-radius: 0;
      background-color: #01EEFD;
      color: #fff;
    }
  }

  .info {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 140px);
    padding: 20px;
    padding-right: 48px;
    background-color: #024276;
    box-sizing: border-box;

    .info-left {
      width: 400px;
      height: 100%;
      background-color: #1B5C90;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 78px;
        padding-top: 14px;
        padding-left: 20px;
        padding-bottom: 5px;
        box-sizing: border-box;
        cursor: pointer;

        .item-title {
          color: #fff;
          font-size: 18px;
        }

        .item-operate {
          display: flex;
          align-items: center;
          width: 100%;
          padding-left: 160px;
          box-sizing: border-box;
          color: #fff;
          font-size: 18px;
        }

        .active {
          color: #01EEFD;
        }

        .update {
          margin-right: 41px;
        }
      }

      .item-active {
        background-color: #5581A5;
      }
    }

    .info-right {
      width: calc(100% - 430px);
      height: 100%;
      margin-left: 30px;

      .add {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        height: 80px;

        .icon {
          width: 30px;
          height: 30px;
          line-height: 30px;
          background-color: #04B4D0;
          border-radius: 50%;
          text-align: center;
          color: #fff;
          font-size: 18px;
          font-weight: bold;
          cursor: pointer;
        }
      }

      .table {
        width: 100%;
        height: calc(100% - 80px);
        padding: 20px;
        background-color: #1B5C90;
        box-sizing: border-box;
      }
    }

    .el-table {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.el-table__header {
      border-right: 1px solid #01EEFD !important;
    }

    /deep/.el-table--border {
      border: 1px solid #01EEFD;
    }

    /deep/.el-table th.el-table__cell {
      background-color: transparent;
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-table tr {
      background-color: transparent;
    }

    /deep/.el-table__cell {
      text-align: center;
    }

    /deep/.el-table th.el-table__cell {
      // color: #fff;
    }

    /deep/.el-table th.el-table__cell.is-leaf {
      border-bottom: 1px solid #01EEFD;
    }

    /deep/.el-table--border .el-table__cell {
      border-bottom: 1px solid #01EEFD;
      border-right: 1px solid #01EEFD;
    }

    /deep/.el-table--border::after,
    /deep/.el-table::before {
      background-color: transparent;
    }

    /deep/.el-table__body tr:hover>td {
      background-color: transparent;
    }

    /deep/.el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0px;
      }

      &::-webkit-scrollbar-track {
        border: none;
      }
    }

    /deep/.el-table th.gutter {
      display: none;
      width: 0
    }

    /deep/.el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    /deep/.el-table__body {
      width: 100% !important;
    }

    .operate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 5px;
      box-sizing: border-box;

      .operate-item {
        text-align: left;
        white-space: nowrap;
        cursor: pointer;
      }

      .operate-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
