var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"write"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"close",on:{"click":_vm.closePopup}},[_c('span',{staticClass:"el-icon-close"})]),_vm._m(1),_c('div',{staticClass:"content-positon"},[_c('el-button',{staticClass:"blue",attrs:{"size":"mini"}},[_vm._v("打印")])],1),_vm._m(2),_c('div',{staticClass:"content-scroll"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"scroll-box"},[_c('div',{staticClass:"scroll-box-title"},[_c('span',[_vm._v("处理意见")]),_c('el-button',{staticClass:"button",attrs:{"size":"mini"},on:{"click":_vm.openSelect}},[_vm._v("选择模板")])],1),_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入"}})],1),_vm._m(5),_c('div',{staticClass:"btn"},[_c('el-button',{staticClass:"submit",attrs:{"size":"mini"}},[_vm._v("确定")])],1)])]),(_vm.showSelect)?_c('Select',{on:{"close":_vm.closeSelect}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("随访记录")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-title"},[_c('span',[_vm._v("高血压随访履约服务记录")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('span',[_vm._v("服务编号: 00123420")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('div',{staticClass:"scroll-line-item scroll-line-item1"},[_c('span',[_vm._v("李明明")])]),_c('div',{staticClass:"scroll-line-item scroll-line-item1"},[_c('span',{staticClass:"span"},[_vm._v("男")]),_c('span',{staticClass:"span"},[_vm._v("53岁")]),_c('span',{staticClass:"span"},[_vm._v("手机号：15808082258")]),_c('span',[_vm._v("身份证号：1307301998158451589")])]),_c('div',{staticClass:"scroll-line-item scroll-line-item3"},[_c('span',[_vm._v("服务时间：2022-08-03 12:00:00")])]),_c('div',{staticClass:"scroll-line-item scroll-line-item3"},[_c('span',[_vm._v("服务方式：接诊服务")])]),_c('div',{staticClass:"scroll-line-item scroll-line-item3"},[_c('span',[_vm._v("付费形式：免费")])]),_c('div',{staticClass:"scroll-line-item scroll-line-item3"},[_c('span',[_vm._v("服务内容：高血压随访")])]),_c('div',{staticClass:"scroll-line-item scroll-line-item4"},[_c('span',[_vm._v("服务方机构：古城营镇卫生院")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-line"},[_c('div',{staticClass:"scroll-line-item size scroll-line-item1 scroll-line-item5"},[_c('span',{staticClass:"width1"},[_vm._v("症状：无症状")]),_c('span',{staticClass:"width1"},[_vm._v("血压值：无症状137/78mmHg")]),_c('span',{staticClass:"width1"},[_vm._v("体重：67.5kg")]),_c('span',{staticClass:"width1"},[_vm._v("目标体重：6kg")]),_c('span',{staticClass:"width1"},[_vm._v("体质指数(BM)：23.9kg/m2")])]),_c('div',{staticClass:"scroll-line-item size scroll-line-item1 scroll-line-item5"},[_c('span',{staticClass:"width1"},[_vm._v("目标体质指教(BMI): 22.28kg/m")]),_c('span',{staticClass:"width1 width3"},[_vm._v("心率: 63次/分钟，日吸烟量: 0支，日饮酒量: 0两")]),_c('span',{staticClass:"width1 width3"},[_vm._v("运动次数: 0次/周; 运动时长: 0分/次")])]),_c('div',{staticClass:"scroll-line-item size scroll-line-item1 scroll-line-item5"},[_c('span',{staticClass:"width1"},[_vm._v("摄盐情况(咸淡)：轻")]),_c('span',{staticClass:"width1"},[_vm._v("心理调整评价：良好")]),_c('span',{staticClass:"width1"},[_vm._v("遵医行为评价：良好 ")]),_c('span',{staticClass:"width1"},[_vm._v("药依从性：规律")]),_c('span',{staticClass:"width1"})]),_c('div',{staticClass:"scroll-line-item size scroll-line-item1 scroll-line-item5"},[_c('span',{staticClass:"width1"},[_vm._v("药物不良反应:无 ")]),_c('span',{staticClass:"width1 width2"},[_vm._v("此次随访分类(随访评价) : 控制满意;")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sign"},[_c('span',{staticClass:"doctor"},[_vm._v("医生签字：")]),_c('span',[_vm._v("居民签字：")])])
}]

export { render, staticRenderFns }