<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>设置目标签约量</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="box">
            <div class="box-title">
              <span>设置目标签约量</span>
            </div>
            <div class="box-list">
              <div class="item">
                <div class="key">
                  <span>选择年份</span>
                </div>
                <el-select class="select" v-model="value" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="item">
                <div class="key">
                  <span>选择团队</span>
                </div>
                <el-select class="select" v-model="value" placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="item">
                <div class="key">
                  <span>全年目标</span>
                </div>
                <el-input placeholder="请输入"></el-input>
                <div class="value">
                  <span>平均分配到每月</span>
                </div>
              </div>
            </div>
            <div class="line">
              <div class="line-title">
                <span>第一季度</span>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>1月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>3月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>4月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
            </div>
            <div class="line">
              <div class="line-title">
                <span>第二季度</span>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>4月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>5月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>6月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
            </div>
            <div class="line">
              <div class="line-title">
                <span>第三季度</span>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>7月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>8月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>9月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
            </div>
            <div class="line">
              <div class="line-title">
                <span>第四季度</span>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>10月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>11月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
              <div class="line-item">
                <div class="text">
                  <span>12月</span>
                </div>
                <el-input placeholder="请输入"></el-input>
              </div>
            </div>
          </div>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 859px;
    height: 588px;

    .popup-title {
      width: 247px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 730px;
      height: 535px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 10px 20px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .box {
          display: flex;
          flex-direction: column;

          .box-title {
            height: 46px;
            line-height: 46px;
            color: #03BCD7;
            font-size: 16px;
            font-weight: bold;
          }

          .box-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .item {
              display: flex;
              align-items: center;
              margin-bottom: 18px;

              .key {
                margin-right: 10px;
                white-space: nowrap;
                color: #03BCD7;
                font-size: 18px;
              }

              .value {
                margin-left: 18px;
                white-space: nowrap;
                color: #03BCD7;
                font-size: 16px;
              }
            }
          }

          .line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 70px;
            border-top: 1px solid #03BCD7;
            box-sizing: border-box;

            .line-title {
              padding: 3px 5px;
              background-color: #03BCD7;
              color: #fff;
              font-size: 18px;
            }

            .line-item {
              display: flex;
              align-items: center;
              height: 70px;

              .text {
                margin-right: 14px;
                white-space: nowrap;
                color: #03BCD7;
                font-size: 16px;
              }

              .el-input {
                width: 97px;
              }
            }
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
