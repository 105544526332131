<template>
  <div class="write">
    <div class="title">
      <span>随访记录</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="content-title">
        <span>高血压随访履约服务记录</span>
      </div>
      <div class="content-positon">
        <el-button size="mini" class="blue">打印</el-button>
      </div>
      <div class="code">
        <span>服务编号: 00123420</span>
      </div>
      <div class="content-scroll">
        <div class="scroll-line">
          <div class="scroll-line-item scroll-line-item1">
            <span>李明明</span>
          </div>
          <div class="scroll-line-item scroll-line-item1">
            <span class="span">男</span>
            <span class="span">53岁</span>
            <span class="span">手机号：15808082258</span>
            <span>身份证号：1307301998158451589</span>
          </div>
          <div class="scroll-line-item scroll-line-item3">
            <span>服务时间：2022-08-03 12:00:00</span>
          </div>
          <div class="scroll-line-item scroll-line-item3">
            <span>服务方式：接诊服务</span>
          </div>
          <div class="scroll-line-item scroll-line-item3">
            <span>付费形式：免费</span>
          </div>
          <div class="scroll-line-item scroll-line-item3">
            <span>服务内容：高血压随访</span>
          </div>
          <div class="scroll-line-item scroll-line-item4">
            <span>服务方机构：古城营镇卫生院</span>
          </div>
        </div>

        <div class="scroll-line">
          <div class="scroll-line-item size scroll-line-item1 scroll-line-item5">
            <span class="width1">症状：无症状</span>
            <span class="width1">血压值：无症状137/78mmHg</span>
            <span class="width1">体重：67.5kg</span>
            <span class="width1">目标体重：6kg</span>
            <span class="width1">体质指数(BM)：23.9kg/m2</span>
          </div>
          <div class="scroll-line-item size scroll-line-item1 scroll-line-item5">
            <span class="width1">目标体质指教(BMI): 22.28kg/m</span>
            <span class="width1 width3">心率: 63次/分钟，日吸烟量: 0支，日饮酒量: 0两</span>
            <span class="width1 width3">运动次数: 0次/周; 运动时长: 0分/次</span>
          </div>
          <div class="scroll-line-item size scroll-line-item1 scroll-line-item5">
            <span class="width1">摄盐情况(咸淡)：轻</span>
            <span class="width1">心理调整评价：良好</span>
            <span class="width1">遵医行为评价：良好 </span>
            <span class="width1">药依从性：规律</span>
            <span class="width1"></span>
          </div>
          <div class="scroll-line-item size scroll-line-item1 scroll-line-item5">
            <span class="width1">药物不良反应:无 </span>
            <span class="width1 width2">此次随访分类(随访评价) : 控制满意;</span>
          </div>
        </div>

        <div class="scroll-box">
          <div class="scroll-box-title">
            <span>处理意见</span>
            <el-button class="button" size="mini" @click="openSelect">选择模板</el-button>
          </div>
          <el-input type="textarea" placeholder="请输入">
          </el-input>
        </div>

        <div class="sign">
          <span class="doctor">医生签字：</span>
          <span>居民签字：</span>
        </div>

        <div class="btn">

        <el-button class="submit" size="mini">确定</el-button>
        </div>
      </div>
    </div>
    <Select v-if="showSelect" @close="closeSelect"></Select>
  </div>
</template>

<script>
import Select from './select.vue'
export default {
  components: {
    Select
  },
  data () {
    return {
      radio: 0,
      showSelect: false
    }
  },
  methods: {
    openSelect () {
      this.showSelect = true
    },
    closeSelect () {
      this.showSelect = false
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8888;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 60px);
    padding: 20px 85px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 5%;
      right: 40px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      height: 60px;
      text-align: center;
      color: #fff;
      font-size: 18px;
    }

    .content-positon {
      position: absolute;
      top: 100px;
      right: 200px;
      display: flex;
      align-items: center;

      .el-button {
        border: none;
        border-radius: 0;
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        color: #fff;
      }
    }

    .code {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding-right: 500px;
      text-align: right;
      box-sizing: border-box;
      color: #fff;
      font-size: 18px;
    }

    .content-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 170px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .scroll-line {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 0 16px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;

        .scroll-line-item {
          display: flex;
          align-items: center;
          width: 70%;
          margin-bottom: 20px;
          color: #fff;
          font-size: 16px;

          .span {
            margin-right: 20px;
          }
        }

        .size {
          font-size: 18px;
        }

        /deep/.el-radio__label {
          color: #fff;
          font-size: 16px;
        }

        .scroll-line-item1 {
          width: 100%;
        }

        .scroll-line-item2 {
          display: flex;
          margin-top: 20px;
          padding-left: 140px;
          color: #fff;
          font-size: 16px;
        }

        .scroll-line-item3 {
          width: 30%;
        }

        .scroll-line-item4 {
          width: 65%;
        }

        .scroll-line-item5 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 40px;
          box-sizing: border-box;
        }

        .key {
          margin-right: 20px;
        }

        .width1 {
          width: 20%;
        }

        .width2 {
          width: 80%;
        }

        .width3 {
          width: 40%;
        }
      }

      .scroll-line1 {
        margin-top: 20px;
        margin-bottom: 50px;
      }

      .scroll-box {
        display: flex;
        flex-direction: column;
        padding: 0 16px;
        box-sizing: border-box;

        .scroll-box-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #fff;
          font-size: 18px;
          font-weight: bold;

          .button {
            background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
            color: #fff;
            border-radius: 0;
            border: none;
          }
        }

        .el-textarea {
          width: 100%;
          height: 110px;
          margin-top: 7px;

          /deep/.el-textarea__inner {
            width: 100%;
            height: 100%;
            background-color: transparent;
            resize: none;
          }
        }
      }

      .sign {
        display: flex;
        align-items: center;
        margin-top: 28px;
        padding: 0 16px;
        box-sizing: border-box;
        color: #fff;
        font-size: 18px;

        .doctor {
          width: 229px;
        }
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .submit {

        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        color: #fff;
        border-radius: 0;
        border: none;
      }
    }
  }
}
</style>
