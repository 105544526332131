<template>
  <div class="popup">
    <div class="title">
      <span class="blue"></span>
      <span>新增公告</span>
    </div>
    <div class="content">
      <div class="content-title">
        <span>通知信息</span>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">消息标题</span>
          <el-input placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">消息内容</span>
          <el-input type="textarea" placeholder="请输入内容">
          </el-input>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">发布时间</span>
          <el-date-picker type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">接收人</span>
          <div class="add" @click="openSelecte">
            <span class="el-icon-plus"></span>
            <span>选择接收人</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button size="small" class="submit">发布</el-button>
      <el-button size="small" @click="close">返回</el-button>
    </div>
    <!-- 选择 -->
    <Selecte v-if="showSelecte" @close="closeSelecte"></Selecte>
  </div>
</template>

<script>
import Selecte from './select.vue'
export default {
  components: {
    Selecte
  },
  data () {
    return {
      option: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showSelecte: false
    }
  },
  methods: {
    openSelecte () {
      this.showSelecte = true
    },
    closeSelecte () {
      this.showSelecte = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 88;
  background-color: #081C38;
  box-sizing: border-box;

  .title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    margin-bottom: 10px;
    padding-left: 10px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: bold;

    .blue {
      width: 4px;
      height: 18px;
      margin-right: 4px;
      background-color: #366FF7;
      border-radius: 2px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 120px);
    padding: 0 10px;
    box-sizing: border-box;

    .content-title {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #01EEFD;
      font-size: 18px;
    }

    .line {
      display: flex;
      margin-bottom: 17px;
      padding-left: 10px;
      box-sizing: border-box;

      .item {
        display: flex;
        align-items: center;
        margin-right: 40px;
        color: #01EEFD;
        font-size: 18px;

        .key {
          margin-right: 10px;
          white-space: nowrap;
        }

        .add {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .el-input {
          width: 209px;
        }

        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 210px;
          height: 90px;
          background-color: #fff;
          font-size: 16px;
          color: #A1A6BB;
        }

        .ueditor {
          width: calc(100% - 100);
          height: 400px;
        }

        /deep/.el-textarea__inner {
          width: 714px;
          height: 90px;
          resize: none;
        }
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .el-button {
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #fff;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
