<template>
  <div class="referral">
    <div class="head">
      <el-button size="small" @click="tabClick(0)"
        :class="{ blue: tabIndex === 0, color: tabIndex === 1 }">待处理签约</el-button>
      <el-button size="small" @click="tabClick(1)"
        :class="{ blue: tabIndex === 1, color: tabIndex === 0 }">签约记录</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="input">
      <div class="item">
        <span class="span">签约状态</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="span">签约机构</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="span">医生团队</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="span">服务包</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="span">选择日期</span>
        <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <el-button class="blue" size="small">导出</el-button>
      <el-input class="search" placeholder="请输入关键字" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="swich" v-show="tabIndex === 0">
      <el-table :data="tableData" height="400" max-height="400" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="编号">
        </el-table-column>
        <el-table-column prop="date" label="签约人姓名">
        </el-table-column>
        <el-table-column prop="date" label="身份证号">
        </el-table-column>
        <el-table-column prop="type" label="手机号">
        </el-table-column>
        <el-table-column prop="type1" label="签约状态">
          <template slot-scope="scope">
            <div class="green" v-if="scope.row.status === '1'">
              <span>待审核</span>
            </div>
            <div class="yellow" v-else-if="scope.row.status === '2'">
              <span>已驳回</span>
            </div>
            <div class="red" v-else>
              <span>待支付</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type2" label="签约医生团队">
        </el-table-column>
        <el-table-column prop="type" label="签约服务包">
        </el-table-column>
        <el-table-column prop="type" label="最后修改时间">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <div class="flex">
              <span @click="openSee(scope.row.status)">查看</span>
              <span @click="openUpdate">编辑</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <div class="swich" v-show="tabIndex === 1">
      <el-table :data="tableData" height="400" max-height="400" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="编号">
        </el-table-column>
        <el-table-column prop="date" label="签约人姓名">
        </el-table-column>
        <el-table-column prop="date" label="身份证号">
        </el-table-column>
        <el-table-column prop="type" label="手机号码">
        </el-table-column>
        <el-table-column prop="type" label="签约医生团队">
        </el-table-column>
        <el-table-column prop="type" label="签约医生">
        </el-table-column>
        <el-table-column prop="type" label="服务包">
        </el-table-column>
        <el-table-column prop="type2" label="签约状态">
        </el-table-column>
        <el-table-column prop="type" label="服务状态">
          <template slot-scope="scope">
            <div class="purple" v-if="scope.row.status == '1'">
              <span>生效中</span>
            </div>
            <div class="gray" v-else-if="scope.row.status == '2'">
              <span>已过期</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="type1" label="到期时间">
        </el-table-column>
        <el-table-column label="操作" width="100">
          <!-- slot-scope="scope" -->
          <template slot-scope="scope">
            <div class="flex">
              <span @click="openSeeInfo(scope.row.status)">查看</span>
              <span @click="openUpdate">续约</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <!-- 待处理签约查看 -->
    <See v-if="showSee" :status="status" @close="closeSee"></See>
    <!-- 待处理签约编辑 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 签约记录查看 -->
    <SeeInfo v-if="showSeeInfo" :isbasic="isbasic" @close="closeSeeInfo"></SeeInfo>
  </div>
</template>

<script>
import See from './see.vue'
import Update from './update.vue'
import SeeInfo from './serveInfo.vue'
export default {
  components: {
    See,
    Update,
    SeeInfo
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      tabIndex: 0,
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showSeeInfo: false,
      isbasic: false,
      showUpdate: false,
      showSee: false,
      status: '1'
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openSeeInfo (status) {
      if (status === '1') {
        this.isbasic = true
      } else if (status === '2') {
        this.isbasic = false
      }
      this.showSeeInfo = true
    },
    closeSeeInfo () {
      this.showSeeInfo = false
    },
    openSee (status) {
      this.status = status
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: calc(100% - .125rem);
  height: 100%;
  padding: .125rem .4625rem;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  // border: .05rem solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: .975rem;
    border-bottom: .0125rem solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: .275rem;
      border: 0;
    }

    .blue {
      margin-right: .25rem;
      background-color: #01EEFD;
    }

    .color {
      margin-right: .25rem;
      background-color: #D5D5D5;
    }

    .width {
      width: 1rem;
      font-size: .225rem;
    }

    .el-input {
      width: 3.075rem;
      height: .475rem;
      border-radius: .075rem;

      /deep/.el-input__inner {
        height: .475rem;
        line-height: .475rem;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: .0125rem solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: .225rem;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: .225rem;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
    border-bottom: .0125rem solid #01EEFD;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: .275rem;
      border: 0;
    }

    .el-select {
      width: 1.5rem;
    }

    .select {
      width: 2.575rem;
    }

    .blue {
      margin-right: .25rem;
      background-color: #01EEFD;
    }

    .search {
      width: 3.75rem;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-right: 20px;
      color: #01EEFD;
      font-size: .225rem;

      .span {
        margin-right: .125rem;
      }

      .el-input {
        width: 2.575rem;
        height: .475rem;
        line-height: .475rem;
        background-color: #fff;

        /deep/.el-input__inner {
          height: .475rem;
          line-height: .475rem;
        }
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0rem;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    margin-top: .25rem;
    background-color: #092B50;
    border: .05rem #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: .2125rem;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: .025rem solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: .0125rem solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: .0125rem solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: .0125rem solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: .1875rem;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .green {
    color: #00BB7E;
  }

  .yellow {
    color: #FF8014;
  }

  .red {
    color: #FF0000;
  }

  .purple {
    color: #073F73;
    cursor: pointer;
  }

  .gray {
    color: #D2D2D2;
    cursor: pointer;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: .5rem;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
