<template>
  <div class="select">
    <div class="popup-content">
      <div class="popup-title">
        <span>选择接收医生</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="box">
            <div class="search">
              <span>医生团队</span>
              <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-input placeholder="请选择日期" suffix-icon="el-icon-search">
              </el-input>
            </div>
            <el-table :data="tableData" height="400" max-height="400" :header-cell-style="{ background: '#024276' }">
              <el-table-column type="selection" width="50">
              </el-table-column>
              <el-table-column prop="name" label="项目编号">
              </el-table-column>
              <el-table-column prop="date" label="头像">
              </el-table-column>
              <el-table-column prop="date" label="姓名">
              </el-table-column>
              <el-table-column prop="type" label="联系电话">
              </el-table-column>
              <el-table-column prop="type" label="角色">
              </el-table-column>
              <el-table-column prop="type" label="所属团队">
              </el-table-column>
            </el-table>
          </div>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      options: [{
        value: '1',
        label: '1'
      }],
      value: ''
    }
  },
  methods: {

    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1048px;
    height: 665px;

    .popup-title {
      width: 160px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 100%;
      height: 615px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 30px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .box {
          width: 100%;
          height: calc(100% - 50px);

          .search {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: #01EEFD;
            font-size: 18px;

            .el-select {
              margin-left: 10px;
              margin-right: 40px;
            }

            .el-input {
              width: 298px;
            }
          }

          ::v-deep .el-table__body-wrapper {
            &::-webkit-scrollbar {
              width: 0rem;
            }
          }

          ::v-deep .el-table th.gutter {
            display: none;
            width: 0;
          }

          ::v-deep .el-table colgroup col[name='gutter'] {
            display: none;
            width: 0;
          }

          ::v-deep .el-table__body {
            width: 100% !important;
          }

          .el-table {
            margin-top: .25rem;
            background-color: #092B50;
            border: .05rem #073F73 solid;
          }

          // 表头文字颜色
          /deep/.el-table__header-wrapper {
            thead {
              th {
                div {
                  color: #01EEFD;
                  font-size: .2125rem;
                  font-weight: 400;
                }
              }
            }
          }

          //表格内容颜色
          /deep/.el-table__body tbody tr {
            color: #fff;
            background-color: #092B50;
          }

          /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
            background-color: #092B50;
          }

          /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #01EEFD;
            border-color: #01EEFD;
          }

          /deep/.el-table tbody tr:hover>td {
            background-color: transparent !important
          }

          // 改变边框颜色
          .el-table--border,
          .el-table--group {
            border: .025rem solid #ddd !important;
          }

          /**
  改变表格内竖线颜色
   */
          .el-table--border td,
          .el-table--border th,
          .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
            border-right: .0125rem solid #ddd !important;
          }

          /deep/.el-table td {
            border-bottom: .0125rem solid #01EEFD;
          }

          /deep/.el-table thead tr th {
            border-color: transparent;
          }

          /deep/ .el-table--border::after,
          /deep/ .el-table--group::after,
          /deep/ .el-table::before {
            background-color: #073F73;
          }

          /deep/.el-table--border {
            border: .0125rem solid #073F73 !important;
          }

          /deep/.el-table .cell {
            text-align: center;
            color: #01EEFD;
          }

          /deep/.el-checkbox__inner {
            background-color: transparent;
          }
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
