<template>
  <div class="write">
    <div class="title">
      <span>协议书</span>
    </div>
    <div class="content">
      <div class="close" @click="closePopup">
        <span class="el-icon-close"></span>
      </div>
      <div class="content-title">
        <span>河北省家庭医生签约服务协议书</span>
      </div>
      <div class="content-positon">
        <el-button class="green" @click="update">编辑协议</el-button>
        <el-button class="yellow">打印协议</el-button>
      </div>
      <div class="code">
        <span>协议编号: 00123420</span>
      </div>
      <div class="content-scroll">
        <div class="scroll-line">
          <div class="scroll-line-item size">
            <span>甲方(签约机构)</span>
            <span v-if="!isUpdate">魏县回隆镇卫生院</span>
            <el-input v-else class="input"></el-input>
          </div>
          <div class="scroll-line-item">
            <span>全科医生姓名:</span>
            <span v-if="!isUpdate"></span>
            <el-input v-else class="input"></el-input>
          </div>

          <div class="scroll-line-item">
            <span>团队负责人姓名:</span>
            <span v-if="!isUpdate">张文义</span>
            <el-input v-else class="input"></el-input>
          </div>
          <div class="scroll-line-item">
            <span>签约团队联系电话:</span>
            <span v-if="!isUpdate">13931061698</span>
            <el-input v-else class="input"></el-input>
          </div>

          <div class="scroll-line-item">
            <span>责任护士姓名:</span>
            <span v-if="!isUpdate"></span>
            <el-input v-else class="input"></el-input>
          </div>
          <div class="scroll-line-item">
            <span>联系电话:</span>
            <span v-if="!isUpdate"></span>
            <el-input v-else class="input"></el-input>
          </div>

          <div class="scroll-line-item">
            <span>责任村医(服务站):</span>
            <span v-if="!isUpdate"></span>
            <el-input v-else class="input"></el-input>
          </div>
          <div class="scroll-line-item">
            <span>联系电话:</span>
            <span v-if="!isUpdate"></span>
            <el-input v-else class="input"></el-input>
          </div>

          <div class="scroll-line-item">
            <span></span>
          </div>
          <div class="scroll-line-item">
            <el-radio-group v-model="radio">
              <el-radio :label="3">户籍</el-radio>
              <el-radio :label="6">费户籍</el-radio>
            </el-radio-group>
          </div>

          <div class="scroll-line-item size scroll-line-item1">
            <span>乙方(签约对象) 姓名: 步亦可</span>
          </div>
          <div class="scroll-line-item scroll-line-item1">
            <span>家庭地址:魏县回隆镇东街村164号</span>
          </div>
          <div class="scroll-line-item scroll-line-item1">
            <span>身份证号:130434201202146928</span>
          </div>
          <div class="scroll-line-item2">
            <span class="key">乙方类型:</span>
            <el-checkbox-group v-model="checkList">
              <el-checkbox :label="index + 1 + ''" v-for="(item, index) in checks" :key="index">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>

        <div class="agreement">
          <div class="agreement-title">
            <span>协议内容</span>
          </div>
          <div class="agreement-line h1">
            <span>一、协议服务内容</span>
          </div>
          <div class="agreement-line">
            <span>甲乙双方本着平等、尊重和自愿的原则，签订此协议，甲方作为服务的提供者，向乙方及其家庭成员提供以下服务项目</span>
          </div>
          <div class="agreement-line">
            <span>(一)基础包:1.基本医疗服务:2.国家基本公共卫生服务项目:3.健康管理服务。</span>
          </div>
          <div class="agreement-line">
            <span>(二)个性化服务包:在基础包的服务内容上，签约机构可根据服务能力向乙方提供个性化健康管理服务。</span>
          </div>
          <div class="agreement-line">
            <span>(三)签约服务优惠政策.</span>
          </div>
          <div class="agreement-line">
            <span>(四)签约服务机构特色服务包</span>
          </div>
          <div class="agreement-line h1">
            <span>二、权利和义务</span>
          </div>
          <div class="agreement-line">
            <span>(一)签约机构主要权利和义务:及时获得签约居民真实信息，提供电话接听服务，指导和协助签约居民开展自我健康管理;提供“面对面”个性化健康指导，开展健康教育讲座，不能解决的疑难疾病提供双向转诊服务</span>
          </div>
          <div class="agreement-line">
            <span>(二)签约居民主要权利和义务:享受签约包约定服务，享受政策规定的优惠措施，积极配合签约医生或团队开展履约工作，并保证沟通顺畅;按照签约医生的意见接受首诊，如实向签约医生反映自身的健康问题并接受健康管理建议。</span>
          </div>
          <div class="agreement-line h1">
            <span>三、注意事项</span>
          </div>
          <div class="agreement-line">
            <span>(一)本协议未尽事宜由甲,双方协商解决</span>
          </div>
          <div class="agreement-line margin-bottom">
            <span>二)本协议书一式三份，城市社区:社区卫生服务机构、家庭医生团队、签约居民各执一份:农村地区:乡镇卫生院、村卫生室，签约居民各执一份，自双方签字之日起生效，有效期为一年，期满后如票解约，7方需告知用方，双方然字确认，不提出解约者，视为自动续约</span>
          </div>
        </div>

        <div class="scroll-line scroll-line1">
          <div class="scroll-line-item3">
            <span>乙方选择的签约服务健康包为:基础包(健康体检)</span>
          </div>
          <div class="scroll-line-item3 scroll-line-item4">
            <span></span>
          </div>
          <div class="scroll-line-item3">
            <span>甲方（盖章)</span>
          </div>
          <div class="scroll-line-item3">
            <span>乙方（签字)</span>
          </div>
          <div class="scroll-line-item3">
            <span>2023年1月3日</span>
          </div>
          <div class="scroll-line-item3">
            <span>2023年1月3日</span>
          </div>
          <div class="scroll-line-item3">
            <span>续约时间:</span>
            <span v-if="!isUpdate"></span>
            <el-input v-else class="input"></el-input>
          </div>
          <div class="scroll-line-item3">
            <span></span>
          </div>
          <div class="scroll-line-item3">
            <span>解约时间:</span>
            <span v-if="!isUpdate"></span>
            <el-input v-else class="input"></el-input>
          </div>
          <div class="scroll-line-item3">
            <span>解约原因:</span>
            <span v-if="!isUpdate"></span>
            <el-input v-else class="input"></el-input>
          </div>
          <div class="scroll-line-item3">
            <span>双方签约:</span>
            <span v-if="!isUpdate"></span>
            <el-input v-else class="input"></el-input>
          </div>
          <div class="scroll-line-item3">
            <span></span>
          </div>
        </div>

        <div class="btns" v-if="isUpdate">
          <el-button @click="closePopup">取消</el-button>
          <el-button class="blue" @click="closePopup">确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: 0,
      checkList: ['1', '2'],
      checks: Object.freeze(['健康人 ', '高血压', '糖尿病', '65岁以上老年人', '孕产妇', '0-6岁儿童', '肺结核', '严重精神障碍患者', '视力残疾', '听力残疾', '肢体残疾', '精神残疾', '言语残疾', '低保、五保户', '计生特殊困难家庭', '脱贫户', '边缘易致贫户', '突发严重困难户', '智力残疾', '其他人群']),
      isUpdate: false
    }
  },
  methods: {
    update () {
      this.isUpdate = true
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8888;
  width: 100%;
  height: 100%;
  padding: 0 40px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: calc(100% - 70px);
    margin-top: 10px;
    padding: 30px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 5%;
      right: 40px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      height: 80px;
      line-height: 80px;
      text-align: center;
      color: #fff;
      font-size: 18px;
    }

    .content-positon {
      position: absolute;
      top: 60px;
      right: 200px;
      display: flex;
      align-items: center;

      .el-button {
        border: none;
        border-radius: 0;
        color: #fff;
      }

      .green {
        background-color: #0F9E09;
      }

      .yellow {
        background-color: #D05D04;
      }
    }

    .code {
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding-right: 124px;
      text-align: right;
      box-sizing: border-box;
      color: #fff;
      font-size: 18px;
    }

    .content-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 200px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .scroll-line {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 10px;
        box-sizing: border-box;

        .scroll-line-item {
          display: flex;
          align-items: center;
          width: 70%;
          margin-bottom: 12px;
          color: #fff;
          font-size: 16px;
        }

        .scroll-line-item:nth-child(2n) {
          width: 30%;
        }

        .size {
          font-size: 18px;
        }

        /deep/.el-radio__label {
          color: #fff;
          font-size: 16px;
        }

        .scroll-line-item1 {
          width: 100%;
        }

        .scroll-line-item2 {
          display: flex;
          margin-top: 20px;
          padding-left: 140px;
          color: #fff;
          font-size: 16px;
        }

        .key {
          margin-right: 20px;
        }

        .el-checkbox-group {
          width: calc(100% - 100px);
        }

        /deep/.el-checkbox__label {
          color: #fff;
          font-size: 16px;
        }

        /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #01EEFD;
          border-color: #01EEFD;
        }

        .scroll-line-item3 {
          width: 50%;
          margin-bottom: 12px;
          color: #fff;
          font-size: 16px;
        }

        .scroll-line-item4 {
          margin-top: 30px;
          margin-bottom: 28px;
        }

        .input {
          width: 170px;
          height: 25px;
          line-height: 25px;
          border-bottom: 1px solid #fff;
          box-sizing: border-box;
          color: #fff;

          /deep/.el-input__inner {
            height: 25px;
            line-height: 25px;
            background-color: transparent;
            border: none;
          }
        }
      }

      .scroll-line1 {
        margin-top: 20px;
        margin-bottom: 50px;
      }

      .agreement {
        display: flex;
        flex-direction: column;
        width: 100%;
        // height: 484px;
        margin-top: 36px;
        padding: 0 25px;
        background-color: #102A4F;
        box-sizing: border-box;

        .agreement-title {
          color: #fff;
          font-size: 18px;
        }

        .agreement-line {
          margin-top: 12px;
          color: #fff;
          font-size: 16px;
        }

        .h1 {
          margin-top: 15px;
        }

        .margin-bottom {
          margin-bottom: 10px;
        }
      }

      .btns {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 120px;

        .el-button {
          height: 26px;
          line-height: 26px;
          margin: 0;
          padding-top: 0;
          padding-bottom: 0;
          border: none;
          border-radius: 0;
          background-color: #616B73;
          color: #fff;
        }

        .blue {
          margin-left: 70px;
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }

    }
  }
}
</style>
