<template>
  <div class="page">
    <div class="page-left">
      <div class="menu">
        <div class="scroll">
          <el-menu :collapse-transition="false" :default-active="activePath" router background-color="#0E487D"
            text-color="#fff" active-text-color="#04B4D0" unique-opened>
            <el-menu-item index="/intelligentmonitoring/overviewArchives" @click="saveNavState('/intelligentmonitoring/overviewArchives')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">档案总览</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/intelligentmonitoring/taskkanban" @click="saveNavState('/intelligentmonitoring/taskkanban')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">任务看板</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/intelligentmonitoring/managearchives" @click="saveNavState('/intelligentmonitoring/managearchives')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span  class="font">管理档案</span>
              </template>
            </el-menu-item>
            <el-submenu index="gwrqsc">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">高危人群筛查</span>
              </template>
              <el-menu-item index="/intelligentmonitoring/highriskpopulationscreening/filerun" @click="saveNavState('/intelligentmonitoring/highriskpopulationscreening/filerun')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">档案管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/highriskpopulationscreening/outhospitalscreen" @click="saveNavState('/intelligentmonitoring/highriskpopulationscreening/outhospitalscreen')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">院外筛查</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/highriskpopulationscreening/medicalscreen" @click="saveNavState('/intelligentmonitoring/highriskpopulationscreening/medicalscreen')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">就诊筛查</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/highriskpopulationscreening/testscreen" @click="saveNavState('/intelligentmonitoring/highriskpopulationscreening/testscreen')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">体检筛查</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/highriskpopulationscreening/residentapplication" @click="saveNavState('/intelligentmonitoring/highriskpopulationscreening/residentapplication')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">居民申请</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/intelligentmonitoring/crowdrun" @click="saveNavState('/intelligentmonitoring/crowdrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span  class="font">人群管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/intelligentmonitoring/servicerecord" @click="saveNavState('/intelligentmonitoring/servicerecord')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span  class="font">服务记录</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/intelligentmonitoring/monitordata" @click="saveNavState('/intelligentmonitoring/monitordata')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span  class="font">智能监测</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/intelligentmonitoring/servicemonitoring" @click="saveNavState('/intelligentmonitoring/servicemonitoring')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span  class="font">服务监测</span>
              </template>
            </el-menu-item>
            <el-submenu index="sbgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">设备管理</span>
              </template>
              <el-menu-item index="/intelligentmonitoring/positiondevice" @click="saveNavState('/intelligentmonitoring/positiondevice')">
                <template slot="title">
                  <span class="mrgin-left">定位设备</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/404">
                <template slot="title">
                  <span class="mrgin-left submenu-text">睡眠床垫设备</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/404">
                <template slot="title">
                  <span class="mrgin-left">安防设备</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/bloodpressureequipment" @click="saveNavState('/intelligentmonitoring/bloodpressureequipment')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">血压计设备</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/bloodglucoseequipment" @click="saveNavState('/intelligentmonitoring/bloodglucoseequipment')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">血糖计设备</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/fluoriteuser" @click="saveNavState('/intelligentmonitoring/fluoriteuser')">
                <template slot="title">
                  <span class="mrgin-left">萤石用户</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/404">
                <template slot="title">
                  <span class="mrgin-left">萤石设备</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/customalarms" @click="saveNavState('/intelligentmonitoring/customalarms')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">自定义报警</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="sfgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">随访管理</span>
              </template>
              <el-menu-item index="/intelligentmonitoring/hypertensionvisit" @click="saveNavState('/intelligentmonitoring/hypertensionvisit')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">高血压随访</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/diabetesvisit" @click="saveNavState('/intelligentmonitoring/diabetesvisit')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">糖尿病随访</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/psychosisvisit" @click="saveNavState('/intelligentmonitoring/psychosisvisit')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">精神病随访</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/phthisisvisit" @click="saveNavState('/intelligentmonitoring/phthisisvisit')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">肺结核随访</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/intelligentmonitoring/aichronicdiseaseassistantsystem" @click="saveNavState('/intelligentmonitoring/aichronicdiseaseassistantsystem')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font submenu-text">AI慢病助手系统</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/intelligentmonitoring/monitor" @click="saveNavState('/intelligentmonitoring/monitor')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font submenu-text">远程指标监测</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/intelligentmonitoring/healthwarning" @click="saveNavState('/intelligentmonitoring/healthwarning')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font submenu-text">危险预警与提醒</span>
              </template>
            </el-menu-item>
            <el-submenu index="jkgy">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font submenu-text">个性化管理方案制定</span>
              </template>
              <el-menu-item index="/intelligentmonitoring/emr" @click="saveNavState('/intelligentmonitoring/emr')">
                <template slot="title">
                  <span class="mrgin-left">电子病历</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/doctoradvice" @click="saveNavState('/intelligentmonitoring/doctoradvice')">
                <template slot="title">
                  <span class="mrgin-left">医嘱</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/medication" @click="saveNavState('/intelligentmonitoring/medication')">
                <template slot="title">
                  <span class="mrgin-left">用药</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/exercisediet" @click="saveNavState('/intelligentmonitoring/exercisediet')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">运动与饮食建议</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/intelligentmonitoring/patientmanage" @click="saveNavState('/intelligentmonitoring/patientmanage')">
                <template slot="title">
                  <span class="mrgin-left">患者管理</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/intelligentmonitoring/reviewrun" @click="saveNavState('/intelligentmonitoring/reviewrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="submenu-text">疾病风险评估</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/intelligentmonitoring/ncd" @click="saveNavState('/intelligentmonitoring/ncd')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">院内管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/intelligentmonitoring/healthedu" @click="saveNavState('/intelligentmonitoring/healthedu')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">健康教育</span>
              </template>
            </el-menu-item>
            <!-- <el-menu-item index="/intelligentmonitoring/referral" @click="saveNavState('/intelligentmonitoring/referral')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">双向转诊</span>
              </template>
            </el-menu-item> -->
            <el-submenu index="sxzz">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">双向转诊</span>
              </template>
              <el-menu-item index="/intelligentmonitoring/twoWayreferral/upstreamecord" @click="saveNavState('/intelligentmonitoring/twoWayreferral/upstreamecord')">
                <template slot="title">
                  <span class="mrgin-left submenu-text">上转记录</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/intelligentmonitoring/onlineconsultationservice" @click="saveNavState('/intelligentmonitoring/onlineconsultationservice')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="submenu-text">线上问诊服务</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/intelligentmonitoring/automaterun" @click="saveNavState('/intelligentmonitoring/automaterun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="submenu-text">自动化管理</span>
              </template>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="page-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activePath: '/intelligentmonitoring'
    }
  },
  mounted () {
    this.activePath = window.sessionStorage.getItem('intelligentmonitoringPath') || '/intelligentmonitoring'
  },
  methods: {
    saveNavState (path) {
      // console.log(path)
      window.sessionStorage.setItem('intelligentmonitoringPath', path)
      window.sessionStorage.setItem('headTabActivePath', path)
      this.activePath = path
    },
    pre () { },
    next () { },
    refresh () { }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #071224;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  .page-left {
    overflow: hidden;
    width: 200px;
    height: 100%;
    background-color: #0E487D;

    .menu {
      position: relative;
      width: 100%;
      height: 100%;

      .uncollapse {
        position: absolute;
        bottom: 0;
        writing-mode: tb-rl;
        text-align: center;
        line-height: 40px;
        margin-left: 50%;
        margin-top: 20px;
        transform: translateX(-50%);
        cursor: pointer;
        font-size: 20px;
        color: #fff;
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 10px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .el-menu {
          border: none;
        }

        /deep/.el-menu-item {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 0 !important;
          font-size: 15px;
        }

        .font {
          font-size: 15px;
        }

        /deep/.el-submenu__title {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 1px !important;
          padding-right: 2px !important;
        }
        /deep/.el-icon-arrow-down:before {
          content: '';
        }
        .menu-text {
          width: calc(100% - 40px);
          line-height: normal;
          white-space: pre-wrap;
        }

        .submenu-text {
          // width: 80px;
          line-height: normal;
          white-space: pre-wrap;
        }

        .mrgin-left {
          margin-left: 30px;
        }
      }
    }
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 207px);
    height: 100%;
    margin-left: 7px;
    padding: 10px 0;
    background-color: #071224;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>
