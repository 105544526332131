<template>
  <div class="page">
    <div class="page-left">
      <div class="scroll">
        <el-menu :collapse-transition="false" :default-active="activePath" router background-color="#0E487D"
          text-color="#fff" active-text-color="#04B4D0" unique-opened>
          <el-menu-item index="/smartphysicalexamination/index" @click="saveNavState('/smartphysicalexamination/index')">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>首页</span>
            </template>
          </el-menu-item>
          <el-submenu index="yygl">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span class="menu-text font">预约管理</span>
            </template>
            <el-menu-item index="/smartphysicalexamination/bookingrun/appointmentpermissionrun" @click="saveNavState('/smartphysicalexamination/bookingrun/appointmentpermissionrun')">
              <template slot="title">
                <span class="mrgin-left">预约限额管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/bookingrun/appointmentrecordrun" @click="saveNavState('/smartphysicalexamination/bookingrun/appointmentrecordrun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">预约记录管理</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="ctgl">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span class="menu-text font">查体管理</span>
            </template>
            <el-menu-item index="/smartphysicalexamination/physicalexaminationrun/physicalexaminationinforecordrun" @click="saveNavState('/smartphysicalexamination/physicalexaminationrun/physicalexaminationinforecordrun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">查体信息记录管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/physicalexaminationrun/physicalexaminationclassification" @click="saveNavState('/smartphysicalexamination/physicalexaminationrun/physicalexaminationclassification')">
              <template slot="title">
                <span class="mrgin-left submenu-text">查体分类进度查询</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/physicalexaminationrun/reportrun" @click="saveNavState('/smartphysicalexamination/physicalexaminationrun/reportrun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">报告管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/physicalexaminationrun/offlinedatarun" @click="saveNavState('/smartphysicalexamination/physicalexaminationrun/offlinedatarun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">离线数据管理</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="dagl">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span class="menu-text font">档案管理</span>
            </template>
            <el-menu-item index="/smartphysicalexamination/filerun/healthfile" @click="saveNavState('/smartphysicalexamination/filerun/healthfile')">
              <template slot="title">
                <span class="mrgin-left submenu-text">健康档案</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="szgl">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span class="menu-text font">数据管理</span>
            </template>
            <el-menu-item index="/smartphysicalexamination/datarun/uploadrun" @click="saveNavState('/smartphysicalexamination/datarun/uploadrun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">上传管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/datarun/auditsrun" @click="saveNavState('/smartphysicalexamination/datarun/auditsrun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">审核管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404">
              <template slot="title">
                <span class="mrgin-left submenu-text">报表管理</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="xtfx">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span class="menu-text font">统计分析</span>
            </template>
            <el-menu-item index="/smartphysicalexamination/statisticanalysis/commondiseaseconditions" @click="saveNavState('/smartphysicalexamination/statisticanalysis/commondiseaseconditions')">
              <template slot="title">
                <span class="mrgin-left submenu-text">常见疾病状况</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/statisticanalysis/medicalreport" @click="saveNavState('/smartphysicalexamination/statisticanalysis/medicalreport')">
              <template slot="title">
                <span class="mrgin-left submenu-text">学生综合体检报告</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/statisticanalysis/healthmonitoringresults" @click="saveNavState('/smartphysicalexamination/statisticanalysis/healthmonitoringresults')">
              <template slot="title">
                <span class="mrgin-left submenu-text">学校学生健康监测结果汇总表</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404">
              <template slot="title">
                <span class="mrgin-left submenu-text">学生常见病患病矫治情况</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/statisticanalysis/healthsummary" @click="saveNavState('/smartphysicalexamination/statisticanalysis/healthsummary')">
              <template slot="title">
                <span class="mrgin-left submenu-text">学校学生健康检查情况汇总</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/statisticanalysis/visionstatistics" @click="saveNavState('/smartphysicalexamination/statisticanalysis/visionstatistics')">
              <template slot="title">
                <span class="mrgin-left submenu-text">学校学生视力统计表</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404">
              <template slot="title">
                <span class="mrgin-left submenu-text">学校体检汇总报告</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/404">
              <template slot="title">
                <span class="mrgin-left submenu-text">学生健康体检质量控制复查情况登记表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/smartphysicalexamination/physicalexaminationplan" @click="saveNavState('/smartphysicalexamination/physicalexaminationplan')">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>体检计划</span>
            </template>
          </el-menu-item>
          <el-submenu index="xtpz">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span class="menu-text font">系统配置</span>
            </template>
            <el-menu-item index="/smartphysicalexamination/systemconfig/medicalreport" @click="saveNavState('/smartphysicalexamination/systemconfig/medicalreport')">
              <template slot="title">
                <span class="mrgin-left submenu-text">导检单管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/systemconfig/testprojectrun" @click="saveNavState('/smartphysicalexamination/systemconfig/testprojectrun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">体检项目管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/systemconfig/packagerun" @click="saveNavState('/smartphysicalexamination/systemconfig/packagerun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">套餐管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/systemconfig/abnormalmodulerun" @click="saveNavState('/smartphysicalexamination/systemconfig/abnormalmodulerun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">异常模版管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/systemconfig/userrun" @click="saveNavState('/smartphysicalexamination/systemconfig/userrun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">用户管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/systemconfig/schoollist" @click="saveNavState('/smartphysicalexamination/systemconfig/schoollist')">
              <template slot="title">
                <span class="mrgin-left submenu-text">学校列表</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/systemconfig/menuroleset" @click="saveNavState('/smartphysicalexamination/systemconfig/menuroleset')">
              <template slot="title">
                <span class="mrgin-left submenu-text">菜单角色设置</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/systemconfig/terminalrun" @click="saveNavState('/smartphysicalexamination/systemconfig/terminalrun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">终端管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smartphysicalexamination/systemconfig/devicemodelrun" @click="saveNavState('/smartphysicalexamination/systemconfig/devicemodelrun')">
              <template slot="title">
                <span class="mrgin-left submenu-text">设备型号管理</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="yxgzz">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span class="menu-text font">影像工作站</span>
            </template>
            <el-menu-item index="/smartphysicalexamination/imageworkstation/busworkstation" @click="saveNavState('/smartphysicalexamination/imageworkstation/busworkstation')">
              <template slot="title">
                <span class="mrgin-left submenu-text">b超工作站</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
    <div class="page-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activePath: '/smartphysicalexamination/index'
    }
  },
  mounted () {
    const path = window.sessionStorage.getItem('smartphysicalexaminationPath') || '/smartphysicalexamination/index'
    this.$router.push(path)
    if (path) {
      this.activePath = path
    }
  },
  methods: {
    saveNavState (path) {
      window.sessionStorage.setItem('smartphysicalexaminationPath', path)
      window.sessionStorage.setItem('headTabActivePath', path)

      this.activePath = path
    },
    pre () { },
    next () { },
    refresh () { }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  .page-left {
    width: 276px;
    height: 100%;
    background-color: #006EFF;

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 10px);

      &::-webkit-scrollbar {
        width: 0;
      }

      i {
        color: #fff;
      }

      .el-menu {
        border: none;
        background-color: #006EFF !important;
        color: #fff !important;
      }

      /deep/.el-menu-item {
        display: flex;
        align-items: center;
        height: 50px;
        padding: 0 !important;
        background-color: #006EFF !important;
        font-size: 16px;
        color: #fff !important;
      }

      /deep/.el-menu-item.is-active {
        color: #25A0FF !important;
      }

      .font {
        color: #fff;
        font-size: 16px;
      }

      /deep/.el-submenu__title {
        display: flex;
        align-items: center;
        height: 45px;
        padding: 1px !important;
        padding-right: 2px !important;
        background-color: #006EFF !important;
        color: #17212B !important;
      }

      /deep/.el-icon-arrow-down:before {
        content: '';
      }

      .menu-text {
        // width: calc(100% - 40px);
        line-height: normal;
        white-space: pre-wrap;
      }

      .submenu-text {
        // width: calc(100% - 30px);
        line-height: normal;
        white-space: pre-wrap;
      }

      .mrgin-left {
        display: block;
        // width: 80px;
        margin-left: 30px;
        line-height: normal;
        white-space: pre-wrap;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6; /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 278px);
    height: 100%;
    background-color: #fff;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>
