<template>
  <div class="tab-box">
    <div class="input">
      <div class="item">
        <span class="span">服务包状态</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-input class="search" placeholder="请输入关键字" suffix-icon="el-icon-search">
      </el-input>
      <el-button class="blue" size="small" @click="openAdd">新增服务包</el-button>
    </div>
    <div class="scroll">
      <div class="sroll-title">
        <span>初级包</span>
      </div>
      <el-row>
        <el-col :span="4">
          <div class="item" @click="openSee('0')">
            <div class="ing ing-blue">
              <span>使用中</span>
            </div>
            <img class="user-img" src="../../../../../assets/img/all-img.png">
            <div class="news">
              <div class="news-line">
                <span class="name">基础服务包</span>
              </div>
              <div class="news-line">
                <span>签约对象 所有人</span>
              </div>
              <div class="news-line">
                <el-tag size="small" class="red">随访服务</el-tag>
                <el-tag size="small" class="red green">常规护理</el-tag>
              </div>
            </div>
            <div class="position">
              <span>服务包详情</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="sroll-title">
        <span>中级包</span>
      </div>
      <el-row>
        <el-col :span="4">
          <div class="item" @click="openSee('1')">
            <div class="ing ing-green">
              <span>待审核</span>
            </div>
            <img class="user-img" src="../../../../../assets/img/old-man.png">
            <div class="news">
              <div class="news-line">
                <span class="name">老年人服务包</span>
              </div>
              <div class="news-line">
                <span>签约对象 55岁以上老人</span>
              </div>
              <div class="news-line">
                <el-tag size="small" class="red">随访服务</el-tag>
                <el-tag size="small" class="red green">常规护理</el-tag>
              </div>
            </div>
            <div class="position">
              <span>服务包详情</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="sroll-title">
        <span>高级包</span>
      </div>
      <el-row>
        <el-col :span="4">
          <div class="item" @click="openSee('2')">
            <div class="ing ing-yellow">
              <span>已停用</span>
            </div>
            <img class="user-img" src="../../../../../assets/img/children.png">
            <div class="news">
              <div class="news-line">
                <span class="name">儿童服务包</span>
              </div>
              <div class="news-line">
                <span>签约对象 0-6岁儿童</span>
              </div>
              <div class="news-line">
                <el-tag size="small" class="red">随访服务</el-tag>
                <el-tag size="small" class="red green">常规护理</el-tag>
              </div>
            </div>
            <div class="position">
              <span>服务包详情</span>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="sroll-title">
        <span>特需定制包</span>
      </div>
      <el-row>
        <el-col :span="4">
          <div class="item" @click="openSee('3')">
            <div class="ing ing-red">
              <span>已驳回</span>
            </div>
            <img class="user-img" src="../../../../../assets/img/custom-made.png">
            <div class="news">
              <div class="news-line">
                <span class="name">老年人服务包</span>
              </div>
              <div class="news-line">
                <span>签约对象 55岁以上老人</span>
              </div>
              <div class="news-line">
                <el-tag size="small" class="red">随访服务</el-tag>
                <el-tag size="small" class="red green">常规护理</el-tag>
              </div>
            </div>
            <div class="position">
              <span>服务包详情</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 新增服务包 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <!-- 查看 -->
    <See v-if="showSee" :isStatus="isStatus" @close="closeSee"></See>
  </div>
</template>

<script>
import Add from './add.vue'
import See from './see.vue'
export default {
  components: {
    Add,
    See
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showAdd: false,
      showSee: false,
      isStatus: '1'
    }
  },
  methods: {
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSee (str) {
      this.isStatus = str
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openNewRun () {
      this.showNewsRun = true
    },
    closeNewRun () {
      this.showNewsRun = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.tab-box {
  width: 100%;
  height: calc(100% - 100px);

  .swich {
    display: flex;
    flex-direction: column;
  }

  .input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
    border-bottom: 1px solid #01EEFD;

    .el-button {
      margin-left: 40px;
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .el-select {
      width: 220px;
    }

    .select {
      width: 206px;
    }

    .blue {
      margin-right: 20px;
      background-color: #01EEFD;
    }

    .search {
      width: 300px;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 206px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .sroll-title {
      height: 56px;
      line-height: 56px;
      color: #01EEFD;
      font-size: 18px;
    }

    .item {
      overflow: hidden;
      position: relative;
      display: flex;
      width: 240px;
      height: 133px;
      padding: 6px;
      background: linear-gradient(180deg, #0162AE 0%, #015394 100%);
      box-shadow: 0px 3px 4px 1px rgba(157, 157, 157, 0.1608);
      border-radius: 6px;
      box-sizing: border-box;
      cursor: pointer;

      .ing {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        position: absolute;
        top: -18px;
        right: -38px;
        z-index: 88;
        width: 110px;
        height: 47px;
        background-color: #FF0000;
        color: #fff;
        font-size: 14px;
        transform: rotate(30deg);
      }

      .ing-green {
        background-color: #0BBB0B;
      }

      .ing-blue {
        background-color: #3670F6;
      }

      .ing-red {
        background-color: #FF0000;
      }

      .ing-yellow {
        background-color: #FFC300;
      }

      .add {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: #FFFFFF;
        font-size: 14px;

        i {
          margin-right: 4px;
          font-size: 20px;
          font-weight: bolder;
        }
      }

      .user-img {
        width: 52px;
        height: 52px;
        border-radius: 6px;
      }

      .news {
        height: 100%;
        margin-left: 6px;

        .news-line {
          margin-bottom: 6px;
          color: #fff;
          font-size: 12px;

          .name {
            margin-right: 4px;
            font-size: 18px;
          }

          .red {
            background-color: #FFC4C4;
            border-radius: 12px;
            border: none;
            color: #FF0000;
            font-size: 14px;
          }

          .green {
            margin-left: 10px;
            background-color: #B6FFB6;
            color: #0BBB0B;
          }
        }
      }

      .position {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 3px 6px;
        background-color: #3670F6;
        border-radius: 0px 0px 6px 0;
        color: #fff;
        font-size: 14px;
      }

      .buttons {
        display: flex;
        align-items: center;
        position: absolute;
        left: 22px;
        bottom: 0;

        .yes,
        .no {
          border: none;
          color: #fff;
        }

        .yes {
          background-color: #0BBB0B;
        }

        .no {
          background-color: #FF0000;
        }
      }
    }
  }
}
</style>
