import { render, staticRenderFns } from "./smartPhysicalExamination.vue?vue&type=template&id=7f65c0ef&scoped=true&"
import script from "./smartPhysicalExamination.vue?vue&type=script&lang=js&"
export * from "./smartPhysicalExamination.vue?vue&type=script&lang=js&"
import style0 from "./smartPhysicalExamination.vue?vue&type=style&index=0&id=7f65c0ef&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f65c0ef",
  null
  
)

export default component.exports