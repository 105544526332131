<template>
  <div class="tab-box">
    <div class="input">
      <div class="item-tab">
        <div class="sort" :class="{ active: tabIndex === 0 }" @click="tabClick(0)">
          <span>我管理的团队</span>
        </div>
        <div class="sort" :class="{ active: tabIndex === 1 }" @click="tabClick(1)">
          <span>我加入的团队</span>
        </div>
      </div>
      <el-button class="blue" size="small" @click="openAdd">新增医生团队</el-button>
      <el-button class="blue" size="small" @click="openApplyFor">申请加入团队</el-button>
      <el-button class="blue" size="small">导出</el-button>
      <div class="item">
        <span class="span">所属机构</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-input class="search" placeholder="请输入关键字" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="swich" v-show="tabIndex === 0">
      <el-table :data="tableData" height="400" max-height="400" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="编号">
        </el-table-column>
        <el-table-column prop="date" label="头像">
        </el-table-column>
        <el-table-column prop="date" label="团名称">
        </el-table-column>
        <el-table-column prop="type" label="团队长">
        </el-table-column>
        <el-table-column prop="type1" label="团队标签">
          <!-- <template slot-scope="scope">
            <div class="green" v-if="scope.row.status == '1'">
              <span>待审核</span>
            </div>
            <div class="yellow" v-else-if="scope.row.status == '2'">
              <span>已驳回</span>
            </div>
            <div class="yellow" v-else-if="scope.row.status == '3'">
              <span>待服务</span>
            </div>
          </template> -->
        </el-table-column>
        <el-table-column prop="type2" label="所属机构">
        </el-table-column>
        <el-table-column prop="type2" label="签约人数">
        </el-table-column>
        <el-table-column prop="type2" label="评分">
        </el-table-column>
        <el-table-column prop="type" label="状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.type" active-color="#04B4D0" inactive-color="#D5D5D5" active-text="启动"
              inactive-text="禁用">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <!-- slot-scope="scope" -->
          <template>
            <div class="flex">
              <span @click="openSee1">查看</span>
              <span @click="openDelete">解散团队</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <div class="swich" v-show="tabIndex === 1">
      <el-table :data="tableData" height="400" max-height="400" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="编号">
        </el-table-column>
        <el-table-column prop="date" label="头像">
        </el-table-column>
        <el-table-column prop="date" label="团名称">
        </el-table-column>
        <el-table-column prop="type" label="团队长">
        </el-table-column>
        <el-table-column prop="type1" label="团队标签">
          <!-- <template slot-scope="scope">
            <div class="green" v-if="scope.row.status == '1'">
              <span>待审核</span>
            </div>
            <div class="yellow" v-else-if="scope.row.status == '2'">
              <span>已驳回</span>
            </div>
            <div class="yellow" v-else-if="scope.row.status == '3'">
              <span>待服务</span>
            </div>
          </template> -->
        </el-table-column>
        <el-table-column prop="type2" label="所属机构">
        </el-table-column>
        <el-table-column prop="type2" label="签约人数">
        </el-table-column>
        <el-table-column prop="type2" label="评分">
        </el-table-column>
        <el-table-column prop="type" label="状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.type" active-color="#04B4D0" inactive-color="#D5D5D5" active-text="启动"
              inactive-text="禁用">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <!-- slot-scope="scope" -->
          <template>
            <div class="flex">
              <span @click="openSee2">查看</span>
              <span @click="openOut">退出团队</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <!-- 新增医生团队 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <!-- 申请加入团队 -->
    <ApplyFor v-if="showApplyFor" @close="closeApplyFor"></ApplyFor>
    <!-- 我管理的团队 查看 -->
    <See1 v-if="showSee1" @close="closeSee1"></See1>
    <!-- 我加入的团队 查看 -->
    <See2 v-if="showSee2" @close="closeSee2"></See2>
    <!-- 解散团队 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <!-- 退出确定 -->
    <Out v-if="showOut" @close="closeOut"></Out>
  </div>
</template>

<script>
import Add from './add.vue'
import ApplyFor from './applyFor.vue'
import See1 from './see1.vue'
import See2 from './see2.vue'
import Delete from './delete.vue'
import Out from './out.vue'
export default {
  components: {
    Add,
    ApplyFor,
    See1,
    See2,
    Delete,
    Out
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      tabIndex: 0,
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showAdd: false,
      showApplyFor: false,
      showSee1: false,
      showSee2: false,
      showDelete: false,
      showOut: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openApplyFor () {
      this.showApplyFor = true
    },
    closeApplyFor () {
      this.showApplyFor = false
    },
    openSee1 () {
      this.showSee1 = true
    },
    closeSee1 () {
      this.showSee1 = false
    },
    openSee2 () {
      this.showSee2 = true
    },
    closeSee2 () {
      this.showSee2 = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openOut () {
      this.showOut = true
    },
    closeOut () {
      this.showOut = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.tab-box  {
  width: 100%;
  height: calc(100% - 100px);

  .swich {
    display: flex;
    flex-direction: column;
    height: calc(100% - 240px);
    padding-top: 10px;
    box-sizing: border-box;

    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      margin-bottom: 12px;
      padding: 16px;
      border-radius: 6px;
      background: linear-gradient(180deg, #016FC0 0%, #014B84 100%);
      box-sizing: border-box;
      cursor: pointer;

      .col-top {
        width: 65px;
        height: 65px;
      }

      .col-center {
        color: #FFFFFF;
        font-size: 23px;
      }

      .col-bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #fff;
        font-size: 20px;
      }
    }
  }

  .input {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px 0;
    border-bottom: 1px solid #01EEFD;

    .item-tab {
      display: flex;
      align-items: center;
      width: 65%;

      .sort {
        margin-right: 36px;
        color: #FFFFFF;
        font-size: 20px;
        border-bottom: 4px solid #FFFFFF;
        cursor: pointer;
      }

      .active {
        border-color: #04B4D0;
      }
    }

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: .275rem;
      border: 0;
    }

    .el-select {
      width: 120px;
    }

    .select {
      width: 206px;
    }

    .blue {
      margin-right: 30px;
      background-color: #01EEFD;
    }

    .search {
      width: 3.75rem;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 206px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0rem;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: .05rem #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: .2125rem;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: .025rem solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: .0125rem solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: .0125rem solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: .0125rem solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: .1875rem;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .green {
    color: #00BB7E;
  }

  .yellow {
    color: #FF8014;
  }

  .purple {
    color: #073F73;
    cursor: pointer;
  }

  .gray {
    color: #D2D2D2;
    cursor: pointer;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: .5rem;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  /deep/.el-switch__label--left {
    position: relative;
    left: 70px;
    color: #fff;
    z-index: -1111;

    span {
      width: 40px;
    }
  }

  /deep/.el-switch__label--right {
    position: relative;
    right: 70px;
    color: #fff;
    z-index: -1111;

    span {
      display: inline-block;
      width: 40px;
    }
  }

  /deep/.el-switch__core {
    width: 65px !important;
  }

  /deep/.el-switch__label--left.is-active {
    z-index: 10;
    color: #fff;
  }

  /deep/.el-switch__label--right.is-active {
    z-index: 10;
    color: #fff;
  }
}</style>
