<template>
  <div class="item-content">
    <el-descriptions class="margin-top" title="签约信息" :column="4" border>
      <el-descriptions-item>
        <template slot="label">
          姓名
        </template>
        2091900120622
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          出生日期
        </template>
        <span class="green">
          待审核
        </span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          签约机构
        </template>
        桥西区社区服务中心
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          签约团队
        </template>
        李明明团队
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          签约医生
        </template>
        李明明
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          服务包
        </template>
        老年人服务包
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          签约周期
        </template>
        1年
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          服务来源
        </template>
        居民申请
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          签约类型
        </template>
        首次签约
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          申请时间
        </template>
        <span class="purple">
          2022/03/22 10:00:00
        </span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          生效日期
        </template>
        <span class="purple">
          2022/03/22 10:00:00
        </span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          签约备注
        </template>
        上次的药已经吃完了！
      </el-descriptions-item>
    </el-descriptions>
    <div class="title">
      <span>签约记录</span>
    </div>
    <div class="table">
      <el-table :data="tableData" height="300" max-height="300" :header-cell-style="{ background: '#01EEFD' }">
        <el-table-column prop="name" label="编号">
        </el-table-column>
        <el-table-column prop="date" label="签约机构">
        </el-table-column>
        <el-table-column prop="date" label="签约医生团队">
        </el-table-column>
        <el-table-column prop="type" label="签约医生">
        </el-table-column>
        <el-table-column prop="type2" label="服务包">
        </el-table-column>
        <el-table-column prop="type2" label="申请时间">
        </el-table-column>
        <el-table-column prop="type2" label="申请时间">
        </el-table-column>
        <el-table-column label="到期时间">
          <!-- slot-scope="scope" -->
          <template>
            <div class="see">
              <span class="el-icon-view" @click="openSeeInfo"></span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <div class="btns">
      <el-button class="close" size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      tabIndex: 0,
      options: [{
        value: '1',
        label: '1'
      }]
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openSeeInfo () {
      this.$emit('openSeeInfo')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.item-content {
  width: 100%;
  height: 100%;

  .el-descriptions {
    margin-top: 10px;
    padding: 10px 0;
    background-color: transparent;
    border-top: 1px solid #01EEFD;
    font-size: 16px;
  }

  .purple {
    color: #3670F6;
  }

  .green {
    color: #0BBB0B;
  }

  /deep/.el-descriptions__header {
    color: #01EEFD;
    font-size: 18px;
  }

  /deep/.el-descriptions__body {
    background-color: transparent;
    color: #01EEFD;
  }

  /deep/.el-descriptions-item__label.is-bordered-label {
    background-color: transparent;
    text-align: right;
    // text-align-last: justify;
    color: #01EEFD;
    white-space: nowrap;
  }

  /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
    border: none;
  }

  .title {
    width: 100%;
    height: 35px;
    line-height: 35px;
    padding: 0 10px;
    color: #01EEFD;
    font-size: 18px;
  }

  .table {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    height: 400px;
    padding-bottom: 22px;
    border: 1px solid #01EEFD;
    border-radius: 6px;
    box-sizing: border-box;

    .see {
      color: #0077F9;
      font-size: 20px;
      cursor: pointer;
    }

    /deep/.el-table .cell {
      color: #fff;
    }

    /deep/.el-table__body tbody tr {
      background-color: #081C38;
    }

    /deep/.el-table td {
      border: none;
    }

    /deep/.el-table__body .el-table__cell .cell {
      color: #01EEFD;
    }

    /deep/.el-table::before {
      background-color: #081C38;
    }

    /deep/.el-pagination__total, /deep/.el-pagination__jump {
      color: #01EEFD;
    }

    /deep/.btn-prev, /deep/.btn-next, /deep/.el-pager .number {
      background-color: #ADADAD;
      border-radius: 0;
      color: #fff;
    }

    /deep/.active {
      background-color: #01EEFD !important;
    }
  }
}

.btns {
  display: flex;
  align-items: center;
  position: absolute;
  left: 40px;
  bottom: 40px;
}

.submit,
.close {
  margin-right: 20px;
  background-color: #33465C;
  border-radius: 0;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
}

.submit {
  background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
}
</style>
