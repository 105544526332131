<template>
  <div class="info">
    <div class="info-right">
      <div class="title">
        <span class="blue"></span>
        <span>新增服务项目</span>
      </div>
      <el-descriptions class="margin-top" title="医生信息" :column="5" border>
        <el-descriptions-item>
          <template slot="label">
            项目名称
          </template>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            服务次数
          </template>
          <el-input placeholder="请输入" class="width"></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            项目类型
          </template>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            项目原价
          </template>
          <el-input placeholder="请输入" class="width"></el-input>
        </el-descriptions-item>
      </el-descriptions>

      <div class="line">
        <div class="lable">
          <span>服务介绍</span>
        </div>
        <el-input type="textarea" placeholder="请输入">
        </el-input>
      </div>
      <div class="btns">
        <el-button class="submit" size="small" @click="openSubmit">保存</el-button>
        <el-button class="close" size="small" @click="close">返回</el-button>
      </div>
    </div>
    <!-- 确定 -->
    <Submit v-if="showSubmit" @close="closeSubmit"></Submit>
  </div>
</template>

<script>
import Submit from './submit.vue'
export default {
  components: {
    Submit
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      checkList: ['1'],
      radio: 3,
      showSubmit: false
    }
  },
  methods: {
    openSubmit () {
      this.showSubmit = true
    },
    closeSubmit () {
      this.showSubmit = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-right {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      color: #01EEFD;
      font-size: 20px;

      .blue {
        width: 4px;
        height: 18px;
        margin-right: 4px;
        border-radius: 2px;
        background-color: #366FF7;
      }
    }

    .el-descriptions {
      padding: 10px 0;
      background-color: transparent;
      font-size: 18px;
    }

    .el-descriptions:nth-child(2) {
      margin-top: 10px;
      border-top: 1px solid #ECECEC;
    }

    .head-img {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }

    .flex {
      display: flex;
      align-items: center;
    }

    .line {
      display: flex;
      margin-bottom: 20px;
      padding-left: 6px;
      color: #01EEFD;
      font-size: 18px;
    }

    /deep/.el-textarea {
      width: 400px;
      margin-left: 30px;
    }

    /deep/.el-descriptions__header {
      color: #01EEFD;
      font-size: 18px;
    }

    /deep/.el-descriptions__body {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.el-descriptions-item__label.is-bordered-label {
      background-color: transparent;
      text-align: left;
      // text-align-last: justify;
      color: #01EEFD;
      white-space: nowrap;
    }

    /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
      border: none;
      padding: 6px;
    }

    /deep/.el-descriptions-item__cell {
      padding: 0;
    }

    /deep/.el-textarea__inner {
      resize: none;
    }

    .width {
      width: 200px;
    }

    .add {
      margin-left: 23px;
      cursor: pointer;
    }

    .btns {
      display: flex;
      align-items: center;
      position: absolute;
      left: 40px;
      bottom: 40px;
    }

    .submit,
    .close {
      margin-right: 20px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #FFFFFF;
      font-size: 14px;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
