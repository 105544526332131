<template>
  <div class="info">
    <div class="info-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="info-right">
      <div class="title">
        <div class="title-left">
          <span class="blue"></span>
          <span>居民详情</span>
        </div>
        <el-button size="small" class="send" @click="openNewsRun">发消息</el-button>
      </div>
      <div class="tab">
        <div class="item" v-for="(item, index) in tabs" :key="index" :class="{ active: tabIndex === index }"
          @click="tabClick(index)">
          <span>{{ item }}</span>
        </div>
      </div>
      <div class="tab-content">
        <!-- 健康档案 -->
        <Item1 v-if="tabIndex === 0" @openPerfectResident="openPerfectResident" @close="close"></Item1>
        <!-- 健康记录 -->
        <Item2 v-else-if="tabIndex === 1" @close="close"></Item2>
        <!-- 签约信息 -->
        <Item3 v-else-if="tabIndex === 2" @openSeeInfo="openSeeInfo" @close="close"></Item3>
        <!-- 服务记录 -->
        <Item4 v-else-if="tabIndex === 3" @openUpdate="openUpdate" @close="close"></Item4>
      </div>
    </div>
    <!-- 签约记录查看 -->
    <SeeInfo v-if="showSeeInfo" :isbasic="isbasic" @close="closeSeeInfo"></SeeInfo>
    <!-- 添加服务 待处理服务编辑 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 聊天 消息管理 -->
    <NewsRun v-if="showNewsRun" :isPosition="isPosition" @close="closeNewsRun"></NewsRun>
  </div>
</template>

<script>
import UserBasicInfo from '../../../../components/user-basic-info/user-basic-info.vue'
import Item1 from './item1.vue'
import Item2 from './item2.vue'
import Item3 from './item3.vue'
import Item4 from './item4.vue'
import SeeInfo from '../signingRun/serveInfo.vue'
import Update from '../serviceRun/update.vue'
import NewsRun from '../newsRun/newsRun.vue'
export default {
  components: {
    UserBasicInfo,
    Item1,
    Item2,
    Item3,
    Item4,
    SeeInfo,
    Update,
    NewsRun
  },
  data () {
    return {
      tabIndex: 0,
      tabs: ['健康档案', '健康记录', '签约信息', '服务记录'],
      isbasic: true,
      showSeeInfo: false,
      showUpdate: false,
      isPosition: false,
      showNewsRun: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openSeeInfo () {
      this.showSeeInfo = true
    },
    closeSeeInfo () {
      this.showSeeInfo = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openNewsRun () {
      this.isPosition = true
      this.showNewsRun = true
    },
    closeNewsRun () {
      this.isPosition = false
      this.showNewsRun = false
    },
    openPerfectResident () {
      this.$emit('openPerfectResident')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-left {
    width: 270px;
    height: 100%;
  }

  .info-right {
    position: relative;
    width: calc(100% - 280px);
    height: 100%;
    margin-left: 10px;
    padding: 20px 40px;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-left {
        display: flex;
        align-items: center;
        color: #01EEFD;
        font-size: 20px;
        font-weight: bold;

        .blue {
          width: 4px;
          height: 18px;
          margin-right: 4px;
          background-color: #366ff7;
          border-radius: 2px;
        }
      }

      .send {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        color: #fff;
        border: none;
      }
    }

    .tab {
      display: flex;
      align-items: center;
      height: 60px;

      .item {
        height: 41px;
        line-height: 41px;
        margin: 0 10px;
        padding: 0 28px;
        background-color: #D2D2D2;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }

      .active {
        background-color: #01EEFD;
      }
    }

    .tab-content {
      width: 100%;
      height: calc(100% - 110px);
    }

  }
}</style>
