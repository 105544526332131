<template>
  <div class="drawer">
    <div class="drawer-info">
      <div class="darwer-title">
        <span class="blue-box"></span>
        <span>服务包详情</span>
      </div>
      <!-- 待审核 -->
      <img v-if="isStatus == '1'" class="status" src="../../../../../assets/img/status1.png">
      <!-- 已驳回 -->
      <img v-if="isStatus == '3'" class="status" src="../../../../../assets/img/status2.png">
      <div class="info">
        <img class="info-img" src="../../../../../assets/img/old-man.png" alt="">
        <div class="news">
          <div class="news-line">
            <span class="span">老人服务包</span>
            <el-tag size="small">基础包</el-tag>
          </div>
          <div class="news-line">
            <span class="key">订购价</span>
            <span class="number">￥120</span>
          </div>
          <div class="news-line">
            <span class="key">标签</span>
            <el-tag size="small" class="green">慢病管理</el-tag>
            <el-tag size="small" class="blue">中医服务</el-tag>
            <el-tag size="small" class="red">老年人管理</el-tag>
          </div>
          <div class="news-line">
            <span class="key">服务对象</span>
            <span class="value">55岁以上的老人</span>
          </div>
          <div class="news-line">
            <span class="key">签约周期</span>
            <span class="value">1年(续签)</span>
          </div>
          <div class="news-line news-line1">
            <span class="key">服务介绍</span>
            <span class="value">主要以基本医疗服务和公共卫生服务为主，如部分常见病或 多发病的治疗和用药指导、重症的就医指导和转诊预约、居民健康档案的管理和 慢病管理指导等。</span>
          </div>
        </div>
      </div>
      <div class="scroll">
        <div class="table-title">
          <span>服务项目</span>
        </div>
        <el-table :data="tableData" height="200" max-height="200" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="项目编号">
          </el-table-column>
          <el-table-column prop="date" label="项目名称">
          </el-table-column>
          <el-table-column prop="date" label="次数">
          </el-table-column>
          <el-table-column prop="type" label="项目类型">
          </el-table-column>
          <el-table-column prop="type1" label="项目介绍"></el-table-column>
        </el-table>
        <div class="card">
          <div class="card-title">
            <span>服务详情字段</span>
          </div>
          <div class="card-box">
            <div class="card-line">
              <div class="key">
                <span>舒张压：</span>
              </div>
              <el-input size="small" placeholder="请输入"></el-input>
              <div class="unit">
                <span>mmHg</span>
              </div>
              <div class="key key1">
                <span>收缩压：</span>
              </div>
              <el-input size="small" placeholder="请输入"></el-input>
              <div class="unit">
                <span>mmHg</span>
              </div>
            </div>
            <div class="card-line">
              <div class="key">
                <span>症状：</span>
              </div>
              <el-input class="width" size="small" placeholder="请输入"></el-input>
            </div>
            <div class="card-line">
              <div class="key">
                <span>健康指导：</span>
              </div>
              <el-input class="width" size="small" placeholder="请输入"></el-input>
            </div>
            <div class="card-line">
              <div class="key">
                <span>备注：</span>
              </div>
              <el-input type="textarea" class="width" placeholder="请输入内容">
              </el-input>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <span>编辑记录</span>
          </div>
          <el-timeline :reverse="true">
            <el-timeline-item timestamp="审核人：李明明 提交时间：2021年2月15日10：13">
              审核通过
            </el-timeline-item>
            <el-timeline-item timestamp="审核人：李明明 提交时间：2021年2月15日10：13">
              编辑服务包
            </el-timeline-item>
            <el-timeline-item timestamp="审核人：李明明 提交时间：2021年2月15日10：13">
              新增服务包
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <div class="btns">
        <el-button class="submit" size="small" @click="close">编辑</el-button>
        <el-button v-if="isStatus == '1'" class="submit" size="small" @click="openProcess">审核通过</el-button>
        <el-button v-if="isStatus == '1'" class="close red" size="small" @click="openReject">驳回</el-button>
        <el-button v-if="isStatus == '0'" class="close red" size="small" @click="openDeactivateea">停用</el-button>
        <el-button v-if="isStatus == '2'" class="close green" size="small" @click="openFiring">启用</el-button>
        <el-button class="close" size="small" @click="close">返回</el-button>
      </div>
    </div>
    <!-- 审核通过 -->
    <Process v-if="showProcess" @close="closeProcess"></Process>
    <!-- 驳回 -->
    <Reject v-if="showReject" @close="closeReject"></Reject>
    <!-- 停用 -->
    <Deactivateea v-if="showDeactivateea" @close="closeDeactivateea"></Deactivateea>
    <!-- 启用 -->
    <Firing v-if="showFiring" @close="closeFiring"></Firing>
  </div>
</template>

<script>
import Process from './process.vue'
import Reject from './reject.vue'
import Deactivateea from './deactivate.vue'
import Firing from './firing.vue'
export default {
  props: {
    // 0 使用中 1 带审核 2 停用中 3 已驳回
    isStatus: {
      type: String,
      default: '1'
    }
  },
  components: {
    Process,
    Reject,
    Deactivateea,
    Firing
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      showProcess: false,
      showReject: false,
      showDeactivateea: false,
      showFiring: false
    }
  },
  methods: {
    openProcess () {
      this.showProcess = true
    },
    closeProcess () {
      this.showProcess = false
    },
    openReject () {
      this.showReject = true
    },
    closeReject () {
      this.showReject = false
    },
    openDeactivateea () {
      this.showDeactivateea = true
    },
    closeDeactivateea () {
      this.showDeactivateea = false
    },
    openFiring () {
      this.showFiring = true
    },
    closeFiring () {
      this.showFiring = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.drawer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  background-color: rgba(0, 0, 0, .74);

  .drawer-info {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 20px;
    padding: 20px;
    background-color: #081C38;
    border-radius: 6px;
    box-sizing: border-box;

    .darwer-title {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 20px;
      font-weight: bold;

      .blue-box {
        width: 4px;
        height: 18px;
        border-radius: 2px;
        background-color: #366FF7;
        margin-right: 5px;
      }
    }

    .status {
      position: absolute;
      top: 24px;
      left: 182px;
      width: 82px;
      height: 66px;
    }

    .btns {
      display: flex;
      align-items: center;
      position: absolute;
      left: 40px;
      bottom: 40px;
    }

    .submit,
    .close {
      margin-right: 20px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #FFFFFF;
      font-size: 14px;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }

    .red {
      background-color: #FF0000;
    }

    .green {
      background-color: #0BBB0B;
    }

    .info {
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding-left: 44px;
      box-sizing: border-box;

      .info-img {
        width: 216px;
        height: 167px;
        background-color: #fff;
      }

      .news {
        display: flex;
        flex-direction: column;
        margin-left: 20px;

        .news-line {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          color: #01EEFD;
          font-size: 18px;

          .span {
            margin-right: 42px;
          }

          .el-tag {
            border: none;
            border-radius: 12px;
            background-color: #FF9200;
            color: #fff;
          }

          .key {
            margin-right: 10px;
            color: #01EEFD;
            font-size: 14px;
          }

          .number {
            color: #FF9200;
            font-size: 12px;
          }

          .green {
            margin-right: 10px;
            background-color: #B6FFB6;
            color: #0BBB0B;
          }

          .blue {
            margin-right: 10px;
            background-color: #AFCAFF;
            color: #3778F6;
          }

          .red {
            background-color: #FFC4C4;
            color: #FF0000;
          }

          .value {
            width: 341px;
            font-size: 14px;
          }
        }

        .news-line1 {
          align-items: flex-start;
        }
      }
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 360px);

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .table-title {
      height: 40px;
      line-height: 40px;
      color: #01EEFD;
      font-size: 18px;
    }

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0rem;
      }
    }

    ::v-deep .el-table th.gutter {
      display: none;
      width: 0;
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    ::v-deep .el-table__body {
      width: 100% !important;
    }

    .el-table {
      background-color: #092B50;
      border: .05rem #073F73 solid;
    }

    // 表头文字颜色
    /deep/.el-table__header-wrapper {
      thead {
        th {
          div {
            color: #01EEFD;
            font-size: .2125rem;
            font-weight: 400;
          }
        }
      }
    }

    //表格内容颜色
    /deep/.el-table__body tbody tr {
      color: #fff;
      background-color: #092B50;
    }

    /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #092B50;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent !important
    }

    // 改变边框颜色
    .el-table--border,
    .el-table--group {
      border: .025rem solid #ddd !important;
    }

    /**
  改变表格内竖线颜色
   */
    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: .0125rem solid #ddd !important;
    }

    /deep/.el-table td {
      border-bottom: .0125rem solid #01EEFD;
    }

    /deep/.el-table thead tr th {
      border-color: transparent;
    }

    /deep/ .el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
      background-color: #073F73;
    }

    /deep/.el-table--border {
      border: .0125rem solid #073F73 !important;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #01EEFD;
    }

    .card {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      padding: 0 10px;
      border-top: 1px solid #01EEFD;
      box-sizing: border-box;

      .card-title {
        height: 50px;
        line-height: 50px;
        color: #01EEFD;
        font-size: 18px;
      }

      .card-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .card-line {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        color: #01EEFD;
        font-size: 18px;

        .key {
          white-space: nowrap;
        }

        .key1 {
          margin-left: 53px;
        }

        .el-input {
          width: 130px;
        }

        .unit {
          margin-left: 5px;
        }

        .width {
          width: 392px;
        }
      }

      /deep/.el-textarea__inner {
        resize: none;
      }

      /deep/.el-timeline-item__node {
        background-color: #01EEFD;
      }

      /deep/.el-timeline-item__tail {
        border-color: #01EEFD;
      }

      /deep/.el-timeline-item__content {
        color: #01EEFD;
        font-size: 18px;
      }

      /deep/.el-timeline-item__timestamp {
        color: #01EEFD;
        font-size: 14px;
      }
    }
  }
}
</style>
