<template>
  <div class="popup">
    <div class="popup-content">
      <div class="popup-title">
        <span>填写健康随访信息</span>
      </div>
      <div class="popup-box">
        <div class="content">
          <div class="close" @click="close">
            <i class="el-icon-close"></i>
          </div>

          <el-descriptions :column="3" border>
            <el-descriptions-item>
              <template slot="label">
                血压
              </template>
              <div class="flex">
                <el-input></el-input>
                <span>mmHg</span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                空腹血糖
              </template>
              <div class="flex">
                <el-input></el-input>
                <span>mmol/L</span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                心率
              </template>
              <div class="flex">
                <el-input></el-input>
                <span>次/分</span>
              </div>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                病情描述
              </template>
              <el-input type="textarea" placeholder="请输入">
              </el-input>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                病情描述
              </template>
              <el-input type="textarea" placeholder="请输入">
              </el-input>
            </el-descriptions-item>
            <el-descriptions-item></el-descriptions-item>
          </el-descriptions>
          <div class="btn">
            <el-button size="small" @click="close">取消</el-button>
            <el-button size="small" @click="close">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 88;
  background-color: rgba(0, 0, 0, .74);

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1320px;
    height: 374px;

    .popup-title {
      width: 267px;
      height: 50px;
      line-height: 60px;
      background-image: url('../../img/dialogTitleBg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      text-align: center;
      color: #fff;
      font-size: 20px;
    }

    .popup-box {
      width: 100%;
      height: 421px;
      padding: 10px;
      background-color: rgba(3, 27, 44, .8);
      border-radius: 10px;
      border: 4px solid rgba(1, 49, 88, .5);
      box-sizing: border-box;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 40px 20px;
        border-radius: 10px;
        border: 4px solid rgba(1, 49, 88, .5);
        box-sizing: border-box;

        .close {
          display: flex;
          align-items: flex-end;
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(26.565deg, #2057a7 50%, transparent 0);
          transform: rotate(180deg);
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .flex {
          display: flex;
          align-items: center;

          .el-input {
            width: 140px;
            margin-right: 11px;
          }
        }

        .el-descriptions {
          padding: 10px 0;
          background-color: transparent;
          font-size: 16px;
        }

        /deep/.el-descriptions__header {
          color: #01EEFD;
          font-size: 18px;
        }

        /deep/.el-descriptions__body {
          background-color: transparent;
          color: #01EEFD;
        }

        /deep/.el-descriptions-item__label.is-bordered-label {
          background-color: transparent;
          text-align: right;
          // text-align-last: justify;
          color: #01EEFD;
          white-space: nowrap;
        }

        /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
          border: none;
        }

        /deep/.el-textarea__inner {
          height: 120px;
          resize: none;
        }

        .btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 240px;
          margin: 0 auto;
        }

        .el-button {
          border-radius: 0;
          border: none;
          background-color: #33465C;
          color: #fff;
        }

        .el-button:nth-child(2) {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
