<template>
  <div class="content">
    <div class="search">
      <div class="item">
        <span class="key">选择签约日期</span>
        <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>

      <div class="item">
        <span class="key">所属机构</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="key">医生团队</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="key">医生</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="scroll">
      <div class="title">
        <span>签约量</span>
      </div>
      <el-radio-group v-model="value">
        <el-radio label="0">目标签约量</el-radio>
        <el-radio label="1">实际签约量</el-radio>
      </el-radio-group>
      <div class="bar" id="bar"></div>
      <div class="table">
        <div class="table-head">
          <el-button size="small">导出</el-button>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>日期</span>
          </div>
          <div class="table-td">
            <span>目标签约量</span>
          </div>
          <div class="table-td">
            <span>实际签约量</span>
          </div>
          <div class="table-td">
            <span>签约完成率</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>2022/04/01</span>
          </div>
          <div class="table-td">
            <span>100</span>
          </div>
          <div class="table-td">
            <span>100</span>
          </div>
          <div class="table-td">
            <span>80%</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>2022/04/01</span>
          </div>
          <div class="table-td">
            <span>100</span>
          </div>
          <div class="table-td">
            <span>100</span>
          </div>
          <div class="table-td">
            <span>80%</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>2022/04/01</span>
          </div>
          <div class="table-td">
            <span>100</span>
          </div>
          <div class="table-td">
            <span>100</span>
          </div>
          <div class="table-td">
            <span>80%</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>合计</span>
          </div>
          <div class="table-td">
            <span>100</span>
          </div>
          <div class="table-td">
            <span>100</span>
          </div>
          <div class="table-td">
            <span>80%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartXy: null,
      chartPie1: null,
      chartPie2: null,
      chartPie3: null,
      chartBar: null,
      options: [{
        value: '1',
        label: '1'
      }],
      value: ''
    }
  },
  mounted () {
    this.canvasBar()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      this.chartBar.resize()
    },
    canvasBar () {
      var chartDom = document.getElementById('bar')
      this.chartBar = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: ['1/1', '1/4', '1/4', '1/4', '1/4', '1/4', '1/4'],
          axisLabel: {
            show: true,
            color: '#04B4D0',
            fontSize: 13,
            interval: 0
            // rotate: -20
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          },
          axisTick: {
            // y轴刻度线
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            }
          },
          axisLabel: {
            show: true,
            color: '#04B4D0',
            fontSize: 14
          },
          splitLine: {
            lineStyle: {
              color: '#04B4D0'
            }
          }
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'bar',
            barWidth: '20%',
            itemStyle: {
              color: '#ACD3FF'
            }
          },
          {
            barGap: '-100%',
            barWidth: '20%',
            data: [110, 100, 100, 30, 20, 60, 70],
            name: '分量',
            type: 'bar',
            itemStyle: {
              color: '#04B4D0'
            }
          }
        ]
      }
      option && this.chartBar.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: calc(100% - 90px);

  .search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-right: 40px;
      color: #01EEFD;
      font-size: 16px;

      .key {
        margin-right: 10px;
      }
    }
  }

  .scroll {
    overflow-y: scroll;
    width: calc(100% - 192px);
    height: calc(100% - 100px);
    margin: 0 auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      height: 57px;
      line-height: 57px;
      color: #04B4D0;
      font-size: 17px;
    }

    /deep/.el-radio__label {
      color: #04B4D0;
      font-size: 16px;
    }

    .line {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .table {
      display: flex;
      flex-direction: column;

      .table-head {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        height: 61px;

        .el-button {
          background-color: #367AF6;
          border-radius: 6px;
          border: none;
          color: #fff;
        }
      }

      .table-tr {
        display: flex;
        align-items: center;
        height: 41px;
        background-color: #3569B3;

        .table-td {
          flex: 1;
          text-align: center;
          color: #FFFFFF;
          font-size: 16px;
        }
      }

      .table-tr:nth-child(odd) {
        background-color: #103A75;
      }

      .table-tr:nth-child(even) {
        background-color: #3569B3;
      }
    }

    .bar {
      width: 100%;
      height: 300px;
    }
  }
}
</style>
