<template>
  <div class="children-page">
    <div class="head">
      <el-button>签约成员列表</el-button>
    </div>
    <div class="search">
      <div class="item">
        <el-date-picker type="date" placeholder="签约生效日期">
        </el-date-picker>
      </div>
      <div class="item">
        <el-date-picker type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="item">
        <el-input placeholder="请输入姓名/身份证号/手机号" class="input"></el-input>
      </div>
      <div class="item">
        <span class="key">团队</span>
        <el-select v-model="value" placeholder="请选择医生团队">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item item2">
        <el-checkbox v-model="checked">医生本人签约</el-checkbox>
      </div>
      <div class="item">
        <el-select v-model="value" placeholder="人群分类">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item item3">
        <span>协议签字情况</span>
        <span class="icon el-icon-arrow-right"></span>
      </div>
      <div class="item">
        <el-select v-model="value" placeholder="所属区域">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item item1">
        <span class="key">服务包完成情况</span>
        <el-select v-model="value" placeholder="请选择服务包完成情况">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button>查询</el-button>
      <el-button>导出</el-button>
    </div>
    <div class="info">
      <el-table :data="tableData" border height="500">
        <el-table-column prop="date" label="基本信息" width="560">
          <template>
            <div class="news">
              <div class="news-left"></div>
              <div class="news-right">
                <div class="news-item name">
                  <span>郭秋生</span>
                </div>
                <div class="news-item">
                  <span>公卫已建档-本机构</span>
                </div>
                <div class="news-item">
                  <span>人群分类：人群分类</span>
                </div>
                <div class="news-item">
                  <span>协议签字：医生未签名   居民未签名</span>
                </div>
                <div class="news-item">
                  <span>男</span>
                </div>
                <div class="news-item">
                  <span>49岁</span>
                </div>
                <div class="news-item">
                  <span>电话：15806638888</span>
                </div>
                <div class="news-item">
                  <span>居住地址：</span>
                </div>
                <div class="news-button">
                  <div class="button">
                    <span class="el-icon-edit update"></span>
                    <span>编辑</span>
                  </div>
                  <div class="button">
                    <span class="el-icon-s-order update"></span>
                    <span>解约</span>
                  </div>
                  <div class="button">
                    <span class="el-icon-user-solid update"></span>
                    <span>组信息</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="服务包" width="300">
          <template>
            <div class="serve">
              <div class="serve-line">
                <div class="serve-key">
                  <span>服务包完成情况  0/4</span>
                </div>
                <div class="status"></div>
              </div>
              <div class="serve-line">
                <div class="serve-key">
                  <span>基础服务包（一般人群） 0/2</span>
                </div>
                <div class="button">
                  <span>履约单</span>
                </div>
              </div>
              <div class="serve-line">
                <div class="serve-key">
                  <span>基础服务包（诊疗包） 0/2</span>
                </div>
                <div class="button">
                  <span>履约单</span>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="学医期限" width="160">
          <template>
            <div class="date">
              <span class="span">发生签约日期 2022-05-18</span>
              <span>有效期 2022-05-18  至  2022-05-18</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" flex label="操作" width="160">
          <template>
            <div class="operate">
              <div class="operate-item" @click="openUpdate">
                <span>修改服务包</span>
              </div>
              <div class="operate-item">
                <span>健康档案</span>
              </div>
              <div class="operate-item" @click="openHealthRecords">
                <span>健康记录</span>
              </div>
              <div class="operate-item">
                <span>个人协议</span>
              </div>
              <div class="operate-item" @click="openDeviceBinding">
                <span>设备绑定</span>
              </div>
              <div class="operate-item" @click="openPaperAgreement">
                <span>纸质协议</span>
              </div>
              <div class="operate-item">
                <span>履约记录</span>
              </div>
              <div class="operate-item" @click="openPerformancePhotos">
                <span>履约照片</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 协议 -->
    <Agreement v-if="showAgreement" @close="closeAgreement"></Agreement>
    <!-- 修改服务包 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 健康记录 -->
    <HealthRecords v-if="showHealthRecords" @close="closeHealthRecords"></HealthRecords>
    <!-- 设备绑定 -->
    <DeviceBinding v-if="showDeviceBinding" @close="closeDeviceBinding"></DeviceBinding>
    <!-- 纸质协议 -->
    <PaperAgreement v-if="showPaperAgreement" @close="closePaperAgreement"></PaperAgreement>
    <!-- 履约照片 -->
    <PerformancePhotos v-if="showPerformancePhotos" @close="closePerformancePhotos"></PerformancePhotos>
  </div>
</template>

<script>
import Agreement from './agreement.vue'
import Update from './update.vue'
import HealthRecords from './healthRecords.vue'
import DeviceBinding from './deviceBinding.vue'
import PaperAgreement from './paperAgreement.vue'
import PerformancePhotos from './performancePhotos.vue'
export default {
  components: {
    Agreement,
    Update,
    HealthRecords,
    DeviceBinding,
    PaperAgreement,
    PerformancePhotos
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      checked: false,
      showSee: false,
      showAgreement: false,
      showUpdate: false,
      showHealthRecords: false,
      showDeviceBinding: false,
      showPaperAgreement: false,
      showPerformancePhotos: false
    }
  },
  methods: {
    openAgreement () {
      this.showAgreement = true
    },
    closeAgreement () {
      this.showAgreement = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openHealthRecords () {
      this.showHealthRecords = true
    },
    closeHealthRecords () {
      this.showHealthRecords = false
    },
    openDeviceBinding () {
      this.showDeviceBinding = true
    },
    closeDeviceBinding () {
      this.showDeviceBinding = false
    },
    openPaperAgreement () {
      // console.log(111)
      this.showPaperAgreement = true
    },
    closePaperAgreement () {
      this.showPaperAgreement = false
    },
    openPerformancePhotos () {
      this.showPerformancePhotos = true
    },
    closePerformancePhotos () {
      this.showPerformancePhotos = false
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: 100%;
  height: 100%;
  background-color: #081C38;
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    padding: 10px 0;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      margin-right: 20px;
      text-align: center;
      border: 0;
      border-radius: 0;
      background-color: #01EEFD;
      color: #fff;
      font-size: 22px;
    }
  }

  .search {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 100%;
    height: 136px;
    padding: 20px 0;
    box-sizing: border-box;

    .input {
      width: 250px;
    }

    .item {
      display: flex;
      align-items: center;
      width: 250px;
      margin-right: 30px;
      color: #01EEFD;
      font-size: 16px;

      .key {
        margin-right: 3px;
        white-space: nowrap;
      }

      .icon {
        color: #A1A6BB;
        font-size: 14px;
      }

      /deep/.el-checkbox__label {
        color: #fff;
        font-size: 16px;
      }

      /deep/.el-checkbox__inner {
        width: 22px;
        height: 22px;
        background-color: transparent;
      }

      /deep/.el-checkbox__inner::after {
        display: none;
      }

      /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
        background-color: #409EFF;
      }
    }

    .item1 {
      width: 350px;
    }

    .item2 {
      width: auto;
    }

    .item3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 160px;
      margin-right: 0;
    }

    .el-button {
      width: 90px;
      height: 38px;
      margin-right: 30px;
      background-color: #04B4D0;
      border-radius: 0;
      border: 0;
      color: #fff;
    }
  }

  .info {
    width: 100%;
    height: calc(100% - 214px);
    padding: 20px;
    background-color: #024276;
    box-sizing: border-box;

    .el-table {
      background-color: transparent;
      color: #01EEFD;
      border-bottom: 1px solid #01EEFD !important;
    }

    /deep/.el-table--border {
      border: 1px solid #01EEFD;
      border-bottom: none;
    }

    /deep/.el-table th.el-table__cell {
      background-color: transparent;
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-table tr {
      background-color: transparent;
    }

    /deep/.el-table__header {
      width: 100% !important;
    }

    /deep/.el-table__cell {
      text-align: center;
    }

    /deep/.el-table th.el-table__cell {
      color: #fff;
    }

    /deep/.el-table th.el-table__cell.is-leaf {
      border-bottom: 1px solid #01EEFD;
    }

    /deep/.el-table--border .el-table__cell {
      border-bottom: 1px solid #01EEFD;
      border-right: 1px solid #01EEFD;
    }

    /deep/.el-table--border::after,
    /deep/.el-table::before {
      background-color: transparent;
    }

    /deep/.el-table__body tr:hover>td {
      background-color: transparent;
    }

    /deep/.el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0px;
      }

      &::-webkit-scrollbar-track {
        border: none;
      }
    }

    /deep/.el-table th.gutter {
      display: none;
      width: 0
    }

    /deep/.el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    /deep/.el-table__body {
      width: 100% !important;
    }

    .news {
      display: flex;
      width: 100%;
      height: 115px;

      .news-left {
        width: 48px;
        height: 48px;
        background-color: #fff;
        border-radius: 50%;
      }

      .news-right {
        display: flex;
        flex-wrap: wrap;
        align-content: start;
        flex: 1;
        height: 100%;
        margin-left: 7px;

        .news-item {
          margin-bottom: 4px;
          margin-right: 10px;
          color: #01EEFD;
          font-size: 14px;
        }

        .news-item:nth-child(4) {
          margin-right: 0;
        }

        .name {
          font-size: 16px;
        }

        .news-button {
          display: flex;
          align-items: center;
          width: 100%;

          .button {
            display: flex;
            align-items: center;
            margin-right: 20px;
            color: #fff;
            font-size: 14px;
            cursor: pointer;

            .update {
              margin-right: 10px;
              font-size: 20px;
            }
          }
        }
      }
    }

    .serve {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 140px;
      padding: 0 10px;
      box-sizing: border-box;

      .serve-line {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 8px;
        color: #01EEFD;
        font-size: 14px;

        .serve-key {
          text-align: left;
          margin-right: 10px;
        }

        .status {
          width: 220px;
          height: 12px;
          background-color: #9CAEAF;
          border-radius: 6px;
        }

        .button {
          color: #fff;
          font-size: 14px;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;
        }
      }

      .serve-line:first-child {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .date {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 115px;
    }

    .operate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      height: 115px;
      box-sizing: border-box;

      .operate-item {
        min-width: 72px;
        // margin-right: 22px;
        text-align: left;
        white-space: nowrap;
        border-bottom: 1px solid #01EEFD;
        cursor: pointer;
      }

      .operate-item:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
