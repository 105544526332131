<template>
  <div class="referral">
    <div class="head">
      <el-button size="small" class="tabIndex" :class="{ blue: tabIndex === index }" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">{{ item }}</el-button>
    </div>
    <!-- 数据概况 -->
    <Tab1 v-if="tabIndex === 0"></Tab1>
    <!-- 签约业绩统计 -->
    <Tab2 v-else-if="tabIndex === 1"></Tab2>
    <!-- 服务业绩统计 -->
    <Tab3 v-else-if="tabIndex === 2"></Tab3>
    <!-- 居民健康异常统计 -->
    <Tab4 v-else-if="tabIndex === 3"></Tab4>
    <!-- 居民标签统计 -->
    <Tab5 v-else-if="tabIndex === 4"></Tab5>
    <!-- 居民年龄统计 -->
    <Tab6 v-else-if="tabIndex === 5"></Tab6>
    <!-- 居民学历统计 -->
    <Tab7 v-else-if="tabIndex === 6"></Tab7>
    <!-- 居民性别统计 -->
    <Tab8 v-else-if="tabIndex === 7"></Tab8>
  </div>
</template>

<script>
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2/tab2.vue'
import Tab3 from './tab3/tab3.vue'
import Tab4 from './tab4/tab4.vue'
import Tab5 from './tab5/tab5.vue'
import Tab6 from './tab6/tab6.vue'
import Tab7 from './tab7/tab7.vue'
import Tab8 from './tab8/tab8.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    Tab6,
    Tab7,
    Tab8
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['数据概况', '签约业绩统计', '服务业绩统计', '居民健康异常统计', '居民标签统计', '居民年龄统计', '居民学历统计', '居民性别统计'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: calc(100% - 10px);
  height: 100%;
  padding: 10px 37px;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  // border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      text-align: center;
      border-radius: 0;
      border: 0;
      background-color: #284867;
      color: #fff;
      font-size: 22px;
    }

    .blue {
      background-color: #01EEFD;
    }

    .color {
      margin-right: .25rem;
      background-color: #D5D5D5;
    }

    .width {
      width: 1rem;
      font-size: .225rem;
    }

    .el-input {
      width: 3.075rem;
      height: .475rem;
      border-radius: .075rem;

      /deep/.el-input__inner {
        height: .475rem;
        line-height: .475rem;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: .0125rem solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: .225rem;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: .225rem;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

}
</style>
