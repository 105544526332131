<template>
  <div class="referral">
    <div class="head">
      <el-button size="small" class="blue">居民管理</el-button>
      <!-- <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input> -->
    </div>
    <div class="input">
      <div class="item">
        <span class="span">签约状态</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="span">签约机构</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="span">医生团队</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="span">服务包</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="span">居民标签</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button class="blue" size="small" @click="openAddResident">新增居民档案</el-button>
      <el-button class="blue" size="small">导出</el-button>
      <el-input class="search" placeholder="请输入关键字" suffix-icon="el-icon-search">
      </el-input>
      <div class="icons">
        <div class="icons-tab" :class="{ 'icons-active': tabIndex == 0 }" @click="tabClick(0)">
          <span class="el-icon-s-operation"></span>
        </div>
        <div class="icons-tab" :class="{ 'icons-active': tabIndex == 1 }" @click="tabClick(1)">
          <span class="el-icon-menu"></span>
        </div>
      </div>
    </div>
    <div class="swich" v-show="tabIndex === 0">
      <el-table :data="tableData" height="400" max-height="400" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="编号">
        </el-table-column>
        <el-table-column prop="date" label="姓名">
        </el-table-column>
        <el-table-column prop="date" label="身份证号">
        </el-table-column>
        <el-table-column prop="type" label="手机号">
        </el-table-column>
        <el-table-column prop="type1" label="签约状态">
          <!-- <template slot-scope="scope">
            <div class="green" v-if="scope.row.status == '1'">
              <span>待审核</span>
            </div>
            <div class="yellow" v-else-if="scope.row.status == '2'">
              <span>已驳回</span>
            </div>
            <div class="yellow" v-else-if="scope.row.status == '3'">
              <span>待服务</span>
            </div>
          </template> -->
        </el-table-column>
        <el-table-column prop="type2" label="用户状态">
        </el-table-column>
        <el-table-column prop="type" label="居民状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.type" active-color="#04B4D0" inactive-color="#D5D5D5" active-text="启动"
              inactive-text="禁用">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="300">
          <!-- slot-scope="scope" -->
          <template>
            <div class="flex">
              <span @click="openUserInfo">查看</span>
              <span @click="openPerfectResident">编辑</span>
              <span>发消息</span>
              <span @click="openAddService">添加服务</span>
              <span @click="openDelete">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <div class="swich" v-show="tabIndex === 1">
      <el-row :gutter="12">
        <el-col :span="4" v-for="(item, index) in 18" :key="index" >
          <div class="col" @click="openUserInfo">
            <img src="../../img/emr/user.png" class="col-top">
            <div class="col-center">
              <span>李明明 62岁</span>
            </div>
            <div class="col-bottom">
              <span>高血糖</span>
              <span>高血压</span>
              <span>冠心病</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 新增居民档案 -->
    <AddResident v-if="showAddResident" @close="closeAddResident"></AddResident>
    <!-- 完善居民档案 -->
    <PerfectResident v-if="showPerfectResident" @close="closePerfectResident"></PerfectResident>
    <!-- 新增服务信息 -->
    <AddService v-if="showAddService" @close="closeAddService"></AddService>
    <!-- 删除 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
    <!-- 用户信息 -->
    <UserInfo v-if="showUserInfo" @openPerfectResident="openPerfectResident" @close="closeUserInfo"></UserInfo>
  </div>
</template>

<script>
import AddResident from './addResident.vue'
import PerfectResident from './perfectResident.vue'
import AddService from '../serviceRun/addService.vue'
import Delete from './delete.vue'
import UserInfo from './userInfo.vue'
export default {
  components: {
    AddResident,
    PerfectResident,
    AddService,
    Delete,
    UserInfo
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      tabIndex: 0,
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showSeeInfo: false,
      isbasic: false,
      showUpdate: false,
      showSee: false,
      status: '1',
      showAddResident: false,
      showPerfectResident: false,
      showAddService: false,
      showDelete: false,
      showUserInfo: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openPerfectResident () {
      this.showPerfectResident = true
    },
    closePerfectResident () {
      this.showPerfectResident = false
    },
    openAddResident () {
      this.showAddResident = true
    },
    closeAddResident () {
      this.showAddResident = false
    },
    openAddService () {
      this.showAddService = true
    },
    closeAddService () {
      this.showAddService = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    openUserInfo () {
      this.showUserInfo = true
    },
    closeUserInfo () {
      this.showUserInfo = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: calc(100% - 10px);
  height: 100%;
  padding: 10px 37px;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  // border: 4px solid rgba(1, 124, 219, .27);
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
    height: calc(100% - 240px);
    padding-top: 10px;
    box-sizing: border-box;

    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      margin-bottom: 12px;
      padding: 16px;
      border-radius: 6px;
      background: linear-gradient(180deg, #016FC0 0%, #014B84 100%);
      box-sizing: border-box;
      cursor: pointer;

      .col-top {
        width: 65px;
        height: 65px;
      }

      .col-center {
        color: #FFFFFF;
        font-size: 23px;
      }

      .col-bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #fff;
        font-size: 20px;
      }
    }
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .blue {
      margin-right: 20px;
      background-color: #01EEFD;
    }

    .color {
      margin-right: 20px;
      background-color: #D5D5D5;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
    border-bottom: 1px solid #01EEFD;

    .icons {
      position: absolute;
      right: 45px;
      bottom: 8px;
      display: flex;
      align-items: center;

      .icons-tab {
        margin-left: 21px;
        color: #fff;
        font-size: 24px;
        cursor: pointer;
      }

      .icons-active {
        color: #04B4D0;
      }
    }

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
    }

    .el-select {
      width: 120px;
    }

    .select {
      width: 206px;
    }

    .blue {
      margin-right: 30px;
      background-color: #01EEFD;
    }

    .search {
      width: 300px;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
      }

      .el-input {
        width: 206px;
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .green {
    color: #00BB7E;
  }

  .yellow {
    color: #FF8014;
  }

  .purple {
    color: #073F73;
    cursor: pointer;
  }

  .gray {
    color: #D2D2D2;
    cursor: pointer;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  /deep/.el-switch__label--left {
    position: relative;
    left: 70px;
    color: #fff;
    z-index: -1111;

    span {
      width: 40px;
    }
  }

  /deep/.el-switch__label--right {
    position: relative;
    right: 70px;
    color: #fff;
    z-index: -1111;

    span {
      display: inline-block;
      width: 40px;
    }
  }

  /deep/.el-switch__core {
    width: 65px !important;
  }

  /deep/.el-switch__label--left.is-active {
    z-index: 10;
    color: #fff;
  }

  /deep/.el-switch__label--right.is-active {
    z-index: 10;
    color: #fff;
  }
}</style>
