<template>
  <div class="page">
    <div class="page-left">
      <div v-if="isCollapse" class="collapse">
        <div class="top" @click="isCollapse = !isCollapse">
          <span>展开</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <div class="bot">
          <i class="el-icon-caret-top" @click="pre"></i>
          <i class="el-icon-refresh-right" @click="refresh"></i>
          <i class="el-icon-caret-bottom" @click="next"></i>
        </div>
      </div>
      <div v-if="!isCollapse" class="menu">
        <div class="scroll">
          <el-menu :collapse-transition="false" :default-active="activePath" router background-color="#0E487D"
            text-color="#fff" active-text-color="#04B4D0" unique-opened>
            <!-- <el-menu-item index="/wisdomhomesignature/signingrun" @click="saveNavState('/wisdomhomesignature/signingrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">签约管理</span>
              </template>
            </el-menu-item> -->
            <el-submenu index="qygl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">签约管理</span>
              </template>
              <el-menu-item index="/wisdomhomesignature/signingmemberlist">
                <template slot="title" @click="saveNavState('/wisdomhomesignature/signingmemberlist')">
                  <span class="mrgin-left">签约成员列表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/wisdomhomesignature/residentfnewal" @click="saveNavState('/wisdomhomesignature/residentfnewal')">
                <template slot="title">
                  <span class="mrgin-left">居民续约</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/wisdomhomesignature/signingagreement" @click="saveNavState('/wisdomhomesignature/signingagreement')">
                <template slot="title">
                  <span class="mrgin-left">签约协议签订</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/wisdomhomesignature/addsignedresidents" @click="saveNavState('/wisdomhomesignature/addsignedresidents')">
                <template slot="title">
                  <span class="mrgin-left">新增签约居民</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="qyfw">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">签约服务</span>
              </template>
              <el-menu-item index="/wisdomhomesignature/lightvisit" @click="saveNavState('/wisdomhomesignature/lightvisit')">
                <template slot="title">
                  <span class="mrgin-left">轻随访</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/wisdomhomesignature/healthconsultationpush" @click="saveNavState('/wisdomhomesignature/healthconsultationpush')">
                <template slot="title">
                  <span class="mrgin-left">健康咨询推送</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/wisdomhomesignature/teamperformancerecord" @click="saveNavState('/wisdomhomesignature/teamperformancerecord')">
                <template slot="title">
                  <span class="mrgin-left">团队履约记录</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/wisdomhomesignature/handlingopiniontemplate">
                <template slot="title" @click="saveNavState('/wisdomhomesignature/handlingopiniontemplate')">
                  <span class="mrgin-left">处理意见模板</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/wisdomhomesignature/signingteam" @click="saveNavState('/wisdomhomesignature/signingteam')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">签约团队</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/wisdomhomesignature/doorservicerequirementdeclaration" @click="saveNavState('/wisdomhomesignature/doorservicerequirementdeclaration')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">上门服务需求申报</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/wisdomhomesignature/summaryperformancerecords" @click="saveNavState('/wisdomhomesignature/summaryperformancerecords')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">履约记录汇总</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/wisdomhomesignature/servicerun" @click="saveNavState('/wisdomhomesignature/servicerun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">服务管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/wisdomhomesignature/residentrun" @click="saveNavState('/wisdomhomesignature/residentrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">居民管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/wisdomhomesignature/newsrun" @click="saveNavState('/wisdomhomesignature/newsrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">消息管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/wisdomhomesignature/datamanagement" @click="saveNavState('/wisdomhomesignature/datamanagement')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">资料管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/wisdomhomesignature/datastatistics" @click="saveNavState('/wisdomhomesignature/datastatistics')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">数据统计</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/wisdomhomesignature/systemmanagement" @click="saveNavState('/wisdomhomesignature/systemmanagement')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">系统设置</span>
              </template>
            </el-menu-item>
          </el-menu>
        </div>
        <!-- <div class="uncollapse" @click="isCollapse = !isCollapse">
          <span>收回</span>
          <i class="el-icon-caret-top"></i>
        </div> -->
      </div>
    </div>
    <div class="page-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activePath: '/wisdomhomesignature'
    }
  },
  mounted () {
    console.log(window.sessionStorage.getItem('wisdomhomesignaturePath'))
    this.activePath = window.sessionStorage.getItem('wisdomhomesignaturePath') || '/wisdomhomesignature'
  },
  methods: {
    saveNavState (path) {
      window.sessionStorage.setItem('wisdomhomesignaturePath', path)
      window.sessionStorage.setItem('headTabActivePath', path)
      this.activePath = path
    },
    pre () { },
    next () { },
    refresh () { }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #071224;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  .page-left {
    width: 120px;
    height: 100%;
    background-color: #0E487D;

    .collapse {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 120px;
      color: #fff;
      height: 100%;
      padding-top: 100px;

      .top {
        height: 30%;
        width: 40px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        cursor: pointer;

        i {
          font-size: 20px;
        }
      }

      .bot {
        height: 20%;
        width: 40px;
        margin-top: 80px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .menu {
      position: relative;
      width: 100%;
      height: 100%;

      .uncollapse {
        position: absolute;
        bottom: 0;
        writing-mode: tb-rl;
        text-align: center;
        line-height: 40px;
        margin-left: 50%;
        margin-top: 20px;
        transform: translateX(-50%);
        cursor: pointer;
        font-size: 20px;
        color: #fff;
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 10px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .el-menu {
          border: none;
        }

        /deep/.el-menu-item {
          display: flex;
          align-items: center;
          height: 50px;
          padding: 0 !important;
          font-size: 16px;
        }

        .font {
          font-size: 16px;
        }

        /deep/.el-submenu__title {
          display: flex;
          align-items: center;
          height: 50px;
          padding: 1px !important;
          padding-right: 2px !important;
        }

        /deep/.el-icon-arrow-down:before {
          content: '';
        }

        .menu-text {
          width: calc(100% - 40px);
          line-height: normal;
          white-space: pre-wrap;
        }

        .submenu-text {
          width: 80px;
          margin-left: 20px;
          line-height: normal;
          white-space: pre-wrap;
        }

        .mrgin-left {
          width: 80px;
          margin-left: 30px;
          line-height: normal;
          white-space: pre-wrap;
        }
      }
    }
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 107px);
    height: 100%;
    margin-left: 7px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>
