<template>
  <div class="drawer">
    <div class="drawer-box">
      <div class="close" @click="close">
        <span class="el-icon-circle-close"></span>
      </div>
      <div class="drawer-info">
        <div class="info">
          <img class="info-img" src="../../../../../assets/img/old-man.png" alt="">
          <div class="news">
            <div class="news-line">
              <span class="span">老人服务包</span>
              <el-tag size="small">基础包</el-tag>
            </div>
            <div class="news-line">
              <span class="key">订购价</span>
              <span class="number">￥120</span>
            </div>
            <div class="news-line">
              <span class="key">标签</span>
              <el-tag size="small" class="green">慢病管理</el-tag>
              <el-tag size="small" class="blue">中医服务</el-tag>
              <el-tag size="small" class="red">老年人管理</el-tag>
            </div>
            <div class="news-line">
              <span class="key">服务对象</span>
              <span class="value">55岁以上的老人</span>
            </div>
            <div class="news-line">
              <span class="key">签约周期</span>
              <span class="value">1年(续签)</span>
            </div>
            <div class="news-line news-line1">
              <span class="key">服务介绍</span>
              <span class="value">主要以基本医疗服务和公共卫生服务为主，如部分常见病或 多发病的治疗和用药指导、重症的就医指导和转诊预约、居民健康档案的管理和 慢病管理指导等。</span>
            </div>
          </div>
        </div>
        <div class="scroll">
          <el-descriptions class="margin-top" title="服务项目" :column="5" border direction="vertical">
            <el-descriptions-item>
              <template slot="label">
                项目编号
              </template>
              2021340001
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                项目名称
              </template>
              高血压随访
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                次数
              </template>
              2次
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                项目类型
              </template>
              免费项目
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                项目介绍
              </template>
              1.血压测量及记录 2.根据结果提供综合性健康指导
            </el-descriptions-item>
          </el-descriptions>
          <div class="card">
            <div class="card-title">
              <span>服务详情字段</span>
            </div>
            <div class="card-line">
              <div class="key">
                <span>舒张压：</span>
              </div>
              <el-input size="small" placeholder="请输入"></el-input>
              <div class="unit">
                <span>mmHg</span>
              </div>
              <div class="key key1">
                <span>收缩压：</span>
              </div>
              <el-input size="small" placeholder="请输入"></el-input>
              <div class="unit">
                <span>mmHg</span>
              </div>
            </div>
            <div class="card-line">
              <div class="key">
                <span>症状：</span>
              </div>
              <el-input class="width" size="small" placeholder="请输入"></el-input>
            </div>
            <div class="card-line">
              <div class="key">
                <span>健康指导：</span>
              </div>
              <el-input class="width" size="small" placeholder="请输入"></el-input>
            </div>
            <div class="card-line">
              <div class="key">
                <span>备注：</span>
              </div>
              <el-input class="width" size="small" placeholder="请输入"></el-input>
            </div>
          </div>
          <div class="card">
            <div class="card-title">
              <span>编辑记录</span>
            </div>
            <el-timeline :reverse="true">
              <el-timeline-item timestamp="审核人：李明明 提交时间：2021年2月15日10：13">
                审核通过
              </el-timeline-item>
              <el-timeline-item timestamp="审核人：李明明 提交时间：2021年2月15日10：13">
                编辑服务包
              </el-timeline-item>
              <el-timeline-item timestamp="审核人：李明明 提交时间：2021年2月15日10：13">
                新增服务包
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
        <el-button size="small" class="delete">移除</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.drawer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  background-color: rgba(0, 0, 0, .74);

  .drawer-box {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    .close {
      color: #fff;
      font-size: 30px;
      cursor: pointer;
    }

    .drawer-info {
      position: relative;
      width: 900px;
      height: 100%;
      margin-left: 20px;
      padding: 20px 0;
      background-color: #081C38;
      border-radius: 6px;
      box-sizing: border-box;

      .delete {
        position: absolute;
        left: 38px;
        bottom: 30px;
        background-color: #FF0000;
        color: #fff;
        border: none;
        border-radius: 0;
      }

      .info {
        display: flex;
        align-items: center;
        padding-left: 44px;
        box-sizing: border-box;

        .info-img {
          width: 216px;
          height: 167px;
          background-color: #fff;
        }

        .news {
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          .news-line {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            color: #01EEFD;
            font-size: 18px;

            .span {
              margin-right: 42px;
            }

            .el-tag {
              border: none;
              border-radius: 12px;
              background-color: #FF9200;
              color: #fff;
            }

            .key {
              margin-right: 10px;
              color: #01EEFD;
              font-size: 14px;
            }

            .number {
              color: #FF9200;
              font-size: 12px;
            }

            .green {
              margin-right: 10px;
              background-color: #B6FFB6;
              color: #0BBB0B;
            }

            .blue {
              margin-right: 10px;
              background-color: #AFCAFF;
              color: #3778F6;
            }

            .red {
              background-color: #FFC4C4;
              color: #FF0000;
            }

            .value {
              width: 341px;
              font-size: 14px;
            }
          }

          .news-line1 {
            align-items: flex-start;
          }
        }
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 300px);

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      .el-descriptions {
        padding: 10px 0;
        background-color: transparent;
        font-size: 16px;
      }

      /deep/.el-descriptions__header {
        padding-left: 10px;
        color: #01EEFD;
        font-size: 18px;
      }

      /deep/.el-descriptions__body {
        background-color: transparent;
        color: #01EEFD;
      }

      /deep/.el-descriptions-item__label.is-bordered-label {
        background-color: transparent;
        text-align: center;
        color: #01EEFD;
        white-space: nowrap;
      }

      /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
        border: none;
      }

      /deep/.el-descriptions-item__cell {
        text-align: center;
      }

      .card {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 10px;
        border-top: 1px solid #01EEFD;
        box-sizing: border-box;

        .card-title {
          height: 50px;
          line-height: 50px;
          color: #01EEFD;
          font-size: 18px;
        }

        .card-line {
          display: flex;
          align-items: center;
          margin-bottom: 18px;
          color: #01EEFD;
          font-size: 18px;

          .key {
            white-space: nowrap;
          }

          .key1 {
            margin-left: 53px;
          }

          .el-input {
            width: 130px;
          }

          .unit {
            margin-left: 5px;
          }

          .width {
            width: 392px;
          }
        }

        /deep/.el-timeline-item__node {
          background-color: #01EEFD;
        }

        /deep/.el-timeline-item__tail {
          border-color: #01EEFD;
        }

        /deep/.el-timeline-item__content {
          color: #01EEFD;
          font-size: 18px;
        }

        /deep/.el-timeline-item__timestamp {
          color: #01EEFD;
          font-size: 14px;
        }
      }
    }

  }
}
</style>
