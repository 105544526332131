<template>
  <div class="page">
    <div class="page-left">
      <div v-if="isCollapse" class="collapse">
        <div class="top" @click="isCollapse = !isCollapse">
          <span>展开</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <div class="bot">
          <i class="el-icon-caret-top" @click="pre"></i>
          <i class="el-icon-refresh-right" @click="refresh"></i>
          <i class="el-icon-caret-bottom" @click="next"></i>
        </div>
      </div>
      <div v-if="!isCollapse" class="menu">
        <div class="scroll">
          <el-menu :collapse-transition="false" :default-active="activePath" router background-color="#0E487D"
            text-color="#fff" active-text-color="#04B4D0" unique-opened>
            <el-submenu index="ymyy">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">疫苗预约</span>
              </template>
              <el-menu-item index="/reservationservice/vaccineappointment/bookingrun"
                @click="saveNavState('/reservationservice/vaccineappointment/bookingrun')">
                <template slot="title">
                  <span class="mrgin-left">预约管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/vaccineappointment/dataoverview"
                @click="saveNavState('/reservationservice/vaccineappointment/dataoverview')">
                <template slot="title">
                  <span class="mrgin-left">数据概述</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/vaccineappointment/vaccinerun"
                @click="saveNavState('/reservationservice/vaccineappointment/vaccinerun')">
                <template slot="title">
                  <span class="mrgin-left">疫苗管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/vaccineappointment/userrun"
                @click="saveNavState('/reservationservice/vaccineappointment/userrun')">
                <template slot="title">
                  <span class="mrgin-left">用户管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/vaccineappointment/articlerun"
                @click="saveNavState('/reservationservice/vaccineappointment/articlerun')">
                <template slot="title">
                  <span class="mrgin-left">文章管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/vaccineappointment/otherset"
                @click="saveNavState('/reservationservice/vaccineappointment/otherset')">
                <template slot="title">
                  <span class="mrgin-left">其他设置</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="tjyy">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">体检预约</span>
              </template>
              <el-menu-item index="/reservationservice/testreservation/reservationtest"
                @click="saveNavState('/reservationservice/testreservation/reservationtest')">
                <template slot="title">
                  <span class="mrgin-left">预约体检</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/testreservation/testschedul"
                @click="saveNavState('/reservationservice/testreservation/testschedul')">
                <template slot="title">
                  <span class="mrgin-left">体检排班</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/404">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">家签预约</span>
              </template>
            </el-menu-item>
            <el-submenu index="yygl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">预约管理</span>
              </template>
              <el-menu-item index="/reservationservice/reservationrun/reservationregister"
                @click="saveNavState('/reservationservice/reservationrun/reservationregister')">
                <template slot="title">
                  <span class="mrgin-left">预约挂号</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/reservationrun/doctorschedul"
                @click="saveNavState('/reservationservice/reservationrun/doctorschedul')">
                <template slot="title">
                  <span class="mrgin-left">医生排班</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="yyxx">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">医院信息</span>
              </template>

              <el-menu-item index="/reservationservice/hospitalinfo/doctorrun"
                @click="saveNavState('/reservationservice/hospitalinfo/doctorrun')">
                <template slot="title">
                  <span class="mrgin-left">医生管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/hospitalinfo/departmentrun"
                @click="saveNavState('/reservationservice/hospitalinfo/departmentrun')">
                <template slot="title">
                  <span class="mrgin-left">科室管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/hospitalinfo/testpackage"
                @click="saveNavState('/reservationservice/hospitalinfo/testpackage')">
                <template slot="title">
                  <span class="mrgin-left">体检套餐</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/hospitalinfo/healthencyclopedia"
                @click="saveNavState('/reservationservice/hospitalinfo/healthencyclopedia')">
                <template slot="title">
                  <span class="mrgin-left">健康百科</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/hospitalinfo/hospitalnavigation"
                @click="saveNavState('/reservationservice/hospitalinfo/hospitalnavigation')">
                <template slot="title">
                  <span class="mrgin-left">医院导航</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/hospitalinfo/aboutus"
                @click="saveNavState('/reservationservice/hospitalinfo/aboutus')">
                <template slot="title">
                  <span class="mrgin-left">医院简介</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/hospitalinfo/appointmentnotice"
                @click="saveNavState('/reservationservice/hospitalinfo/appointmentnotice')">
                <template slot="title">
                  <span class="mrgin-left">预约须知</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/hospitalinfo/medicalrecord"
                @click="saveNavState('/reservationservice/hospitalinfo/medicalrecord')">
                <template slot="title">
                  <span class="mrgin-left">病案配送通知</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/hospitalinfo/userrun"
                @click="saveNavState('/reservationservice/hospitalinfo/userrun')">
                <template slot="title">
                  <span class="mrgin-left">用户反馈管理</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="xtgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">系统管理</span>
              </template>
              <el-menu-item index="/reservationservice/systemset/administratorrun"
                @click="saveNavState('/reservationservice/systemset/administratorrun')">
                <template slot="title">
                  <span class="mrgin-left">管理员管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/systemset/roles"
                @click="saveNavState('/reservationservice/systemset/roles')">
                <template slot="title">
                  <span class="mrgin-left">角色管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/systemset/titlerun"
                @click="saveNavState('/reservationservice/systemset/titlerun')">
                <template slot="title">
                  <span class="mrgin-left">职称管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/systemset/messageannouncement"
                @click="saveNavState('/reservationservice/systemset/messageannouncement')">
                <template slot="title">
                  <span class="mrgin-left">消息管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/reservationservice/systemset/updatepassword"
                @click="saveNavState('/reservationservice/systemset/updatepassword')">
                <template slot="title">
                  <span class="mrgin-left">修改密码</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
        <!-- <div class="uncollapse" @click="isCollapse = !isCollapse">
          <span>收回</span>
          <i class="el-icon-caret-top"></i>
        </div> -->
      </div>
    </div>
    <div class="page-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activePath: '/reservationservice'
    }
  },
  mounted () {
    this.activePath = window.sessionStorage.getItem('reservationservicePath') || '/reservationservice'
  },
  methods: {
    saveNavState (path) {
      window.sessionStorage.setItem('reservationservicePath', path)
      window.sessionStorage.setItem('headTabActivePath', path)
      this.activePath = path
    }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #071224;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  .page-left {
    overflow: hidden;
    width: 120px;
    height: 100%;
    background-color: #0E487D;

    .collapse {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 120px;
      color: #fff;
      height: 100%;
      padding-top: 100px;

      .top {
        height: 30%;
        width: 40px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        cursor: pointer;

        i {
          font-size: 20px;
        }
      }

      .bot {
        height: 20%;
        width: 40px;
        margin-top: 80px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .menu {
      position: relative;
      width: 100%;
      height: 100%;

      .uncollapse {
        position: absolute;
        bottom: 0;
        writing-mode: tb-rl;
        text-align: center;
        line-height: 40px;
        margin-left: 50%;
        margin-top: 20px;
        transform: translateX(-50%);
        cursor: pointer;
        font-size: 20px;
        color: #fff;
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 10px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .el-menu {
          border: none;
        }

        /deep/.el-menu-item {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 0 !important;
          font-size: 16px;
        }

        .font {
          font-size: 16px;
        }

        /deep/.el-submenu__title {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 1px !important;
          padding-right: 2px !important;
        }

        /deep/.el-icon-arrow-down:before {
          content: '';
        }

        .menu-text {
          // width: calc(100% - 40px);
          // line-height: normal;
          // white-space: pre-wrap;
        }

        .submenu-text {
          width: calc(100% - 30px);
          line-height: normal;
          white-space: pre-wrap;
        }

        .mrgin-left {
          display: block;
          width: 80px;
          margin-left: 30px;
          line-height: normal;
          white-space: pre-wrap;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* 设置最大显示行数 */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .item-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 360px;
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 107px);
    height: 100%;
    margin-left: 7px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>
