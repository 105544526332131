<template>
  <div class="drawer">
    <div class="drawer-box">
      <div class="close" @click="close">
        <span class="el-icon-circle-close"></span>
      </div>
      <div class="drawer-info">
        <div class="drawer-title">
          <span>医生信息</span>
        </div>
        <div class="drawer-line">
          <div class="drawer-line drawer-line1">
            <div class="key">
              <span>医生编号</span>
            </div>
            <div class="value">
              <span>2091900122</span>
            </div>
          </div>
          <div class="drawer-line drawer-line1">
            <div class="key">
              <span>医生姓名</span>
            </div>
            <div class="value">
              <span>李明明</span>
            </div>
          </div>
        </div>
        <div class="drawer-line">
          <div class="drawer-line drawer-line1">
            <div class="key">
              <span>医生头像</span>
            </div>
            <img class="head-img" src="../../../img/emr/user.png">
          </div>
          <div class="drawer-line drawer-line1">
            <div class="key">
              <span>性别</span>
            </div>
            <div class="value">
              <span>男</span>
            </div>
          </div>
        </div>
        <div class="drawer-line">
          <div class="key">
            <span>角色</span>
          </div>
          <div class="value">
            <span>全职护士</span>
          </div>
        </div>

        <div class="drawer-line drawer-line2">
          <div class="key">
            <span>医生简介</span>
          </div>
          <div class="value">
            <span>1.北京大学第一医院内分泌科专家，从事内分泌相关疾病35余年,尤其擅长代谢性疾
              病引起的不孕不育诊疗，现任北京天伦医院不孕不育妇科特聘专家。2.1979—1984年就读于北京医学院医学系，随后在北京医科大学第一医院(现北京大学第一医院)内分泌科读研究生，毕业后留院工作至今。</span>
          </div>
        </div>

        <div class="drawer-line drawer-line2">
          <div class="key">
            <span>医生擅长</span>
          </div>
          <div class="value">
            <span>1.北京大学第一医院内分泌科专家，从事内分泌相关疾病35余年,尤其擅长代谢性疾
              病引起的不孕不育诊疗，现任北京天伦医院不孕不育妇科特聘专家。2.1979—1984年就读于北京医学院医学系，随后在北京医科大学第一医院(现北京大学第一医院)内分泌科读研究生，毕业后留院工作至今。</span>
          </div>
        </div>

        <div class="drawer-line drawer-line2">
          <div class="key">
            <span>加入团队</span>
          </div>
          <div class="value">
            <span>1.北京大学第一医院内分泌科专家，从事内分泌相关疾病35余年,尤其擅长代谢性疾
              病引起的不孕不育诊疗，现任北京天伦医院不孕不育妇科特聘专家。2.1979—1984年就读于北京医学院医学系，随后在北京医科大学第一医院(现北京大学第一医院)内分泌科读研究生，毕业后留院工作至今。</span>
          </div>
        </div>

        <div class="drawer-line">
          <div class="drawer-line drawer-line1">
            <div class="key">
              <span>签约人数</span>
            </div>
            <div class="value">
              <span>80人</span>
            </div>
          </div>
          <div class="drawer-line drawer-line1">
            <div class="key">
              <span>创建时间</span>
            </div>
            <div class="value">
              <span>2021-06-06 10:38:12</span>
            </div>
          </div>
        </div>
        <div class="drawer-line">
          <div class="drawer-line drawer-line1">
            <div class="key">
              <span>创建人</span>
            </div>
            <div class="value">
              <span>李明</span>
            </div>
          </div>
          <div class="drawer-line drawer-line1">
            <div class="key">
              <span>账号状态</span>
            </div>
            <div class="value">
              <span class="radio">启用</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.drawer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
  background-color: rgba(0, 0, 0, .74);

  .drawer-box {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 600px;

    .close {
      color: #fff;
      font-size: 30px;
      cursor: pointer;
    }

    .drawer-info {
      width: 800px;
      height: 100%;
      margin-left: 20px;
      padding: 10px;
      background-color: #081C38;
      border-radius: 6px;
      box-sizing: border-box;

      .drawer-title {
        color: #01EEFD;
        font-size: 18px;
      }

      /deep/.el-descriptions__header {
        color: #01EEFD;
        font-size: 18px;
      }

      /deep/.el-descriptions__body {
        background-color: transparent;
        color: #01EEFD;
      }

      /deep/.el-descriptions-item__label.is-bordered-label {
        background-color: transparent;
        text-align: right;
        // text-align-last: justify;
        color: #01EEFD;
        white-space: nowrap;
      }

      /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
        border: none;
        padding: 6px;
      }

      .drawer-line {
        display: flex;
        align-items: center;
        margin-top: 15px;
        color: #01EEFD;

        .key {
          width: 76px;
          white-space: nowrap;
          text-align-last: justify;
          font-size: 18px;
        }

        .value {
          width: calc(100% - 90);
          margin-left: 10px;
          font-size: 14px;

          .radio {
            display: block;
            padding: 4px 10px;
            background-color: #0BBB0B;
            border-radius: 10px;
            color: #fff;
            font-size: 11px;
          }
        }
      }

      .drawer-line1 {
        width: 50%;

        .head-img {
          width: 36px;
          height: 36px;
          margin-left: 10px;
        }
      }

      .drawer-line2 {
        align-items: flex-start;
      }
    }
  }
}
</style>
