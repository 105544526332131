<template>
  <div class="item-content">
    <div class="item-title">
      <span class="title">体重趋势</span>
      <span class="title">体重记录</span>
    </div>
    <div class="item-box">
      <div class="charts" id="echarts"></div>
      <div class="item-info">
        <div class="table">
          <div class="thead">
            <span class="flex flex3">测量时间</span>
            <span class="flex">数值(kg)</span>
            <span class="flex">评估</span>
            <span class="flex flex2">操作</span>
          </div>
          <div class="scroll">
            <div class="list" v-for="(item, index) in 20" :key="index">
              <span class="flex flex3">2022-02-02 02:02:05</span>
              <span class="flex">49.2</span>
              <span class="flex green">正常</span>
              <div class="flex flex2 flex4">
                <span class="blue" @click="openUpdateTz">编辑</span>
                <span class="red" @click="openDelete">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item-box">
      <div class="charts" id="echarts1"></div>
      <div class="item-info">
        <div class="table">
          <div class="thead">
            <span class="flex flex3">测量时间</span>
            <span class="flex">数值(kg)</span>
            <span class="flex">评估</span>
            <span class="flex flex2">操作</span>
          </div>
          <div class="scroll">
            <div class="list" v-for="(item, index) in 20" :key="index">
              <span class="flex flex3">2022-02-02 02:02:05</span>
              <span class="flex">49.2</span>
              <span class="flex green">正常</span>
              <div class="flex flex2 flex4">
                <span class="blue">编辑</span>
                <span class="red">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button class="close" size="small" @click="close">返回</el-button>
    </div>
    <!-- 编辑健康记录 -->
    <UpdateTz v-if="showUpdateTz" @close="closeUpdateTz"></UpdateTz>
    <!-- 删除 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
  </div>
</template>

<script>
import UpdateTz from './updateTz.vue'
import Delete from './delete.vue'
export default {
  components: {
    UpdateTz,
    Delete
  },
  data () {
    return {
      myChart: null,
      myChart1: null,
      showUpdateTz: false,
      showDelete: false
    }
  },
  mounted () {
    this.canvas()
    this.canvas1()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      this.myChart.resize()
      this.myChart1.resize()
    },
    canvas () {
      var chartDom = document.getElementById('echarts')
      if (!this.myChart) {
        this.myChart = this.$echarts.init(chartDom)
      }
      var option
      option = {
        grid: {
          left: 40,
          right: 30,
          bottom: 30,
          top: 30
        },
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#01EEFD',
              fontSize: 15
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '单位:kg',
          nameTextStyle: {
            color: '#01EEFD',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#01EEFD',
              fontSize: 15
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              color: '#fff' // 改变折线点的颜色
            },
            lineStyle: {
              color: '#0077F9' // 改变折线颜色
            },
            label: {
              show: true,
              textStyle: {
                color: '#01EEFD'
              }
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    canvas1 () {
      var chartDom = document.getElementById('echarts1')
      if (!this.myChart1) {
        this.myChart1 = this.$echarts.init(chartDom)
      }
      var option
      option = {
        grid: {
          left: 40,
          right: 30,
          bottom: 30,
          top: 30
        },
        xAxis: {
          type: 'category',
          data: ['1/4', '1/24', '1/31', '2/4', '2/14', '2/18', '3/1'],
          axisLabel: {
            show: true,
            textStyle: {
              color: '#01EEFD',
              fontSize: 15
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '单位:mmol/L',
          nameTextStyle: {
            color: '#01EEFD',
            nameLocation: 'start'
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#01EEFD',
              fontSize: 15
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#01EEFD']
            }
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            itemStyle: {
              color: '#fff'// 改变折线点的颜色
            },
            lineStyle: {
              color: '#0077F9' // 改变折线颜色
            },
            label: {
              show: true,
              textStyle: {
                color: '#01EEFD'
              }
            }
          }
        ]
      }

      option && this.myChart1.setOption(option)
    },
    openUpdateTz () {
      this.showUpdateTz = true
    },
    closeUpdateTz () {
      this.showUpdateTz = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.item-content {
  width: 100%;
  height: 100%;

  .item-title {
    display: flex;
    align-items: center;
    width: 100%;

    .title {
      width: 50%;
      color: #01EEFD;
      font-size: 18px;
    }
  }

  .item-box {
    display: flex;
    align-items: center;
    height: 240px;
    margin-top: 8px;
  }

  .charts,
  .item-info {
    width: 50%;
    height: 100%;
  }

  .charts {
    padding: 0 20px;
    box-sizing: border-box;

    .chart {
      width: 100%;
      height: 100%;
    }
  }

  .item-info {
    border-left: 1px solid #01EEFD;
    box-sizing: border-box;

    .table {
      width: 80%;
      height: 100%;
      margin: 0 auto;
      border: 1px solid #01EEFD;
      border-radius: 6px;
      box-sizing: border-box;

      .thead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 34px;
        background-color: #01EEFD;
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 14px;
      }

      .flex {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
        text-align: center;
      }

      .flex2 {
        flex: 2;
      }

      .flex3 {
        flex: 3;
      }

      .flex4 {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 34px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 30px;
          color: #FFFFFF;
          font-size: 14px;

          .green {
            color: #5BE00E;
          }

          .red {
            color: #FF0000;
            cursor: pointer;
          }

          .blue {
            color: #0077F9;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.btns {
  display: flex;
  align-items: center;
  position: absolute;
  left: 40px;
  bottom: 40px;
}

.submit,
.close {
  margin-right: 20px;
  background-color: #33465C;
  border-radius: 0;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
}

.submit {
  background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
}
</style>
