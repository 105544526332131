<template>
  <div class="write">
    <div class="title">
      <span>我的排班</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="table">
        <div class="table-head">
          <span class="flex"></span>
          <span class="flex">星期一</span>
          <span class="flex">星期二</span>
          <span class="flex">星期三</span>
          <span class="flex">星期四</span>
          <span class="flex">星期五</span>
          <span class="flex">星期六</span>
          <span class="flex">星期日</span>
        </div>
        <div class="table-tr">
          <span class="flex flex1">上午</span>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week1Upper === index }" v-for="(item, index) in selects" :key="index" @click="select('week1Upper', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week2Upper === index }" v-for="(item, index) in selects" :key="index" @click="select('week2Upper', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week3Upper === index }" v-for="(item, index) in selects" :key="index" @click="select('week3Upper', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week4Upper === index }" v-for="(item, index) in selects" :key="index" @click="select('week4Upper', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week5Upper === index }" v-for="(item, index) in selects" :key="index" @click="select('week5Upper', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week6Upper === index }" v-for="(item, index) in selects" :key="index" @click="select('week6Upper', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week7Upper === index }" v-for="(item, index) in selects" :key="index" @click="select('week7Upper', index)">{{ item }}</span>
            </div>
          </div>
        </div>
        <div class="table-tr">
          <span class="flex flex1">下午</span>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week1Down === index }" v-for="(item, index) in selects" :key="index" @click="select('week1Down', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week2Down === index }" v-for="(item, index) in selects" :key="index" @click="select('week2Down', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week3Down === index }" v-for="(item, index) in selects" :key="index" @click="select('week3Down', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week4Down === index }" v-for="(item, index) in selects" :key="index" @click="select('week4Down', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week5Down === index }" v-for="(item, index) in selects" :key="index" @click="select('week5Down', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week6Down === index }" v-for="(item, index) in selects" :key="index" @click="select('week6Down', index)">{{ item }}</span>
            </div>
          </div>
          <div class="flex flex2">
            <div class="select">
              <span class="select-item" :class="{ 'select-active': week7Down === index }" v-for="(item, index) in selects" :key="index" @click="select('week7Down', index)">{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <el-button class="button" @click="closePopup">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selects: Object.freeze(['随访', '坐班', '休息']),
      week1Upper: 0,
      week1Down: 0,
      week2Upper: 0,
      week2Down: 0,
      week3Upper: 0,
      week3Down: 0,
      week4Upper: 0,
      week4Down: 0,
      week5Upper: 0,
      week5Down: 0,
      week6Upper: 0,
      week6Down: 0,
      week7Upper: 0,
      week7Down: 0
    }
  },
  methods: {
    select (key, value) {
      this.$data[key] = value
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8888;
  width: 100%;
  height: 100%;
  padding: 0 200px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 533px;
    margin-top: 10px;
    padding: 70px 65px;
    background-image: url('../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 22px;
      right: 24px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .table {
      width: 100%;
      height: 300px;
      border: 4px solid #073F73;
      box-sizing: border-box;

      .table-head {
        display: flex;
        align-items: center;
        height: 44px;
        background-color: #024276;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 17px;

        .flex {
          flex: 1;
          height: 44px;
          line-height: 44px;
          text-align: center;
        }
      }

      .table-tr {
        display: flex;
        align-items: center;
        height: 124px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 17px;

        .flex {
          flex: 1;
          height: 124px;
          text-align: center;
          border-right: 1px solid #01EEFD;
          box-sizing: border-box;
        }

        .flex1 {
          line-height: 124px;
        }

        .flex2 {
          display: flex;
          align-items: center;
          justify-content: center;

          .select {
            display: flex;
            flex-direction: column;
            width: 105px;

            .select-item {
              height: 26px;
              line-height: 26px;
              margin-bottom: 6px;
              color: #fff;
              font-size: 16px;
              cursor: pointer;
            }

            .select-active {
              position: relative;
              padding-left: 3px;
              background-color: rgba(4, 180, 208, .74);
              box-sizing: border-box;

              &::after {
                position: absolute;
                right: 0px;
                z-index: 88;
                content: '√';
                height: 16px;
                line-height: 16px;
                color: #fff;
                font-size: 14px;
              }

              &::before {
                position: absolute;
                right: 22px;
                content: '';
                width: 0;
                height: 0;
                border-width: 15px;
                border-style: solid;
                border-color: #04B4D0 transparent transparent transparent ;
                transform: rotate(-135deg);
                transform-origin: top right;
                color: #fff;
                font-size: 16px;
              }
            }
          }
        }

        .flex:last-child {
          border-right: none;
        }
      }

      .table-tr:last-child {
        border-bottom: none;
      }
    }

    .btns {
      position: absolute;
      bottom: 56px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;

      .el-button {
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
