<template>
  <div class="item-content">
    <div class="list">
      <div class="cell" v-for="(item, index) in 10" :key="index">
        <div class="cell-head">
          <div class="left">
            <span>服务信息</span>
            <span class="update">编辑</span>
          </div>
          <div class="right">
            <div class="icon">
              <span class="el-icon-alarm-clock"></span>
            </div>
            <el-tag class="yellow">待服务</el-tag>
          </div>
        </div>
        <div class="cell-box">
          <div class="cell-item">
            <div class="key">
              <span>服务编号</span>
            </div>
            <div class="value">
              <span>2091900120622</span>
            </div>
          </div>
          <div class="cell-item">
            <div class="key">
              <span>服务状态</span>
            </div>
            <div class="value">
              <span class="yellow">待服务</span>
            </div>
          </div>
          <div class="cell-item">
            <div class="key">
              <span>服务机构</span>
            </div>
            <div class="value">
              <span>桥西区社区服务中心</span>
            </div>
          </div>
          <div class="cell-item">
            <div class="key">
              <span>服务医生</span>
            </div>
            <div class="value">
              <span>李明明</span>
            </div>
          </div>
          <div class="cell-item">
            <div class="key">
              <span>服务包</span>
            </div>
            <div class="value">
              <span>老年人服务包</span>
            </div>
          </div>
          <div class="cell-item">
            <div class="key">
              <span>服务项目</span>
            </div>
            <div class="value">
              <span>高血压随访（剩余2次）</span>
            </div>
          </div>

          <div class="cell-item">
            <div class="key">
              <span>服务地点</span>
            </div>
            <div class="value">
              <span>机构门诊</span>
            </div>
          </div>
          <div class="cell-item">
            <div class="key">
              <span>提交时间</span>
            </div>
            <div class="value">
              <span class="blue">2022/03/22 10:00:00</span>
            </div>
          </div>
          <div class="cell-item">
            <div class="key">
              <span>审核时间</span>
            </div>
            <div class="value">
              <span class="blue">2022/03/22 10:00:00</span>
            </div>
          </div>

          <div class="cell-item">
            <div class="key">
              <span>服务时间</span>
            </div>
            <div class="value">
              <span class="blue">2022/03/22 10:00:00</span>
            </div>
          </div>
          <div class="cell-item">
            <div class="key">
              <span>服务团队</span>
            </div>
            <div class="value">
              <span>李明明团队</span>
            </div>
          </div>
          <div class="cell-item">
            <div class="key">
              <span>服务来源</span>
            </div>
            <div class="value">
              <span>居民申请</span>
            </div>
          </div>

          <div class="cell-item">
            <div class="key">
              <span>审核人</span>
            </div>
            <div class="value">
              <span>王晓明</span>
            </div>
          </div>
          <div class="cell-item">
            <div class="key">
              <span>服务备注</span>
            </div>
            <div class="value">
              <span>上次的药已经吃完了！</span>
            </div>
          </div>
          <div class="cell-item"></div>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button class="submit" size="small" @click="openUpdate">添加服务</el-button>
      <el-button class="close" size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    openUpdate () {
      this.$emit('openUpdate')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.item-content {
  width: 100%;
  height: 100%;
}

.list {
  overflow-y: scroll;
  width: 100%;
  height: calc(100% - 50px);
  border-top: 1px solid #01EEFD;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, .4);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .cell {
    width: 100%;
    height: 280px;
    padding: 10px;
    box-sizing: border-box;

    .cell-head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
        color: #01EEFD;
        font-size: 20px;

        .update {
          margin-left: 14px;
          color: #0077F9;
          font-size: 16px;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .icon {
          color: #FF7600;
          font-size: 20px;
        }

        .el-tag {
          margin-left: 10px;
          padding: 0 20px;
          background-color: #00BB7E;
          border: none;
          border-radius: 0 6px 0 0;
          color: #fff;
          font-size: 18px;
        }

        .yellow {
          background-color: #FF7600;
        }
      }
    }

    .cell-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: calc(100% - 140px);

      .cell-item {
        display: flex;
        align-items: center;
        width: 33%;
        margin-top: 10px;
        color: #01EEFD;

        .key {
          width: 80px;
          font-size: 18px;
          white-space: nowrap;
          text-align-last: justify;
        }

        .value {
          margin-left: 7px;
          font-size: 16px;

          .yellow {
            color: #FF7600;
          }

          .blue {
            color: #3676F6;
          }
        }
      }
    }
  }
}

.btns {
  display: flex;
  align-items: center;
  position: absolute;
  left: 40px;
  bottom: 40px;
}

.submit,
.close {
  margin-right: 20px;
  background-color: #33465C;
  border-radius: 0;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
}

.submit {
  background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
}
</style>
