<template>
  <div class="content">
    <div class="search">
      <div class="search-left">
        <div class="item">
          <span class="key">操作人</span>
          <el-select v-model="value" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
        </div>
        <div class="item">
          <span class="key">操作日期</span>
          <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="item">
            <el-input placeholder="请输入关键字" suffix-icon="el-icon-search">
            </el-input>
          </div>
      </div>
      <div class="search-right">
        <el-button>导出</el-button>
      </div>
    </div>
    <div class="scroll">
      <el-table :data="tableData" height="400" max-height="400" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="操作人">
        </el-table-column>
        <el-table-column prop="date" label="操作时间">
        </el-table-column>
        <el-table-column prop="date" label="IP">
        </el-table-column>
        <el-table-column prop="type" label="操作模块">
        </el-table-column>
        <el-table-column prop="type" label="操作详情">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: { },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showAdd: false,
      showUpdate: false,
      showDelete: false
    }
  },
  mounted () { },
  beforeDestroy () { },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: calc(100% - 90px);

  .search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .search-left {
      display: flex;
      align-items: center;
    }

    .search-right {
      display: flex;
      align-items: center;
    }

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 16px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
      }
    }

    .el-button {
      background-color: #04B4D0;
      border-radius: 0;
      border: none;
      color: #fff;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);
    margin: 0 auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0rem;
      }
    }

    ::v-deep .el-table th.gutter {
      display: none;
      width: 0;
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    ::v-deep .el-table__body {
      width: 100% !important;
    }

    .el-table {
      margin-top: .25rem;
      background-color: #092B50;
      border: .05rem #073F73 solid;
    }

    // 表头文字颜色
    /deep/.el-table__header-wrapper {
      thead {
        th {
          div {
            color: #01EEFD;
            font-size: .2125rem;
            font-weight: 400;
          }
        }
      }
    }

    //表格内容颜色
    /deep/.el-table__body tbody tr {
      color: #fff;
      background-color: #092B50;
    }

    /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #092B50;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent !important
    }

    // 改变边框颜色
    .el-table--border,
    .el-table--group {
      border: .025rem solid #ddd !important;
    }

    /**
  改变表格内竖线颜色
   */
    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: .0125rem solid #ddd !important;
    }

    /deep/.el-table td {
      border-bottom: .0125rem solid #01EEFD;
    }

    /deep/.el-table thead tr th {
      border-color: transparent;
    }

    /deep/ .el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
      background-color: #073F73;
    }

    /deep/.el-table--border {
      border: .0125rem solid #073F73 !important;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #0e75d4;
      font-size: 15px;

      span {
        cursor: pointer;
      }
    }

    .flex1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .img {
        width: 30px;
        height: 30px;
        margin-right: 22px;
      }
    }

    .color {
      color: #FFFFFF;
      font-size: 24px;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: .5rem;

      ::v-deep.el-pagination__total,
      ::v-deep.el-pagination__jump {
        color: #01EEFD;

        .el-input__inner {
          color: #fff;
          background-color: transparent;
        }
      }

      ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #01EEFD;
      }
    }
  }

  /deep/.el-switch__label--left {
    position: relative;
    left: 70px;
    color: #fff;
    z-index: -1111;

    span {
      width: 40px;
    }
  }

  /deep/.el-switch__label--right {
    position: relative;
    right: 70px;
    color: #fff;
    z-index: -1111;

    span {
      display: inline-block;
      width: 40px;
    }
  }

  /deep/.el-switch__core {
    width: 65px !important;
  }

  /deep/.el-switch__label--left.is-active {
    z-index: 10;
    color: #fff;
    white-space: nowrap;
  }

  /deep/.el-switch__label--right.is-active {
    z-index: 10;
    white-space: nowrap;
  }
}
</style>
