<template>
  <div class="update">
    <div class="title">
      <span class="blue"></span>
      <span>编辑公告</span>
    </div>
    <div class="content">
      <div class="content-title">
        <span>通知信息</span>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">消息标题</span>
          <el-input placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">消息内容</span>
          <el-input type="textarea" placeholder="请输入内容">
          </el-input>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">发布时间</span>
          <el-date-picker type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">接收人</span>
          <div class="add" @click="openSelecte">
            <span class="el-icon-plus"></span>
          <span>选择接收人</span>
          </div>
        </div>
      </div>
      <el-table :data="tableData" height="290" max-height="290" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="编号">
        </el-table-column>
        <el-table-column prop="date" label="头像">
        </el-table-column>
        <el-table-column prop="date" label="姓名">
        </el-table-column>
        <el-table-column prop="type" label="联系电话">
        </el-table-column>
        <el-table-column prop="type" label="角色">
        </el-table-column>
        <el-table-column prop="type" label="所属团队">
        </el-table-column>
        <el-table-column label="操作">
          <!-- slot-scope="scope" -->
          <template>
            <span>删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">消息状态：待发送</span>
        </div>
        <div class="item">
          <span class="key">发布人：王小敏</span>
        </div>
        <div class="item">
          <span class="key">编辑时间：2020-10-30 11:09:09</span>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button size="small" class="submit">发布</el-button>
      <el-button size="small" class="delete" @click="openDelete">删除</el-button>
      <el-button size="small" @click="close">返回</el-button>
    </div>
    <!-- 选择 -->
    <Selecte v-if="showSelecte" @close="closeSelecte"></Selecte>
    <!-- 删除确认 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
  </div>
</template>

<script>
import Selecte from './select.vue'
import Delete from './delete.vue'
export default {
  components: {
    Selecte,
    Delete
  },
  data () {
    return {
      option: [{
        value: '1',
        label: '1'
      }],
      value: '',
      imageUrl: '',
      text: '',
      options: {
        // 普通上传地址
        action: '',
        customConfig: {}, // wangEditor编辑的配置
        // 是否启用oss(qiniu/ali)
        oss: '',
        headers: {},
        data: {},
        propsHttp: {
          home: '',
          url: 'url',
          res: 'data'
        },
        // 七牛云oss配置
        qiniu: {
          AK: '',
          SK: '',
          scope: '',
          url: '',
          deadline: 1
        },
        // 阿里云oss配置
        ali: {
          region: '',
          endpoint: '',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: ''
        }
      },
      showDelete: false,
      showSelecte: false,
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }]
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    openSelecte () {
      this.showSelecte = true
    },
    closeSelecte () {
      this.showSelecte = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 88;
  background-color: #081C38;
  box-sizing: border-box;

  .title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    margin-bottom: 10px;
    padding-left: 10px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: bold;

    .blue {
      width: 4px;
      height: 18px;
      margin-right: 4px;
      background-color: #366FF7;
      border-radius: 2px;
    }
  }

  .content {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 120px);
    padding: 0 10px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .content-title {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #01EEFD;
      font-size: 18px;
    }

    /deep/.el-textarea__inner {
          width: 714px;
          height: 90px;
          resize: none;
        }

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0rem;
      }
    }

    ::v-deep .el-table th.gutter {
      display: none;
      width: 0;
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    ::v-deep .el-table__body {
      width: 100% !important;
    }

    .el-table {
      margin-top: .25rem;
      background-color: #092B50;
      border: .05rem #073F73 solid;
    }

    // 表头文字颜色
    /deep/.el-table__header-wrapper {
      thead {
        th {
          div {
            color: #01EEFD;
            font-size: .2125rem;
            font-weight: 400;
          }
        }
      }
    }

    //表格内容颜色
    /deep/.el-table__body tbody tr {
      color: #fff;
      background-color: #092B50;
    }

    /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #092B50;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent !important
    }

    // 改变边框颜色
    .el-table--border,
    .el-table--group {
      border: .025rem solid #ddd !important;
    }

    /**
  改变表格内竖线颜色
   */
    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: .0125rem solid #ddd !important;
    }

    /deep/.el-table td {
      border-bottom: .0125rem solid #01EEFD;
    }

    /deep/.el-table thead tr th {
      border-color: transparent;
    }

    /deep/ .el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
      background-color: #073F73;
    }

    /deep/.el-table--border {
      border: .0125rem solid #073F73 !important;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #0e75d4;
      font-size: 15px;

      span {
        cursor: pointer;
      }
    }

    .flex1 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .img {
        width: 30px;
        height: 30px;
        margin-right: 22px;
      }
    }

    .color {
      color: #FFFFFF;
      font-size: 24px;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;

      ::v-deep.el-pagination__total,
      ::v-deep.el-pagination__jump {
        color: #01EEFD;

        .el-input__inner {
          color: #fff;
          background-color: transparent;
        }
      }

      ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #01EEFD;
      }
    }

    .line {
      display: flex;
      margin-bottom: 17px;
      padding-left: 10px;
      box-sizing: border-box;

      .item {
        display: flex;
        align-items: center;
        margin-right: 40px;
        color: #01EEFD;
        font-size: 18px;

        .key {
          margin-right: 10px;
          white-space: nowrap;
        }

        .add {
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .el-input {
          width: 209px;
        }

        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 210px;
          height: 90px;
          background-color: #fff;
          font-size: 16px;
          color: #A1A6BB;
        }

        .ueditor {
          width: calc(100% - 100);
          height: 400px;
        }
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .el-button {
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #fff;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }

    .delete {
      background-color: #FF0000;
    }
  }
}
</style>
