<template>
  <div class="page">
    <div class="page-left">
      <div v-if="isCollapse" class="collapse">
        <div class="top" @click="isCollapse = !isCollapse">
          <span>展开</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <div class="bot">
          <i class="el-icon-caret-top" @click="pre"></i>
          <i class="el-icon-refresh-right" @click="refresh"></i>
          <i class="el-icon-caret-bottom" @click="next"></i>
        </div>
      </div>
      <div v-if="!isCollapse" class="menu">
        <div class="scroll">
          <el-menu :collapse-transition="false" :default-active="activePath" router background-color="#0E487D"
            text-color="#fff" active-text-color="#04B4D0" unique-opened>
            <!-- <el-menu-item index="/problemFeedback/runkanban" @click="saveNavState('/problemFeedback/runkanban')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">管理看板</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/monitoring" @click="saveNavState('/problemFeedback/monitoring')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">实时监护</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/monitor" @click="saveNavState('/problemFeedback/monitor')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">智能监测</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemfeedback/monitordata" @click="saveNavState('/problemfeedback/monitordata')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">监测数据</span>
              </template>
            </el-menu-item>
            <el-submenu index="jtcw">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">家庭床位</span>
              </template>
              <el-menu-item index="/problemFeedback/monitorkanban" @click="saveNavState('/problemFeedback/monitorkanban')">
                <template slot="title">
                  <span class="margin-left">监测看板</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/service" @click="saveNavState('/problemFeedback/service')">
                <template slot="title">
                  <span class="margin-left">上门服务</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="hlfw">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">护理服务</span>
              </template>
              <el-menu-item index="/problemFeedback/nursing" @click="saveNavState('/problemFeedback/nursing')">
                <template slot="title">
                  <span class="margin-left">护理计划</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/medication" @click="saveNavState('/problemFeedback/medication')">
                <template slot="title">
                  <span class="margin-left">用药计划</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/recovery" @click="saveNavState('/problemFeedback/recovery')">
                <template slot="title">
                  <span class="margin-left">康复计划</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/problemFeedback/reviewRun" @click="saveNavState('/problemFeedback/reviewRun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">评估管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/healthservices" @click="saveNavState('/problemFeedback/healthservices')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">健康服务</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/healthedu" @click="saveNavState('/problemFeedback/healthedu')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">健康教育</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/devicetube" @click="saveNavState('/problemFeedback/devicetube')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">设备管理</span>
              </template>
            </el-menu-item>
            <el-submenu index="xtgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="submenu-text font">系统管理</span>
              </template>
              <el-menu-item index="/problemFeedback/organsetup" @click="saveNavState('/problemFeedback/organsetup')">
                <template slot="title">
                  <span class="margin-left">机构设置</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/knowledgebase" @click="saveNavState('/problemFeedback/knowledgebase')">
                <template slot="title">
                  <span class="margin-left">知识库</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/nursingdictionary" @click="saveNavState('/problemFeedback/nursingdictionary')">
                <template slot="title">
                  <span class="margin-left">护理字典</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/personnelrun" @click="saveNavState('/problemFeedback/personnelrun')">
                <template slot="title">
                  <span class="margin-left">人员管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/rolerun" @click="saveNavState('/problemFeedback/rolerun')">
                <template slot="title">
                  <span class="margin-left">角色管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/menurun" @click="saveNavState('/problemFeedback/menurun')">
                <template slot="title">
                  <span class="margin-left">菜单管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/departmentrun" @click="saveNavState('/problemFeedback/departmentrun')">
                <template slot="title">
                  <span class="margin-left">部门管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/postrun" @click="saveNavState('/problemFeedback/postrun')">
                <template slot="title">
                  <span class="margin-left">职务管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/meDepartment" @click="saveNavState('/problemFeedback/meDepartment')">
                <template slot="title">
                  <span class="margin-left">我的部门</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/testsetmeal" @click="saveNavState('/problemFeedback/testsetmeal')">
                <template slot="title">
                  <span class="margin-left">体检套餐</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/ycyl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">远程医疗</span>
              </template>
              <el-menu-item index="/problemFeedback/bultrasonic" @click="saveNavState('/problemFeedback/bultrasonic')">
                <template slot="title">
                  <span class="margin-left">远程B超</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/ecg" @click="saveNavState('/problemFeedback/ecg')">
                <template slot="title">
                  <span class="margin-left">远程心电</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/problemFeedback/image" @click="saveNavState('/problemFeedback/image')">
                <template slot="title">
                  <span class="margin-left">远程影像</span>
                </template>
              </el-menu-item>
            </el-submenu> -->

            <!-- <el-menu-item index="/problemFeedback/ncd" @click="saveNavState('/problemFeedback/ncd')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">智慧慢病</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/dictionary" @click="saveNavState('/problemFeedback/dictionary')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="submenu-text">护理字典维护</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/knowledgebase" @click="saveNavState('/problemFeedback/knowledgebase')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="submenu-text">知识库维护</span>
              </template>
            </el-menu-item> -->

            <el-menu-item index="/problemFeedback/consultation" @click="saveNavState('/problemFeedback/consultation')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">会诊</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/bultrasonic" @click="saveNavState('/problemFeedback/bultrasonic')" v-if="showBox">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">远程B超</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/ecg" @click="saveNavState('/problemFeedback/ecg')" v-if="showBox">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">远程心电</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/image" @click="saveNavState('/problemFeedback/image')" v-if="showBox">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">远程影像</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemFeedback/referral" @click="saveNavState('/problemFeedback/referral')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">转诊</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/problemfeedback/reportingrun" @click="saveNavState('/problemfeedback/reportingrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">报告管理</span>
              </template>
            </el-menu-item>
          </el-menu>
        </div>
        <!-- <div class="uncollapse" @click="isCollapse = !isCollapse">
          <span>收回</span>
          <i class="el-icon-caret-top"></i>
        </div> -->
      </div>
    </div>
    <div class="page-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activePath: ''
    }
  },
  computed: {
    ...mapState(['ShowHeadTab']),
    showBox () {
      return this.ShowHeadTab.findIndex(item => item.text === '智慧养老') !== -1
    }
  },
  mounted () {
    this.activePath = window.sessionStorage.getItem('problemfeedbackPath') || '/problemfeedback'
    console.log('判断是否有养老', this.ShowHeadTab.findIndex(item => item.text === '智慧养老'))
  },
  methods: {
    saveNavState (path) {
      window.sessionStorage.setItem('problemfeedbackPath', path)
      window.sessionStorage.setItem('headTabActivePath', path)
      this.activePath = path
    },
    pre () { },
    next () { },
    refresh () { }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #071224;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  .page-left {
    width: 120px;
    height: 100%;
    background-color: #0E487D;

    .collapse {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 120px;
      color: #fff;
      height: 100%;
      padding-top: 100px;

      .top {
        height: 30%;
        width: 40px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        cursor: pointer;

        i {
          font-size: 20px;
        }
      }

      .bot {
        height: 20%;
        width: 40px;
        margin-top: 80px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .menu {
      position: relative;
      width: 100%;
      height: 100%;

      .uncollapse {
        position: absolute;
        bottom: 0;
        writing-mode: tb-rl;
        text-align: center;
        line-height: 40px;
        margin-left: 50%;
        margin-top: 20px;
        transform: translateX(-50%);
        cursor: pointer;
        font-size: 20px;
        color: #fff;
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 10px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .el-menu {
          border: none;
        }

        /deep/.el-menu-item {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 0 !important;
          font-size: 18px;
        }

        .font {
          font-size: 18px;
        }

        /deep/.el-submenu__title {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 1px !important;
          padding-right: 2px !important;
        }

        /deep/.el-icon-arrow-down:before {
          content: '';
        }

        .menu-text {
          width: calc(100% - 40px);
          line-height: normal;
          white-space: pre-wrap;
        }

        .submenu-text {
          width: calc(100% - 30px);
          line-height: normal;
          white-space: pre-wrap;
        }

        .margin-left {
          margin-left: 30px;
        }
      }
    }
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 130px);
    height: 100%;
    margin-left: 7px;
    // padding: 10px;
    // background-color: #081C38;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>
