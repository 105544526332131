import { render, staticRenderFns } from "./healthEducation.vue?vue&type=template&id=ca520c30&scoped=true&"
import script from "./healthEducation.vue?vue&type=script&lang=js&"
export * from "./healthEducation.vue?vue&type=script&lang=js&"
import style0 from "./healthEducation.vue?vue&type=style&index=0&id=ca520c30&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca520c30",
  null
  
)

export default component.exports