<template>
  <div class="write">
    <div class="title">
      <span>履约照片</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="info">
          <div class="left">
            <img class="img">
            <div class="news">
              <div class="line">
                <span>郭秋生</span>
                <span>男</span>
                <span>49岁</span>
                <span>电话：15806638888</span>
                <span>身份证号：</span>
              </div>
              <div class="line">
                <span>人群分类：高血压、糖尿病</span>
              </div>
            </div>
          </div>
        </div>
        <div class="list">
          <span class="span">备注信息</span>
          <el-input type="textarea">
          </el-input>
        </div>
        <div class="list">
          <span class="span">备注信息</span>
          <div class="white">
            <el-upload class="avatar-uploader" action="" :show-file-list="false" :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
        <div class="btns">
          <el-button>取消</el-button>
          <el-button class="button" @click="closePopup">确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      radio: '1',
      value: '',
      options: [{
        value: '1',
        label: '1'
      }],
      labelIndex: 0,
      imageUrl: '',
      lables: Object.freeze(['0-6岁儿童', '孕产妇', '65岁以上老年人', '高血压', '糖尿病', '严重精神障碍', '冠心病', '传染病', '哮喘', '其他人群', '慢性支气管炎', '残疾人', '肺结核', '脑卒中后遗症', '高血压-高危', '糖尿病-高危', '计生特殊困难', '低保、五保户', '收费包配套', '视力残疾', '听力残疾', '肢体残疾', '智力残疾', '精神残疾', '言语残疾', '恶心肿瘤', '脱贫户', '边缘易致贫户', '突发严重困...'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
      this.$refs.remarkCarusel.setActiveItem(index)
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 300px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 137px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 700px;
    margin-top: 10px;
    padding: 50px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 38px;
      right: 22px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: calc(100% - 70px);

      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100px;

        .left {
          display: flex;
          align-items: center;

          .img {
            width: 48px;
            height: 48px;
            background-color: #fff;
            border-radius: 50%;
          }

          .news {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 60px;
            margin-left: 7px;

            .line {
              display: flex;
              align-items: center;
              color: #01EEFD;
              font-size: 16px;

              span {
                margin-right: 20px;
              }
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column-reverse;
          height: 100%;

          .add {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 50%;
            background-color: #04B4D0;
            color: #fff;
            font-size: 24px;
          }
        }
      }

      .list {
        display: flex;
        margin-top: 30px;
        color: #fff;
        font-size: 18px;

        .span {
          width: 100px;
        }

        .el-textarea {
          width: calc(100% - 100px);
        }

        /deep/.el-textarea__inner {
          height: 120px;
          resize: none;
        }

        .white {
          display: flex;
          align-items: center;
          width: calc(100% - 100px);
          height: 232px;
          padding-left: 20px;
          background-color: #fff;
          box-sizing: border-box;
        }

        .avatar-uploader .el-upload {
          border: 1px dashed #d9d9d9;
          border-radius: 6px;
          cursor: pointer;
          position: relative;
          overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
          border-color: #409EFF;
        }

        .avatar-uploader-icon {
          font-size: 28px;
          color: #8c939d;
          width: 178px;
          height: 178px;
          line-height: 178px;
          text-align: center;
          background-color: #E0E0E0;
        }

        .avatar {
          width: 178px;
          height: 178px;
          display: block;
        }
      }

      .btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 240px;
        height: 46px;
        margin: 0 auto;
        margin-top: 30px;

        .el-button {
          height: 30px;
          line-height: 30px;
          padding: 0 10px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          background-color: #33465C;
          border-radius: 0;
          border: none;
        }

        .button {
          background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        }
      }
    }
  }
}
</style>
