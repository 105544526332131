<template>
  <div class="tab">
    <div class="title">
      <span class="blue"></span>
      <span>编辑文章</span>
    </div>
    <div class="content">
      <div class="content-title">
        <div class="switch" v-for="(item, index) in switchs" :key="index" :class="{ active: switchIndex === index }" @click="switchClick(index)">
          <span>{{ item }}</span>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">内容</span>
          <div class="ueditor" v-show="!showDelete">
            <avue-ueditor v-model="text" v-bind="options"></avue-ueditor>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['用户隐私政策', '医生端隐私政策', '签约协议']),
      option: [{
        value: '1',
        label: '1'
      }],
      value: '',
      imageUrl: '',
      text: '',
      options: {
        // 普通上传地址
        action: '',
        customConfig: {}, // wangEditor编辑的配置
        // 是否启用oss(qiniu/ali)
        oss: '',
        headers: {},
        data: {},
        propsHttp: {
          home: '',
          url: 'url',
          res: 'data'
        },
        // 七牛云oss配置
        qiniu: {
          AK: '',
          SK: '',
          scope: '',
          url: '',
          deadline: 1
        },
        // 阿里云oss配置
        ali: {
          region: '',
          endpoint: '',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: ''
        }
      },
      showDelete: false
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  .title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    margin-bottom: 10px;
    padding-left: 10px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: bold;

    .blue {
      width: 4px;
      height: 18px;
      margin-right: 4px;
      background-color: #366FF7;
      border-radius: 2px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 120px);
    padding: 0 10px;
    box-sizing: border-box;

    .content-title {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 10px;
      color: #01EEFD;
      font-size: 18px;

      .switch {
        margin-right: 70px;
        border-bottom: 4px solid transparent;
        color: #FFFFFF;
        font-size: 18px;
        cursor: pointer;
      }

      .active {
        color: #04B4D0;
        border-bottom: 3px solid #04B4D0;
      }
    }

    .line {
      display: flex;
      width: 100%;
      margin-top: 40px;
      padding-left: 10px;
      box-sizing: border-box;

      .item {
        display: flex;
        margin-right: 40px;
        color: #01EEFD;
        font-size: 18px;

        .key {
          margin-right: 10px;
          white-space: nowrap;
        }

        .ueditor {
          width: 1464px;
        }

        /deep/.w-e-text-container {
          height: 500px !important;
        }
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .el-button {
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #fff;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }

    .delete {
      background-color: #FF0000;
    }
  }
}
</style>
