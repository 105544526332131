<template>
  <div class="info">
    <div class="info-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="info-right">
      <div class="title">
        <span class="blue"></span>
        <span>医生详情</span>
      </div>
      <div class="card">
        <div class="card-title">
          <span>医生信息</span>
        </div>
        <div class="card-box">
          <div class="item">
            <span class="key">医生编号</span>
            <span class="value">2019090009</span>
          </div>
          <div class="item">
            <span class="key">医生姓名</span>
            <span class="value">王小倩</span>
          </div>
          <div class="item">
            <span class="key">医生头像</span>
            <div class="value">
              <div class="flex">
                <img class="head-img" src="../../../img/emr/user.png">
                <span class="el-icon-plus"></span>
                <span>点击上传</span>
              </div>
            </div>
          </div>
          <div class="item">
            <span class="key">性别</span>
            <span class="value">男</span>
          </div>
          <div class="item">
            <span class="key">联系电话</span>
            <span class="value">15804449266</span>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-box">
          <div class="item item1">
            <span class="key">医生简介</span>
            <div class="value value1">
              <span>1、北京大学第一医院内分泌科专家，从事内分泌相关疾病35余年，尤其擅长代谢性疾病引起的不孕不育诊疗，现任北京天伦
                医院不孕不育妇科特聘专家。</span>
              <span>1、北京大学第一医院内分泌科专家，从事内分泌相关疾病35余年，尤其擅长代谢性疾病引起的不孕不育诊疗，现任北京天伦
                医院不孕不育妇科特聘专家。</span>
            </div>
          </div>
          <div class="item">
            <span class="key">医生擅长</span>
            <span class="value">1、北京大学第一医院内分泌科专家，从事内分泌相关疾病35余年，尤其擅长代谢性疾病引起的不孕不育诊疗，现任北京天伦
              医院不孕不育妇科特聘专家。</span>
          </div>
          <div class="item">
            <span class="key">加入团队</span>
            <span class="value">1、北京大学第一医院内分泌科专家，从事内分泌相关疾病35余年，尤其擅长代谢性疾病引起的不孕不育诊疗，现任北京天伦
              医院不孕不育妇科特聘专家。</span>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-box">
          <div class="item">
            <span class="key">签约人数</span>
            <span class="value">50</span>
          </div>
          <div class="item">
            <span class="key">医生评分</span>
            <span class="value">4.9</span>
          </div>
          <div class="item">
            <span class="key">创建时间</span>
            <span class="value"> 2021-06-06 10:38:12</span>
          </div>
          <div class="item">
            <span class="key">创建人</span>
            <span class="value">李民航</span>
          </div>
          <div class="item">
            <span class="key">账号状态</span>
            <el-switch active-color="#0BBB0B" inactive-color="#0BBB0B" active-text="启用" inactive-text="禁用" v-model="value2">
            </el-switch>
          </div>
        </div>
      </div>
      <div class="btns">
        <el-button class="submit" size="small" @click="openNewRun">发送消息</el-button>
        <el-button class="submit" size="small" @click="openAdd">编辑医生信息</el-button>
        <el-button class="close red" size="small" @click="openDelete">删除</el-button>
        <el-button class="close" size="small" @click="close">返回</el-button>
      </div>
    </div>
    <!-- 发消息 -->
    <NewsRun v-if="showNewsRun" :isPosition="true" @close="closeNewRun"></NewsRun>
    <!-- 新增医生信息 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <!-- 删除 -->
    <Delete v-if="showDelete" @close="closeDelete"></Delete>
  </div>
</template>

<script>
import UserBasicInfo from '../../../../../components/user-basic-info/user-basic-info.vue'
import NewsRun from '../../newsRun/newsRun.vue'
import Add from './add.vue'
import Delete from './delete.vue'
export default {
  components: {
    UserBasicInfo,
    NewsRun,
    Add,
    Delete
  },
  data () {
    return {
      showNewsRun: false,
      showAdd: false,
      showDelete: false,
      value2: true
    }
  },
  methods: {
    openNewRun () {
      this.showNewsRun = true
    },
    closeNewRun () {
      this.showNewsRun = false
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openDelete () {
      this.showDelete = true
    },
    closeDelete () {
      this.showDelete = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-left {
    width: 270px;
    height: 100%;
  }

  .info-right {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: #01EEFD;
      font-size: 20px;

      .blue {
        width: 4px;
        height: 18px;
        margin-right: 4px;
        border-radius: 2px;
        background-color: #366FF7;
      }
    }

    .card {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-top: 1px solid #01EEFD;
      box-sizing: border-box;

      .card-title {
        color: #01EEFD;
        font-size: 18px;
      }

      .card-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .item {
          display: flex;
          align-items: center;
          margin-top: 10px;
          color: #01EEFD;
          font-size: 18px;

          .key {
            width: 80px;
            white-space: nowrap;
            text-align-last: justify;
          }

          .value {
            margin-left: 10px;
            font-size: 16px;
          }
        }

        .item1 {
          align-items: flex-start;
        }

        .value1 {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .head-img {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }

    .flex {
      display: flex;
      align-items: center;
    }

    .add {
      margin-left: 23px;
      cursor: pointer;
    }

    .btns {
      display: flex;
      align-items: center;
      position: absolute;
      left: 40px;
      bottom: 40px;
    }

    .submit,
    .close {
      margin-right: 20px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #FFFFFF;
      font-size: 14px;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }

    .red {
      background-color: #FF0000;
    }
  }
  /deep/.el-switch__label--left {
    position: relative;
    left: 70px;
    color: #fff;
    z-index: -1111;

    span {
      width: 40px;
    }
  }

  /deep/.el-switch__label--right {
    position: relative;
    right: 70px;
    color: #fff;
    z-index: -1111;

    span {
      display: inline-block;
      width: 40px;
    }
  }

  /deep/.el-switch__core {
    width: 65px !important;
  }

  /deep/.el-switch__label--left.is-active {
    z-index: 10;
    color: #fff;
  }

  /deep/.el-switch__label--right.is-active {
    z-index: 10;
    color: #fff;
  }
}
</style>
