<template>
  <div class="popup">
    <div class="title">
      <span class="blue"></span>
      <span>新增文章</span>
    </div>
    <div class="content">
      <div class="content-title">
        <span>文章信息</span>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">文章标题</span>
          <el-input placeholder="请输入"></el-input>
        </div>
        <div class="item">
          <span class="key">文章分类</span>
          <el-input placeholder="请输入"></el-input>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">列表图片</span>
          <el-upload class="avatar-uploader" action="" :show-file-list="false" :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <div class="flex" v-else>
              <i class="el-icon-plus"></i>
              <span>点击上传</span>
            </div>
          </el-upload>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">文章内容</span>
          <div class="ueditor">
            <avue-ueditor v-model="text" v-bind="options"></avue-ueditor>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="item">
          <span class="key">发布时间</span>
          <el-date-picker type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="key">排序号</span>
          <el-input placeholder="请输入"></el-input>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button size="small" class="submit">发布</el-button>
      <el-button size="small" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imageUrl: '',
      text: '',
      options: {
        // 普通上传地址
        action: '',
        customConfig: {}, // wangEditor编辑的配置
        // 是否启用oss(qiniu/ali)
        oss: '',
        headers: {},
        data: {},
        propsHttp: {
          home: '',
          url: 'url',
          res: 'data'
        },
        // 七牛云oss配置
        qiniu: {
          AK: '',
          SK: '',
          scope: '',
          url: '',
          deadline: 1
        },
        // 阿里云oss配置
        ali: {
          region: '',
          endpoint: '',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: ''
        }
      }
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 88;
  background-color: #081C38;
  box-sizing: border-box;

  .title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    margin-bottom: 10px;
    padding-left: 10px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
    color: #01EEFD;
    font-size: 20px;
    font-weight: bold;

    .blue {
      width: 4px;
      height: 18px;
      margin-right: 4px;
      background-color: #366FF7;
      border-radius: 2px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 120px);
    padding: 0 10px;
    box-sizing: border-box;

    .content-title {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #01EEFD;
      font-size: 18px;
    }

    .line {
      display: flex;
      margin-bottom: 17px;
      padding-left: 10px;
      box-sizing: border-box;

      .item {
        display: flex;
        align-items: center;
        margin-right: 40px;
        color: #01EEFD;
        font-size: 18px;

        .key {
          margin-right: 10px;
          white-space: nowrap;
        }

        .el-input {
          width: 209px;
        }

        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 210px;
          height: 90px;
          background-color: #fff;
          font-size: 16px;
          color: #A1A6BB;
        }

        .ueditor {
          width: calc(100% - 100);
          height: 400px;
        }
      }
    }
  }

  .btns {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .el-button {
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #fff;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
