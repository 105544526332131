<template>
  <div class="info" :class="{ info1: isPosition }">
    <div class="info-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="info-right">
      <div class="chat">
        <div class="news">
          <div class="news-title" @click="close">
            <span class="blue"></span>
            <span>消息管理</span>
          </div>
          <div class="news-scroll">
            <div class="news-item" v-for="(item, index) in 20" :key="index">
              <div class="left">
                <img class="head-img" src="../../img/emr/user.png">
                <div class="total">
                  <span>3</span>
                </div>
                <div class="topping">
                  <span>置顶</span>
                </div>
              </div>
              <div class="right">
                <div class="line">
                  <span class="name">韩正义</span>
                  <span class="time">08:00</span>
                </div>
                <div class="line">
                  <span class="text">请您按时吃药！</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-center">
          <div class="top">
            <div class="top-box"></div>
            <div class="top-title">
              <span>韩正义</span>
              <div class="open" @click="openPosition">
                <span class="el-icon-more"></span>
              </div>
            </div>
          </div>
          <div class="center">
            <div class="chart-list">
              <div class="date-time">
                <span>08:00</span>
              </div>
              <div class="user-news">
                <img src="../../img/emr/user.png" class="user-img">
                <div class="chat-news">
                  <span>医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？</span>
                </div>
              </div>
            </div>

            <div class="chart-list">
              <div class="date-time">
                <span>08:00</span>
              </div>
              <div class="user-news user-news1">
                <img src="../../img/emr/user.png" class="user-img">
                <div class="chat-news chat-news1">
                  <span>医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？</span>
                </div>
              </div>
            </div>

            <div class="chart-list">
              <div class="date-time">
                <span>08:00</span>
              </div>
              <div class="user-news">
                <img src="../../img/emr/user.png" class="user-img">
                <div class="chat-news">
                  <span>医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？</span>
                </div>
              </div>
            </div>

            <div class="chart-list">
              <div class="date-time">
                <span>08:00</span>
              </div>
              <div class="user-news user-news1">
                <img src="../../img/emr/user.png" class="user-img">
                <div class="chat-news chat-news1">
                  <span>医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？</span>
                </div>
              </div>
            </div>

            <div class="chart-list">
              <div class="date-time">
                <span>08:00</span>
              </div>
              <div class="user-news">
                <img src="../../img/emr/user.png" class="user-img">
                <div class="chat-news">
                  <span>医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？</span>
                </div>
              </div>
            </div>

            <div class="chart-list">
              <div class="date-time">
                <span>08:00</span>
              </div>
              <div class="user-news user-news1">
                <img src="../../img/emr/user.png" class="user-img">
                <div class="chat-news chat-news1">
                  <span>医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？医生你好，这周六有时间做随访吗？</span>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <el-input type="textarea" placeholder="请输入内容" v-model="textarea">
            </el-input>
            <div class="operate">
              <div class="icons">
                <span class="el-icon-microphone span"></span>
                <span class="el-icon-s-custom span"></span>
                <span class="el-icon-picture span"></span>
              </div>
              <el-button class="send" size="mini">发送</el-button>
            </div>
          </div>
          <div class="position" v-if="showPosition">
            <div class="position-info">
              <img src="../../img/emr/user.png" class="user-img">
              <div class="info-news">
                <span class="name">韩正义</span>
                <span class="see">查看资料</span>
              </div>
            </div>
            <div class="position-line">
              <div class="key">
                <span>好友备注</span>
              </div>
              <el-input placeholder="请输入备注信息"></el-input>
            </div>
            <div class="position-line">
              <div class="key">
                <span>消息置顶</span>
              </div>
              <el-switch v-model="value" active-color="#0BBB0B" inactive-color="#ADADAD" active-text="启用"
                inactive-text="关闭">
              </el-switch>
            </div>
            <div class="position-line">
              <div class="key">
                <span>消息免打扰</span>
              </div>
              <el-switch v-model="value" active-color="#0BBB0B" inactive-color="#ADADAD" active-text="启用"
                inactive-text="关闭">
              </el-switch>
            </div>
          </div>
        </div>
        <div class="chart-right">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserBasicInfo from '../../../../components/user-basic-info/user-basic-info.vue'
export default {
  props: {
    isPosition: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UserBasicInfo
  },
  data () {
    return {
      textarea: '',
      value: '',
      showPosition: false
    }
  },
  methods: {
    openPosition () {
      this.showPosition = !this.showPosition
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-left {
    width: 270px;
    height: 100%;
  }

  .info-right {
    position: relative;
    width: calc(100% - 280px);
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .chat {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      background-color: #063E6D;

      .news {
        width: 244px;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;

        .news-title {
          display: flex;
          align-items: center;
          width: 100%;
          height: 30px;
          color: #0AB5CF;
          font-size: 17px;
          font-weight: bold;

          .blue {
            width: 4px;
            height: 18px;
            background-color: #366FF7;
            border-radius: 2px;
            margin-right: 4px;
          }
        }

        .news-scroll {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 30px);

          &::-webkit-scrollbar {
            width: 0;
          }

          .news-item {
            display: flex;
            align-items: center;
            width: 100%;
            height: 62px;
            padding: 6px 10px;
            border-bottom: 1px solid #0AB5CF;
            box-sizing: border-box;

            .left {
              position: relative;
              width: 32px;
              height: 32px;

              .head-img {
                width: 100%;
                height: 100%;
              }

              .total {
                position: absolute;
                top: -8px;
                right: -8px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px 8px;
                background-color: #FF1010;
                border-radius: 50%;
                transform: scale(.7);
                color: #fff;
                font-size: 12px;
              }

              .topping {
                position: absolute;
                bottom: -30%;
                left: 2%;
                text-align: center;
                background-color: #FF1010;
                border: 2px solid #FF1010;
                white-space: nowrap;
                border-radius: 4px;
                color: #fff;
                font-size: 12px;
                transform: scale(.8);
              }
            }

            .right {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 164px;
              height: 40px;
              margin-left: 10px;

              .line {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                color: #0A8FAB;
                font-size: 12px;

                .name {
                  font-size: 16px;
                }

                .time {
                  font-size: 14px;
                }

                .text {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .chart-center {
        position: relative;
        width: calc(100% - 326px);
        height: 100%;

        .top {
          display: flex;
          flex-direction: column;
          width: 100%;
          background-color: #063E6D;

          .top-box {
            width: 100%;
            height: 77px;
          }

          .top-title {
            position: relative;
            width: 100%;
            height: 43px;
            line-height: 43px;
            text-align: center;
            border-top: 1px solid #0A8FAB;
            color: #333333;
            font-size: 16px;
            font-weight: bold;
            box-sizing: border-box;

            .open {
              position: absolute;
              top: 50%;
              right: 29px;
              transform: translateY(-50%);
              color: #C4C4C4;
              font-size: 20px;
              cursor: pointer;
            }
          }
        }

        .center {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 280px);
          padding: 0 62px;
          background-color: #08263F;
          box-sizing: border-box;

          &::-webkit-scrollbar {
            width: 0;
          }

          .chart-list {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 120px;
            padding: 10px 0;
            box-sizing: border-box;

            .date-time {
              padding: 2px 16px;
              background-color: #fff;
              border-radius: 12px;
              color: #A1A6BB;
              font-size: 14px;
            }

            .user-news {
              display: flex;
              align-items: center;
              width: 100%;
              margin-top: 10px;

              .user-img {
                width: 32px;
                height: 32px;
              }

              .chat-news {
                margin-left: 10px;
                padding: 5px 11px;
                background-color: #fff;
                border-radius: 16px;
                color: #2A374D;
                font-size: 16px;
              }
            }

            .user-news1 {
              flex-direction: row-reverse;

              .chat-news1 {
                margin-left: 0;
                margin-right: 10px;
                background-color: #378BF3;
                color: #fff;
              }
            }
          }
        }

        .bottom {
          width: 100%;
          height: 160px;
          padding: 16px 11px;
          background-color: #0B477A;
          box-sizing: border-box;

          /deep/.el-textarea__inner {
            width: 100%;
            height: 88px;
            background-color: #063E6D;
            border: none;
            color: #fff;
            font-size: 16px;
            resize: none;
          }

          .operate {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 40px;
            padding: 0 25px;
            box-sizing: border-box;

            .icons {
              display: flex;
              align-items: center;
              color: #fff;
              font-size: 20px;

              .span {
                margin-right: 10px;
                cursor: pointer;
              }
            }

            .send {
              background-color: #378BF3;
              border: none;
              border-radius: 6px;
              color: #fff;
              font-size: 16px;
            }
          }
        }

        .position {
          position: absolute;
          top: 120px;
          right: 0;
          width: 234px;
          height: calc(100% - 295px);
          padding: 18px;
          background-color: #063E6D;
          box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.1608);
          border-radius: 6px;
          box-sizing: border-box;

          .position-info {
            display: flex;
            align-items: center;

            .user-img {
              width: 40px;
              height: 40px;
              background-color: #E8E8E8;
              border-radius: 6px;
            }

            .info-news {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 50px;
              margin-left: 9px;
              color: #0A8FAB;

              .name {
                font-size: 18px;
              }

              .see {
                font-size: 14px;
              }
            }
          }

          .position-line {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;

            .key {
              margin-right: 7px;
              color: #0A8FAB;
              font-size: 14px;
              white-space: nowrap;
            }

            /deep/.el-input__inner {
              width: 100px;
              padding: 0 3px;
              text-align: center;
              background-color: transparent;
              border: none;
              border-radius: 0;
              border-bottom: 1px solid #0A8FAB;
              font-size: 12px;
              color: #0A8FAB;

              &::placeholder {
                color: #0A8FAB;
              }
            }

            /deep/.el-switch__label--left {
              position: relative;
              left: 70px;
              color: #fff;
              z-index: -1111;

              span {
                width: 40px;
              }
            }

            /deep/.el-switch__label--right {
              position: relative;
              right: 64px;
              color: #fff;
              z-index: -1111;

              span {
                display: inline-block;
                width: 40px;
                white-space: nowrap;
              }
            }

            /deep/.el-switch__core {
              width: 60px !important;
            }

            /deep/.el-switch__label--left.is-active {
              z-index: 10;
              color: #fff;

              white-space: nowrap;
            }

            /deep/.el-switch__label--right.is-active {
              z-index: 10;
              color: #fff;
            }
          }
        }
      }

      .chart-right {
        width: 82px;
        height: 100%;
      }
    }
  }
}

.info1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 89;
}
</style>
