<template>
  <div class="content">
    <div class="search">
      <div class="item">
        <span class="key">数据类型</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="key">选择签约日期</span>
        <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="item">
        <span class="key">所属机构</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="key">医生团队</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="key">医生</span>
        <el-select v-model="value" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="scroll">
      <div class="title">
        <span>居民性别构成</span>
      </div>
      <div class="bar" id="pie1"></div>
      <div class="table">
        <div class="table-head">
          <el-button size="small">导出</el-button>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>序号</span>
          </div>
          <div class="table-td">
            <span>性别</span>
          </div>
          <div class="table-td">
            <span>人次</span>
          </div>
          <div class="table-td">
            <span>比率</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>1</span>
          </div>
          <div class="table-td">
            <span>男</span>
          </div>
          <div class="table-td">
            <span>80</span>
          </div>
          <div class="table-td">
            <span>80%</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>2</span>
          </div>
          <div class="table-td">
            <span>女</span>
          </div>
          <div class="table-td">
            <span>80</span>
          </div>
          <div class="table-td">
            <span>80%</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>3</span>
          </div>
          <div class="table-td">
            <span>未知</span>
          </div>
          <div class="table-td">
            <span>80</span>
          </div>
          <div class="table-td">
            <span>80%</span>
          </div>
        </div>
        <div class="table-tr">
          <div class="table-td">
            <span>合计</span>
          </div>
          <div class="table-td">
            <span></span>
          </div>
          <div class="table-td">
            <span>80</span>
          </div>
          <div class="table-td">
            <span>80%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartPie1: null,
      options: [{
        value: '1',
        label: '1'
      }],
      value: ''
    }
  },
  mounted () {
    this.canvasPie1()
    window.addEventListener('resize', this.getresize)
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      this.chartPie1.resize()
    },
    canvasPie1 () {
      var chartDom = document.getElementById('pie1')
      this.chartPie1 = this.$echarts.init(chartDom)
      var option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          x: 'center',
          y: 'bottom',
          type: 'scroll',
          itemGap: 40,
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          formatter: function (name) {
            var data = option.series[0].data
            var tarValue
            for (var i = 0; i < data.length; i++) {
              if (data[i].name === name) {
                tarValue = data[i].value
              }
            }
            var v = tarValue
            return `${name}  ${v}人`
          }
        },
        title: {
          text: '1000人',
          left: 'center',
          top: '50%',
          textStyle: {
            textAlign: 'center',
            color: '#04B4D0',
            fontSize: 16,
            fontWeight: 400
          }
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: '40%',
          style: {
            text: '居民总数',
            textAlign: 'center',
            fill: '#04B4D0',
            fontSize: 16
          }
        },
        series: [
          {
            name: '居民',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: '男' },
              { value: 735, name: '女' },
              { value: 580, name: '未知' }
            ]
          }
        ]
      }
      option && this.chartPie1.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: calc(100% - 90px);

  .search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;
      color: #01EEFD;
      font-size: 16px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
      }
    }

    .item:nth-child(5) {
      margin-right: 0;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: calc(100% - 192px);
    height: calc(100% - 100px);
    margin: 0 auto;

    &::-webkit-scrollbar {
      width: 0;
    }

    .title {
      height: 57px;
      line-height: 57px;
      text-align: center;
      color: #04B4D0;
      font-size: 17px;
    }

    /deep/.el-radio__label {
      color: #04B4D0;
      font-size: 16px;
    }

    .line {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .table {
      display: flex;
      flex-direction: column;

      .table-head {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        height: 61px;

        .el-button {
          background-color: #367AF6;
          border-radius: 6px;
          border: none;
          color: #fff;
        }
      }

      .table-tr {
        display: flex;
        align-items: center;
        height: 41px;
        background-color: #3569B3;

        .table-td {
          flex: 1;
          text-align: center;
          color: #FFFFFF;
          font-size: 16px;
        }
      }

      .table-tr:nth-child(odd) {
        background-color: #103A75;
      }

      .table-tr:nth-child(even) {
        background-color: #3569B3;
      }
    }

    .bar {
      width: 100%;
      height: 300px;
    }
  }
}
</style>
