<template>
  <div class="info">
    <div class="info-right">
      <div class="title">
        <span>编辑签约详情</span>
      </div>
      <el-descriptions class="margin-top" title="居民信息" :column="5" border>
        <el-descriptions-item>
          <template slot="label">
            姓名
          </template>
          <el-input></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            性别
          </template>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            出生日期
          </template>
          <el-date-picker type="date" placeholder="选择日期">
          </el-date-picker>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            联系电话
          </template>
          <el-input></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            身份证号
          </template>
          <el-input></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            现居地
          </template>
          <el-input></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            居民标签
          </template>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            家庭成员
          </template>
          <div class="flex">
            <div class="card">
              <img src="../../img/emr/user.png" class="img">
              <div class="news">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <div class="text">
                      <span>李青</span>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="text">
                      <span>62岁</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="12">
                    <div class="text">
                      <span>关系</span>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="text">
                      <span>父母</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="card">
              <img src="../../img/emr/user.png" class="img">
              <div class="news">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <div class="text">
                      <span>李青</span>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="text">
                      <span>62岁</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="12">
                    <div class="text">
                      <span>关系</span>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="text">
                      <span>父母</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="add" v-if="!isbasic">
              <span class="el-icon-plus"></span>
              <span>关联家庭成员</span>
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="签约信息" :column="5" border>
        <el-descriptions-item>
          <template slot="label">
            签约编号
          </template>
          2091900120622
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约状态
          </template>
          <span class="green">待审核</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约机构
          </template>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约团队
          </template>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约医生
          </template>
          <el-input></el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            服务包
          </template>
          <el-select v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约类型
          </template>
          <span class="purple">首次签约</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约周期
          </template>
          <span class="purple">1年</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            费用
          </template>
          <span class="yellow">￥120</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            申请时间
          </template>
          2022/03/22 10:00:00
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            生效时间
          </template>
          <el-date-picker type="date" placeholder="选择日期">
          </el-date-picker>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            签约备注
          </template>
          <el-input type="textarea">
          </el-input>
        </el-descriptions-item>
      </el-descriptions>
      <div class="btns">
        <el-button class="submit" size="small" @click="close">保存</el-button>
        <el-button class="close" size="small" @click="close">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isbasic: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: [{
        value: '1',
        label: '1'
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .info-right {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 10px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      color: #01EEFD;
      font-size: 20px;
    }

    .el-descriptions {
      margin-top: 10px;
      padding: 10px 0;
      background-color: transparent;
      border-top: 1px solid #01EEFD;
      font-size: 16px;
    }

    .gray {
      color: #D2D2D2;
    }

    .purple {
      color: #3670F6;
    }

    .yellow {
      color: #FF915C;
    }

    .green {
      color: #0BBB0B;
    }

    /deep/.el-descriptions__header {
      color: #01EEFD;
      font-size: 18px;
    }

    /deep/.el-descriptions__body {
      background-color: transparent;
      color: #01EEFD;
    }

    /deep/.el-descriptions-item__label.is-bordered-label {
      background-color: transparent;
      text-align: right;
      // text-align-last: justify;
      color: #01EEFD;
      white-space: nowrap;
    }

    /deep/.el-descriptions .is-bordered .el-descriptions-item__cell {
      border: none;
    }

    /deep/.el-textarea__inner {
      resize: none;
    }

    .flex {
      display: flex;
    }

    .card {
      display: flex;
      align-items: center;
      height: 75px;
      margin-right: 10px;
      padding: 10px;
      background: linear-gradient(96deg, #0173C8 0%, #02457A 100%);
      border-radius: 4px;

      .img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      .news {
        display: flex;
        flex-direction: column;

        .text {
          white-space: nowrap;
          color: #fff;
        }
      }
    }

    /deep/.el-textarea__inner {
      width: 523px;
      height: 66px;
      background-color: transparent;
      border: 1px solid #01EEFD;
      color: #fff;
    }

    .add {
      margin-left: 23px;
      cursor: pointer;
    }

    .btns {
      display: flex;
      align-items: center;
      position: absolute;
      left: 40px;
      bottom: 40px;
    }

    .submit,
    .close {
      margin-right: 20px;
      background-color: #33465C;
      border-radius: 0;
      border: none;
      color: #FFFFFF;
      font-size: 14px;
    }

    .submit {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
